import React, { useState, useEffect } from "react";
import { Grid, Box, Divider } from "@mui/material";
import ContentWrapper from "../../../../ContentWrapper";
import CustomStepper from "../../../../CustomStepper";
import { useNavigate,useLocation } from "react-router-dom";
import AddFamilyMember from "../components/Addfamilymember";
import { bindActionCreators } from "redux";
import { connect, useDispatch } from "react-redux";
import SummaryOfTrackMe from "../components/SummaryOfTrackMe";
import TrackMessage from "./trackMessage";
import "../styles/TrackMe.css";

const MainLayout = () =>{
  const location = useLocation();
  const trackData = location.state?.trackData;
  const isUpdate = location.state?.isUpdate;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [skipped, setSkipped] = useState(new Set());
  const [summaryPage, setSummaryPage] = useState(false);
  const [activeStep, setActiveStep] = useState(0);


  const isStepOptional = (step) => {
    return step === 1;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    window.scrollTo(0, 0);
    console.log("activeStep",activeStep)
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }
    if (activeStep === 1) {
      setSummaryPage(true);
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setSkipped(newSkipped);
      setSummaryPage(false);
    }
  };

  const handleBack = () => {
    console.log("handleBack",activeStep)

    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const onCancelClick = () => {
    dispatch({
      type: "ADD_TRACK_ME",
      payload: null,
    });
    navigate(-1); // Navigate back one step in the history
  };

  const onBackOrCancelClick = () => {
    dispatch({
      type: "TRACK_ME_READ_SUCCESS",
      payload: {}
    })
    if (activeStep === 0) {
      onCancelClick();
    } else {
      window.scrollTo(0, 0);
      handleBack();
    }
  };


  const steps = [
    {
      label: "Select Members",
      component: (
        <AddFamilyMember
          onBackOrCancelClick={onBackOrCancelClick}
          handleNext={handleNext}
          trackData={trackData}
        />
      ),
    },
    {
        label: "Select Message",
        component: (
          // <ViewPredefinedMessages
          // onBackOrCancelClick={onBackOrCancelClick}
          // handleNext={handleNext}
          // Crud={true}
          // emergencyAlert={true}
          // trackMe={true}
          // />
          <TrackMessage 
          onBackOrCancelClick={onBackOrCancelClick}
          handleNext={handleNext}
          trackData={trackData}
          />
      ),
    }
  ];
  return (
    <Grid container style={{ margin: 0, width: "100%" }}>
      {/* <Grid item xs={1} md={1} lg={1}></Grid>  */}
       {/* <Grid className="track-me-contrainer"> */}
      <Grid
        container
        xs={12}
        md={12}
        lg={12}
      >
        {summaryPage ? (
          <SummaryOfTrackMe isUpdate={isUpdate} trackData={trackData} />
        ) : (
          <>
            {" "}
            <Box sx={{ width: "100%" }}>
              <CustomStepper steps={steps} activeStep={activeStep} />
            </Box>
            <Divider />{" "}
          </>
        )}
      </Grid>
      {/* </Grid> */}
      {/* <Grid item xs={1} md={1} lg={1}></Grid> */}
    </Grid>
  );
};

MainLayout.defaultProps = {
  isFetching: null,
  error: null,
  familyMembers: [],
};

const TrackMe = ({}) => {
 
  return (
    <ContentWrapper
      title={"SHARE MY LOCATION"}
      gobackUrl={"/track-me"}
      goback={true}
      contenview={
        <MainLayout
        />
      }
    />
  );
};

function mapStateToProps(state) {
  return {
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
    },
    dispatch
  );
}
export default connect(mapStateToProps, mapDispatchToProps)(TrackMe);