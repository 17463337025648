import React, { useEffect, useState } from "react";
import { Button, Box, Typography, Divider, Card, CardContent } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { fetchForgotPasswordData, resetForgotPwd } from "../redux/forgotPasswordAction";
import { connect, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from 'prop-types';
import CloseIcon from"../../../../assets/icons/modal-close-icon.svg";

const ForgotPassword = (props) => {
  const {
    fetchForgotPasswordData, 
    resetForgotPwd,
    isFetching,
    data,
    error,
    onClose,
    resetForgotPassword
  } = props;
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [usernameErr, setUsernameErr] = useState("");
  const dispatch = useDispatch();

  const onChangeUsername = (e) => {
    setUsername(e.target.value)
  }
  useEffect(() => {
    resetForgotPwd();
  }, []);
 
  useEffect(() => {
    if (data) {
      window.localStorage.setItem('hiddenEmail',data?.CodeDeliveryDetails?.Destination);
      navigate('/reset-password');
    }
    if(error && error.message){
      setUsernameErr(JSON.stringify(error?.message));
    }  else {
      setUsernameErr('');
    }
  }, [data, props, error]);

  const onClickSave = () => { 
    if (!username?.trim().length) {
        setUsernameErr('This is required.');
        return
    } else {
        setUsernameErr('');
    }
    const payload = {
        "username": username
    }
    window.localStorage.setItem('forgotpassEmail', username);
    fetchForgotPasswordData(payload)
  }
  const handleCloseIconClick = () => {
    setUsernameErr("");
    navigate('/login');
    resetForgotPwd();
  }
  return (
    <Box
    className="loginScreen"
    id='account-layout'
    onClose={handleCloseIconClick}
  >
        <Card className="forgot-container">
        <div className="forgot-container-top" >
         <Typography className='primary-font-bold1'>Forgot Your Password</Typography>
         <div style={{ display: 'flex',alignitems:'flex-end',justifycontent:'end'}}>
          <img src={CloseIcon} alt="close-icon" className="close-icon" style={{cursor:'pointer',width:"21px"}} onClick={handleCloseIconClick} sx={{
                    marginRight:"30px"
                }}/>       
             </div>
                </div>
            <Divider />
            <CardContent   className="forgot-card-container" >
                <Typography className="primary-font-normal-2" component="div" style={{marginBottom: 25}}>
                    Enter your Email below and we will send a message to reset your password
                </Typography>
                <div>
                    <Typography className='font-normal-small error-message'>{usernameErr}</Typography>
                    <Typography className='font-normal-small profile-input-label'>Email</Typography>
                    <input className={`profile-input ${usernameErr !== '' ? 'err-input' : ''}`}  aria-label='user name'  onChange={onChangeUsername} value={username}/>
                </div>
                <div className="action-holder" style={{marginTop: 20, marginBottom: 0}}>
                    <Button variant="outlined" style={{width: '100%'}}  className="primary-font-bold-6 action-btn profile-save-btn"  onClick={onClickSave}>Reset My Password</Button>
                </div>
            </CardContent>
        </Card>
  </Box>
  )
}
// export default LogIn;
ForgotPassword.propTypes = {
  fetchForgotPasswordData: PropTypes.func.isRequired,
  data: PropTypes.shape(),
  error: PropTypes.shape(),
  isFetching: PropTypes.bool,
};

ForgotPassword.defaultProps = {
  data: null,
  error: null,
  isFetching: null
};
function mapStateToProps(state) {
  return {
    data: state.forgotPassword.data,
    error: state.forgotPassword.error,
    isFetching : state.forgotPassword.isFetching,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchForgotPasswordData,
      resetForgotPwd
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ForgotPassword);

