import React, { useState, useEffect } from "react";
import "../../../../index.css";
import { Grid, Typography, Link, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

import UserAvatar from '../../../../assets/icons/user-avatar.svg';
import Twitter from '../../../../assets/icons/twitter-circle.svg';
import Facebook from '../../../../assets/icons/fb-circle.svg';
import Google from '../../../../assets/icons/google-circle.svg';
import { bindActionCreators } from "redux";
import constants from "../../../../config/constants";
import * as formatters from '../../../../utils/util';

import { connect } from 'react-redux';
import { getProfileInfo, getRelationshipList, updateProfileInfo } from '../redux/actions';
import moment from "moment/moment";
import EnterSecurityLockDialog from './EnterSecurityLockDialog';
import "../styles/MyProfile.css"

const ProfilePage = (props) => {
    const { getProfileInfo,getRelationshipList, relationship,userInfoData, openPopUp } = props;

    const navigate = useNavigate();

    const [name, setName] = useState(null);
    const [imageUrl, setImageUrl] = useState(null);
    const [phoneNumber, setphoneNumber] = useState(null);
    const [altPhoneNumber, setAltPhoneNumber] = useState(null);
    const [email, setEmail] = useState(null);
    const [altEmail, setAltEmail] = useState(null);
    const [emergencyContactName, setEmergencyContactName] = useState(null);
    const [emergencyContactNumber, setEmergencyContactNumber] = useState(null);
    const [dob, setDob] = useState(null);
    const [ssn, setSSN] = useState(null);
    const [relation, setRelation] = useState(null);
    const [user, setUser] = useState(null);
    const [userObject,serUserObject] = useState(null);
      useEffect(()=>{
        getProfileInfo();
        getRelationshipList();
      },[getProfileInfo])

      useEffect(()=>{
        if(userInfoData && userInfoData.status === 200){
            setUser(userInfoData.data);
            const user = userInfoData.data;
            if(user){
              serUserObject(user);
              const userName =( (user?.firstName  && user?.firstName !== null  ? user?.firstName : '')  + 
                               ( user?.middleName && user?.middleName !== null ? (' '+user.middleName)  : "") +
                               (user?.lastName && user?.lastName !== null ? (' '+user.lastName ) : ""))
              setphoneNumber(user?.phoneNo);
              setAltPhoneNumber(user?.altPhoneNo);
              setAltEmail(user?.altEmailId);
              setEmergencyContactName(user?.emergencyContactName);
              setEmergencyContactNumber(user?.emergencyContactNo);
              setEmail(user?.emailId)
              setName(userName)
              setDob(user?.dateOfBirth || '');
              setSSN(user?.socialSecurityNo);
              setRelation(user?.relationshipCode ? relationship[user.relationshipCode] : 'Self');
              setImageUrl(user?.image_PreSignedUrl)
            }
            
        }
      },[userInfoData])

      useEffect(()=>{
        if(relationship && user){
          setRelation(user?.relationshipCode ? relationship[user.relationshipCode] : 'Self');
        }
      },[relationship, user])

  return (
    <Grid container style={{margin: 0, width: '100%'}}>
    <div className="profile-header-container">

    
    <Grid container className="profile-top-container" >
      {/* <Grid item xs={2} md={2}></Grid> */}
      <Grid
       
        container
        xs={12}
        md={12}
        className="profile-box-container"
      >
       
            {/* <Grid item container   className="profile-middle-container" > */}
            <Grid   xs={0} sm={0.5} md={1}    >
              </Grid>

              <Grid xs={12} sm={6} md={6.3} className="profile-middle-container"    >

                  <div className="avatar-container" >
                      <img src={imageUrl ? imageUrl : UserAvatar} alt={'user avatar'} width={215} height={215} style={{borderRadius: '100%',objectFit:'fill', border: '1px solid #DDDFE1'}} />
                  </div>
                  <Typography  style={{ cursor: 'pointer', textTransform:"capitalize"}} className='primary-font-medium-6 profile-input-title profile-title'>{name}</Typography>
                  <Typography  style={{ cursor: 'pointer'}} className='primary-font-normal-1 profile-input-title'>Account Number: <span className="primary-font-bold-5">{userObject?.accountId} </span></Typography>
                  <Typography  style={{ color: 'green'}} className='font-normal-small profile-input-title1 '>Last updated on: {moment(user?.updatedAt).format('L') || '-'}</Typography>
                  <Link href="#" className="primary-font-bold-8" color='#0078FF' style={{marginTop:'30px', textAlign:"center"}} underline="none">{constants.endPointUrl[constants.COGNITO_ENVIRONMENT]}?referrer={user?.firstName}{moment().year()}</Link>
                  <div className="social-icon-container">
                      <div>
                      <a
                          href="#"
                          rel="noopener noreferrer"
                          target="_blank"
                      >
                          <img src={Twitter} alt="twitter"  className="profile-socialicon" />
                      </a>{" "}
                      </div>
                      <div>
                      <a
                          href="#"
                          rel="noopener noreferrer"
                          target="_blank"
                      >
                          <img src={Facebook} alt="facebook" className="profile-socialicon" />
                      </a>{" "}
                      </div>
                      <div>
                      <a
                          href="#"
                          rel="noopener noreferrer"
                          target="_blank"
                      >
                          <img src={Google} alt="google" className="profile-socialicon" />
                      </a>{" "}
                      </div>
                  </div>
                  <div className="profile-input-gap">
                      <Typography className='font-normal-small red-font'>Date of Birth</Typography>
                      <Typography className='primary-font-normal-1'>{dob ? moment(dob).format('L') : '-'}</Typography>
                  </div>
                  <div className="profile-input-gap">
                      <Typography className='font-normal-small red-font'>Social Security Number</Typography>
                      <Typography className='primary-font-normal-1'>{ssn ? formatters.formatAndMaskSSNNumber(ssn) : '-'}</Typography>
                  </div>
                </Grid>
               
            <Grid xs={12} sm={4} md={3.7}  className="profile-middle-container">
                <div >
                    <Typography className='font-normal-small red-font'>Relationship with Plan Owner</Typography>
                    <Typography className='primary-font-normal-1'>{relation ? relation : '-'}</Typography>
                </div>
                <div className="profile-input-gap">
                    <Typography className='font-normal-small red-font'>Phone Number</Typography>
                    <Typography className='primary-font-normal-1'>{phoneNumber ? phoneNumber :'-'}</Typography>
                </div>
                <div className="profile-input-gap">
                    <Typography className='font-normal-small red-font'>Alternate Phone Number</Typography>
                    <Typography className='primary-font-normal-1'>{altPhoneNumber ? altPhoneNumber :'-'}</Typography>
                </div>
                <div className="profile-input-gap"> 
                    <Typography className='font-normal-small red-font'>Email ID</Typography>
                    <Typography className='primary-font-normal-1'>{email ? email : '-'}</Typography>
                </div>
                <div className="profile-input-gap"> 
                    <Typography className='font-normal-small red-font'>Alternate Email ID</Typography>
                    <Typography className='primary-font-normal-1'>{altEmail ? altEmail : '-'}</Typography>
                </div>
                <div className="profile-input-gap"> 
                    <Typography className='font-normal-small red-font'>Emergency Contact Name</Typography>
                    <Typography className='primary-font-normal-1'>{emergencyContactName ? emergencyContactName : '-'}</Typography>
                </div>
                <div className="profile-input-gap"> 
                    <Typography className='font-normal-small red-font'>Emergency Contact Number</Typography>
                    <Typography className='primary-font-normal-1'>{emergencyContactNumber ? emergencyContactNumber : '-'}</Typography>
                </div>
            </Grid>
            <Grid xs={0} sm={0.5} md={1}  className="profile-emp"   >
              </Grid>
        {/* </Grid> */}
        <Button variant="outlined" className="primary-font-bold-6 action-btn profile-save-btn Mt30" style={{margin: 'auto', width: '45%'}} onClick={()=> {navigate('/edit-profile')}}>Edit Profile</Button>
        {openPopUp && <EnterSecurityLockDialog isCloseIconNeeded={true}/> }
      </Grid>
    
    </Grid>
     </div>
     </Grid>
  );
};


ProfilePage.defaultProps = {
    isFetchingLogin: null,
    isFetchingRegister: null,
    isFetchingUserInfo: null,
  };
  
  function mapStateToProps(state) {
    return {
    userInfoData: state.login.userInfoData,
    isFetchingLogin: state.login.isFetching,
    isFetchingUserInfo: state.login.isFetchingUserInfo,
    relationship: state.profile.relationship
    };
  }
  
  function mapDispatchToProps(dispatch) {
    return bindActionCreators(
      {
        getProfileInfo,
        getRelationshipList,
        updateProfileInfo
      },
      dispatch
    );
  }
  
  export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(ProfilePage);
