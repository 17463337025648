import React, { useEffect, useState } from "react";
import "../../../../../index.css";
import "../../styles/info.css"
import { Grid, Card, CardContent, CardActions, Typography, Button, Dialog, DialogTitle, DialogContent, DialogActions, Divider } from "@mui/material";
import UserAvatar from '../../../../../assets/icons/user-avatar.svg';
import PhoneIcon from '../../../../../assets/icons/grey-phone-icon.svg';
import AuthorisedUserFilled from '../../../../../assets/icons/authorisedUser-icon-filled.svg';
import DeleteFilledIcon from '../../../../../assets/images/filled-delete.svg';
import ResendFilledIcon from '../../../../../assets/icons/resend-invite-filled.svg';
import { useNavigate } from "react-router-dom";
import { connect, useDispatch } from 'react-redux';
import { bindActionCreators } from "redux";
import { getFamilyMembers,deleteUserDataApi,markAsAdminAPI, deleteUser, resendInvitationAPI} from "../../redux/actions";

import * as formatters from '../../../../../utils/util';

const MyFamily = (props) => {
    const {getFamilyMembers, familyMembers, isFetching,resendResult, error, deleteUser, deleteUserData, deleteUserDataApi,markAsAdminAPI,isAdminRequest, adminResult, resendInvitationAPI} = props;
    const userDetails = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')): null;

    const navigate = useNavigate();
    const [openAuthorisedUserDialog, setOpenAuthorisedUserDialog] = useState(false);
    const [removeUserDialog, setRemoveUserDialog] = useState(false);
    const userObject = (window.localStorage.getItem('userInfo') ? JSON.parse(window.localStorage.getItem('userInfo')) : null)
    const[userid,setuserid] = useState(null);
    const [emailId, setEmailId] = useState(null);
    const [selectedUser, setSelectedUser] = useState(null);
    const[familyMembersList, setFamilyMembersList] = useState([]);
    const [markAsAdminError, setMarkAsAdminError] = useState(null);
    const [resendInvitation, setResentInvitation] = useState(null);
    const dispatch = useDispatch();

    useEffect(()=>{
        getFamilyMembers()
        window.localStorage.removeItem('selectedUser')

    },[])

    useEffect(()=>{
      if(familyMembers && familyMembers.status === 200){
        setFamilyMembersList(familyMembers?.data)
      }
      if(familyMembers && familyMembers.status !== 200){
      }
    },[familyMembers])

    useEffect(()=>{
      if(deleteUserData && deleteUserData.status === 200){
        getFamilyMembers()
        setRemoveUserDialog(false);
        deleteUser(emailId);
        setEmailId(null);
        setSelectedUser(null);
      }
    },[deleteUserData])

    useEffect(()=>{
      if(adminResult && adminResult.status === 200){
        closeMarkAsAdminModal()
        getFamilyMembers()
      }
      if(adminResult && adminResult.status !== 200){
        setMarkAsAdminError(adminResult.message)
      }
    },[adminResult])

    useEffect(()=>{
      if(resendResult && resendResult.status === 200){
        setSelectedUser(null);
        getFamilyMembers();
        setResentInvitation(false);
      }
    },[resendResult])

    const editFamily= (id) =>{
    
      navigate('/add-members-info');
      const data = {
          userId: id
      }
      window.localStorage.setItem('selectedUser', JSON.stringify(data))
    }
    const familymemberdelete = () => {
      deleteUserDataApi(userid); 
    }

    const markAsAdmin = () => {
      const payload = {
        accountId: userDetails?.accountId ? userDetails.accountId : '',
        userId: userid
      }
       markAsAdminAPI(payload);
    }

    const closeMarkAsAdminModal = () => {
      setMarkAsAdminError(null);
      setOpenAuthorisedUserDialog(false);
      setSelectedUser(null);
    };

    const resendUserInvitation = () => {
      const payload = {
        "senderUserId": userDetails?.id,
        "receiverUserId": selectedUser?.id
      }
      resendInvitationAPI(payload)
    }

  return (
    <Grid container sx={{m:0}}  >
      {/* <Grid item xs={1} md={1}></Grid> */}
      <div className="info-header-container">

      <Grid 
     
        container
        xs={12}
        md={12}
        lg={12}
        style={{ display: "flex",
         justifyContent: 'center', alignItems: 'center',
          flexWrap: 'wrap', 
        padding: '40px 0px', gap: 30}}
      >
        {familyMembersList.map((member, i)=> {
            return (
              <>
   
                <Card sx={{ minWidth: 246 }} className="card-box" key={'familyMemberCard_'+i}>
                <div style={{textAlign: 'center'}}>
                    <img src={member.image_PreSignedUrl ? member.image_PreSignedUrl : UserAvatar}  alt={member.imageAlt} width={87} height={87} style={{borderRadius: '100%',objectFit:'fill', border: '1px solid #DDDFE1'}}/>
                </div>
                <CardContent className="card-content-box">
                    <Typography style={{marginTop: 23 , textTransform:"capitalize"}} className="primary-font-medium name-wrapper">
                        {member?.firstName} {member?.middleName} {member?.lastName}
                    </Typography>
                    <Typography style={{marginTop: 10, display: 'flex'}} className="font-normal-small" color='#8D8D8D'>
                       <img src={PhoneIcon} alt='phone' style={{marginRight: 10}} /> {member.phoneNo || '-'}
                    </Typography>
                    {member.statusCode === 'pending' ? (
                        <div className="admin-inprogress-box" style={{marginTop: 13, textTransform: 'capitalize',background:"#f0f0f0",color:"#000"}} >{"Unverified"}</div>
                    ): (member.statusCode === 'verified' ? 
                          <div className="admin-box" style={{marginTop: 13, textTransform: 'capitalize'}} >{member.userRoleCode}</div> : 
                          ( <div className="admin-inactive-box" style={{marginTop: 13, textTransform: 'capitalize'}} >{member.userRoleCode}</div>)
                          )}
                    <Divider style={{borderColor: '#D8D8D8', width: '100%', marginTop: 10, marginBottom: 10}}/>
                   
                    </CardContent>
                <CardActions style={{display: 'flex',justifyContent:'center',alignItems:'center', padding: 0}}>
                {(member.userRoleCode === "owner" || userDetails.userRoleCode !== "owner" )? <div className="inactive-button-dlt"></div> :  <div title={"Delete"} className="action-button-dlt" onClick={() => { setuserid(member.id); setRemoveUserDialog(true); setEmailId(member.emailId); setSelectedUser(member);}}></div> }
                {(((member.userRoleCode !== "owner" && member.userRoleCode !== "admin") && member.dateOfBirth && (formatters.calculateAge(member.dateOfBirth) > 17)) &&(userDetails.userRoleCode === "owner" ))&& (
                <div title={"Make as Authorized User"} className="action-button-user" onClick={() => {
                   setuserid(member.id); setOpenAuthorisedUserDialog(true); setEmailId(member.emailId);setSelectedUser(member);
                    }}></div>)}
                {(userDetails.userRoleCode === "owner" || (userDetails.userRoleCode === "admin" && member.userRoleCode !== "owner") ||  (member.id === userDetails?.id)) ?  <div title={"Edit"} className="action-button-edit" onClick={() => { editFamily(member?.id) }}></div> :  <div className="inactive-button-edit" ></div>}
                {((userDetails.userRoleCode === "owner" || userDetails.userRoleCode === "admin") && member.statusCode === 'pending') ?  <div title={"Resend Invite"} className="action-button-resent" onClick={() => { setResentInvitation(true);setSelectedUser(member); }}></div> :  <></>}



                </CardActions>
            </Card>
      
             {openAuthorisedUserDialog && (
              <Dialog
              open={openAuthorisedUserDialog}
              onClose={()=> closeMarkAsAdminModal()}
              className="small-dialog dialog-box"
              aria-labelledby="dialog-title"
              aria-describedby="dialog-description"
              >
                  <DialogContent className="content-dialog">
                      <img src={AuthorisedUserFilled} alt='phone' style={{marginRight: 10}} width='118px' height='118px' />
                      <Typography  className="primary-font-normal-4">Do you want to make <b style={{textTransform:"capitalize"}}>{selectedUser?.firstName} {selectedUser?.middleName} {selectedUser?.lastName}</b> as Authorized User?</Typography>
                  </DialogContent>
                  <Divider />
                  <DialogActions>
                  <div className="action-holder" style={{marginTop: 20, marginBottom: 0, gap: 40}}>
                      <Button variant="outlined" className="primary-font-bold-5 action-btn-no profile-cancel-btn" onClick={()=> closeMarkAsAdminModal()}>No</Button>
                      <Button variant="outlined" className="primary-font-bold-5 action-btn-yes profile-save-btn" disabled={isAdminRequest} onClick={()=> markAsAdmin()}>Yes</Button>
                  </div>
                  </DialogActions>
              </Dialog>
          )}
          {removeUserDialog && (
              <Dialog
              open={removeUserDialog}
              onClose={()=> {setRemoveUserDialog(false);setSelectedUser(null)}}
              className="small-dialog dialog-box"
              aria-labelledby="dialog-title"
              aria-describedby="dialog-description"
              >
                  <DialogContent className="content-dialog">
                      <img src={DeleteFilledIcon} alt='phone' style={{marginRight: 10}} width='118px' height='118px' />
                      <Typography  className="primary-font-normal-4">Are you sure want to delete <b style={{textTransform:"capitalize"}}>{selectedUser?.firstName} {selectedUser?.middleName} {selectedUser?.lastName}</b>?</Typography>
                  </DialogContent>
                  <Divider />
                  <DialogActions>
                  <div className="action-holder" style={{marginTop: 20, marginBottom: 0, gap: 40}}>
                  <Button variant="outlined" className="primary-font-bold-5 action-btn-no profile-cancel-btn" onClick={()=> {setRemoveUserDialog(false);setSelectedUser(null)}}>No</Button>
                      <Button variant="outlined" className="primary-font-bold-5 action-btn-yes profile-save-btn" onClick={ ()=>{familymemberdelete() } }>Yes</Button>
                  </div>
                  </DialogActions>
              </Dialog>
          )}
           {resendInvitation && (
              <Dialog
              open={resendInvitation}
              onClose={()=> {setResentInvitation(false);setSelectedUser(null)}}
              className="small-dialog dialog-box"
              aria-labelledby="dialog-title"
              aria-describedby="dialog-description"
              >
                  <DialogContent className="content-dialog">
                      <img src={ResendFilledIcon} alt='phone' style={{marginRight: 10}} width='118px' height='118px' />
                      <Typography  className="primary-font-normal-4">Do you want to resend the invitation to <b style={{textTransform:"capitalize"}}>{selectedUser?.firstName} {selectedUser?.middleName} {selectedUser?.lastName}</b>?</Typography>
                  </DialogContent>
                  <Divider />
                  <DialogActions>
                  <div className="action-holder-1" style={{marginTop: 20, marginBottom: 0}}>
                  <Button variant="outlined" className="primary-font-bold-5 action-btn-no profile-cancel-btn" onClick={()=> {setResentInvitation(false);setSelectedUser(null)}}>No</Button>
                      <Button variant="outlined" className="primary-font-bold-5 action-btn-yes profile-save-btn" onClick={ ()=>{resendUserInvitation() } }>Yes</Button>
                  </div>
                  </DialogActions>
              </Dialog>
          )}
          </>
            )
        })}
        {/* {familyMembersList.length <= 9 && (userDetails.userRoleCode === "owner" || userDetails.userRoleCode === "admin" )&& (
            <Card sx={{ minWidth: 246 }} className="add-card-box" onClick={()=>{navigate('/add-members-info')}}>
                <div className="add-icon">
                </div>
                <Typography style={{textAlign: 'center', marginTop: 41}} className="primary-font-medium">
                    Add <br /> Family Member
                </Typography>
            </Card>
        ) } */}
        </Grid>
      </div>
      {/* <Grid item xs={2} md={2}></Grid> */}
    </Grid>
  );
};


MyFamily.defaultProps = {
    isFetching: null,
    error: null,
    familyMembers: [],
  };
  
  function mapStateToProps(state) {
    return {
        isFetching: state.familyInfo.isFetching,
        error: state.familyInfo.error,
        familyMembers: state.familyInfo.familyMembers,
        deleteUserData:state.familyInfo.deleteUserData,
        isAdminRequest: state.familyInfo.isAdminRequest,
        adminResult: state.familyInfo.adminResult,
        resendResult: state.familyInfo.resendResult
    };
  }
  
  function mapDispatchToProps(dispatch) {
    return bindActionCreators(
      {
        getFamilyMembers,
        deleteUserDataApi,
        markAsAdminAPI,
        deleteUser,
        resendInvitationAPI
      },
      dispatch
    );
  }
  
  export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(MyFamily);