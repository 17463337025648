import React, { useState, useEffect } from "react";
import "../../../../../../index.css";
import { Grid, TextField, Typography, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { connect , useDispatch} from "react-redux";
import { bindActionCreators } from "redux";
import { resetDetails, addBankInformationAPI,updatedBankInformation } from "../../../redux/actions";


const SpecialNotes = ({onBackOrCancelClick, bankAccountData,addBankInformationAPI, isAPIProcessing, addBankAccount,addBankAccountError,updatedBankInformation}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [specialInstructions, setSpecialInstructions] = useState(null);
  const [specialInstructionsErr, setSpecialInstructionsErr] = useState('');
  const userDetails = window.localStorage.getItem("userInfo") ? JSON.parse(window.localStorage.getItem("userInfo")): null;
  const hideEdit = window.localStorage.getItem("hideEdit") ? window.localStorage.getItem("hideEdit") : false;
useEffect(()=>{
  if(bankAccountData && !specialInstructions){
    setSpecialInstructions(bankAccountData?.specialNotes);
  }
},[])

useEffect(()=>{
  if(addBankAccount && addBankAccount.status ===200){
    // resetDetails()
    navigate('/my-bank-accounts')
  } else if(addBankAccount && addBankAccount.status === 500){
    setSpecialInstructionsErr(addBankAccount.message)
  } else if(addBankAccountError && addBankAccountError.status === 400){
    setSpecialInstructionsErr("Something went wrong")
  }

},[addBankAccount, addBankAccountError])
  const onSave = () => {
    const input = {
      specialNotes: specialInstructions
    }
    const payload = {...bankAccountData,...input};
    dispatch({
      type:"ADD_BANK_DETAILS",
      payload: payload
    })
    if(!payload.id){
      payload.createdBy = userDetails?.id
      payload.accountId = userDetails?.accountId
      addBankInformationAPI(payload)
    }else{
      payload.updatedBy = userDetails?.id
      updatedBankInformation(payload)
    }
   
  }
  
  return (
    <Grid container className="account-info-container" >
        <Grid item xs={12} md={12} sx={{padding: 0}}>
            <div className="account-info-padding" >
                <Typography className='font-normal-small error-message'>{specialInstructionsErr}</Typography>
                    <Typography className='font-normal-small profile-input-label'>Special Notes</Typography>
                    <TextField
                    placeholder=""
                    className="txt-area"
                    multiline
                    value={specialInstructions}
                    onChange={(e)=> {setSpecialInstructions(e.target.value)}}
                    disabled={hideEdit}
                    rows={6}
                    maxRows={10}
                    style={{width: '100%'}}
                    />
            </div>
      </Grid>
      <div className="action-holder action-holder-3 btn-holder-margin special-instruction-btn-holder">
        <Button variant="outlined"  className="primary-font-bold-6 btn-width profile-cancel-btn" disabled={isAPIProcessing} onClick={onBackOrCancelClick}>Back</Button>
        {!hideEdit && <Button variant="outlined" className="primary-font-bold-6 btn-width profile-save-btn" disabled={isAPIProcessing} onClick={onSave}>Save</Button>}
        {hideEdit && <Button variant="outlined" className="primary-font-bold-6 btn-width profile-save-btn" disabled={isAPIProcessing} onClick={()=>{navigate('/my-bank-accounts')}}>Close</Button>}
      </div>
    </Grid>
  );
};


SpecialNotes.defaultProps = {
  isFetching: null,
  error: null,
  familyMembers: [],
};

function mapStateToProps(state) {
  return {
    bankAccountData : state.bank.bankAccountData,
    addBankAccount: state.bank.addBankAccount,
    addBankAccountError: state.bank.addBankAccountError,
    isAPIProcessing:state.bank.isAPIProcessing
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      addBankInformationAPI,
      resetDetails,
      updatedBankInformation
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SpecialNotes);


