import React from "react";
import ContentWrapper from "../../../../common/components/ContentWrapper";
import HomePage from "./HomePage";
import { useTranslation } from "react-i18next";

const HomeComponent = () => {
  const { t } = useTranslation("common");

  return (
    <>
      <ContentWrapper
        title={t("MY INFO")}
        gobackUrl={"#"}
        contenview={<HomePage />}
        img={false}
      
      />
    </>
  );
};

export default HomeComponent;
