import React, { useState, useEffect, useContext, useRef,useMemo } from "react";
import { Grid, Typography, Button, Dialog, DialogContent,Box } from "@mui/material";
import ContentWrapper from "../../../../ContentWrapper";
import { useNavigate } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect, useDispatch } from "react-redux";
import LiveTrackingMeetingLocation from "../../EmergencyAlert/components/LiveTrackingMeetingLocation";
import { checkIsTracking, getLiveLocation, stopTrackingAPI, updateLocationAPI } from "../../../redux/actions";
import { UserGeolocationContext, UserInfoDetails, preDefinedMessage } from "../../../../../../utils/userContext";
import EditIcon from "@mui/icons-material/Edit";
import"../styles/TrackMe.css";

const MainLayout = ({ trackView, isLive,  stopTrackingAPI,
    trackMeStopResponse,
    updateLocationAPI,
    updateLiveLocation,
    setSuccessDialuge,
    setMessage
 }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { userObject } = useContext(UserInfoDetails)

    const [msgList, setMsgList] = useState(false);
    const { messageList } = useContext(preDefinedMessage);
    const userLocation = useContext(UserGeolocationContext);
    const currentLocation = { ...userLocation, ...userObject };
    const [coordinates, setCoordinates] = useState({ lat: null, lng: null });
    const [isTracking, setIsTracking] = useState(isLive);
    const intervalRef = useRef(null);


    const fetchLocation = () => {
        navigator.geolocation.getCurrentPosition(
            (position) => {
                const { latitude, longitude } = position.coords;
                setCoordinates({ lat: latitude, lng: longitude });
                triggerUpdateApi({ lat: latitude, lng: longitude }); 
            },
            (error) => {
                console.error('Error getting location:', error);
            }
        );
    };
    
    const handleApiCall = () => {
        let payload = {
            "userId": userObject.id,
            "trackMeId": trackView.id,
        }
        stopTrackingAPI(payload);
    };

    const triggerUpdateApi = (data) => {
        
        let payload = {
            "userId": userObject.id,
            "trackMeId": trackView?.id,
            "locationLat": data.lat,
            "locationLong": data.lng,
            "trackingType":"trackMe",
        }
        // console.log("payload",payload);
        if (
            payload.userId &&
            payload.trackMeId &&
            payload.locationLat &&
            payload.locationLong
        ) {
            updateLocationAPI(payload); 
        }else{
            console.log("Fetching location failed");
        }
    }

    

    useEffect(() => {
        if (updateLiveLocation) {
            if (updateLiveLocation && updateLiveLocation?.status === 200) {
                if (updateLiveLocation?.data) {
                }
            } else {
                console.log(updateLiveLocation?.message);
            }
        }
    }, [updateLiveLocation])


    useEffect(() => {
        if (trackMeStopResponse) {
            if (trackMeStopResponse && trackMeStopResponse?.status === 200) {
                localStorage.setItem("isTrackingEnabled", false);
                setMessage("Tracking stopped successfully");
                setSuccessDialuge(true);
                dispatch({
                    type: "TRACK_STOP_SUCCESS",
                    payload: {}
                  })
                setTimeout(() => {
                    setSuccessDialuge(false);
                    setMessage("");
                    navigate('/track-me')
                }, 1000);
            }
        }

    }, [trackMeStopResponse])

    useEffect(() => {
        let intervalId;

        if (isTracking) {
            intervalRef.current = setInterval(fetchLocation, 20000); // Fetch location every 20 seconds
        } else {
            clearInterval(intervalRef.current); // Clear interval if tracking is stopped
        }

        return () => {
            clearInterval(intervalRef.current); // Cleanup interval on unmount
        };
    }, [isTracking]);

    const handleStopTracking = () => {
        setIsTracking(false);
        handleApiCall();
    };


    useEffect(() => {
        if (trackView) {
            if (trackView?.messages) {
                const inputCodesString = trackView?.messages;
                const inputCodesArray = inputCodesString.split(',').map(code => parseInt(code.trim(), 10));
                const filteredMessages = messageList.filter(item => inputCodesArray.includes(item.code)).map(item => item.message);
                setMsgList(filteredMessages)
            }
        }
    }, [trackView])

    return (
        <Grid container style={{ width: "100%", margin: 0 }}>
            {/* <Grid item xs={1} md={1} lg={1}></Grid> */}
            <Grid className="track-me-container">
            <Grid container
                xs={12}
                md={12}
                lg={12}
                className="box-container"
            >
                <Grid container style={{ width: "100%", marginBottom: 10, display: "flex", justifyContent: "center" }}>
                   {isLive && ( <Grid item xs={12}>
                        <div  className="box-header" >
                            <Typography className="primary-font-bold-2-heading">
                                Share my location
                            </Typography>
                            <Button
                                variant="outlined"
                                className="primary-font-bold-6 action-btn-yes profile-save-btn"
                                onClick={() => navigate("/share-my-location",{ state: { trackData: trackView,isUpdate:true } })}
                            >
                                <EditIcon style={{ fontSize: "19px", marginRight: "2px" }}></EditIcon>
                                Edit
                            </Button>
                        </div>
                    </Grid>)}
                    <Grid container  style={{ margin: 0, width: '100%',justifyContent: "center"}}>
                    <Grid item className="meeting-location" xs={12} md={isLive?6 : 12} lg={isLive?6 : 12}>
                        <div id="meeting-lcoation" style={{ marginTop: "20px" }}>
                            <LiveTrackingMeetingLocation membersList={trackView?.membersList || trackView} meetingLocation={isLive ? currentLocation : {}} />
                        </div>
                    </Grid>
                    {isLive && (<Grid item className='location-card' xs={12} md={6} lg={6}>
                        <div >
                            <Grid
                                style={{
                                    marginTop: "20px",
                                    marginBottom: "20px",
                                    // width: "95%",
                                }}
                            >
                                {trackView &&
                                    trackView.membersList &&
                                    trackView.membersList.map((row) => {
                                        return (
                                            <>
                                                <Grid
                                                    container
                                                    key={row.id}
                                                    sx={{
                                                        mr: "12px",
                                                        padding: "12px",
                                                        border: "1px solid #D8D8D8",
                                                        borderRadius: "10px",
                                                        marginBottom: "10px"
                                                    }}
                                                //   onClick={() => { panToLocation(row) }}
                                                >
                                                    <Grid
                                                        xs={12}
                                                        md={8.5}
                                                        lg={8.5}
                                                        sx={{
                                                            justifyContent: "start",
                                                            textTransform: "capitalize",
                                                        }}
                                                    >
                                                        <Typography className="primary-font-bold-5">
                                                            {row.name
                                                                ? row.name
                                                                : row.firstName +
                                                                " " +
                                                                (row?.middleName && row?.middleName !== null ? row.middleName : "") +
                                                                " " +
                                                                (row?.lastName && row?.lastName !== null ? row?.lastName : "")}
                                                        </Typography>
                                                    </Grid>

                                                </Grid>
                                            </>
                                        );
                                    })}

                            </Grid>
                        </div>
                        <div style={{ marginBottom: "20px", }}>
                            {trackView && trackView.messages && (
                                <Typography className="primary-font-bold-5" sx={{ my: 2 }}>Message</Typography>
                            )}
                            {/* <ul> */}
                            {trackView &&
                                trackView.messagesList &&
                                trackView.messagesList.map((item, index) => {
                                    return (

                                        <>
                                            <Grid
                                                container
                                                key={index}
                                                sx={{
                                                    mr: "12px",
                                                    padding: "12px",
                                                    border: "1px solid #D8D8D8",
                                                    borderRadius: "10px",
                                                    marginBottom: "10px"
                                                }}
                                            >
                                                <Grid
                                                    xs={12}
                                                    md={8.5}
                                                    lg={8.5}
                                                    sx={{
                                                        justifyContent: "start",
                                                        textTransform: "capitalize",
                                                    }}
                                                >
                                                    <Typography className="">
                                                        {item.message}
                                                    </Typography>
                                                </Grid>


                                            </Grid>
                                        </>
                                    )

                                })}

                            {msgList && msgList.map((item, index) => {
                                return (

                                    <>
                                        <Grid
                                            container
                                            key={index}
                                            sx={{
                                                mr: "12px",
                                                padding: "12px",
                                                border: "1px solid #D8D8D8",
                                                borderRadius: "10px",
                                                marginBottom: "10px"
                                            }}
                                        >
                                            <Grid
                                                xs={12}
                                                md={8.5}
                                                lg={8.5}
                                                sx={{
                                                    justifyContent: "start",
                                                    textTransform: "capitalize",
                                                }}
                                            >
                                                <Typography className="">
                                                    {item}
                                                </Typography>
                                            </Grid>


                                        </Grid>
                                    </>
                                )

                            })}

                            {/* <textarea 
              className="font-family-default"
                style={{
                  width: "475px",
                  height: "60px",
                  background: " #FFFFFF 0% 0 % no - repeat padding- box",
                  boxShadow: "0px 3px 6px #00000029",
                  borderRadius: "10px",
                  opacity: 1,
                  outline: "none",
                  border: "none",
                  padding: "18px 16px",
                  margin:"10px 0",
                  resize: "none"
                }} 
                maxLength={500}
                placeholder="Type here..."></textarea> */}
                        </div>

                        {isLive && (<div className="stop-tracking-btn-box">
                            <Button
                               
                                variant="outlined"
                                className="primary-font-bold-6 action-btn profile-save-btn stop-tracking-btn"
                                onClick={() => {
                                    handleStopTracking()
                                }}
                            >
                                {"Stop Tracking"}

                            </Button>
                        </div>)}


                    </Grid>)}
                    </Grid>

                </Grid>
            </Grid>
            </Grid>
        </Grid>
    );
};

MainLayout.defaultProps = {
    isFetching: null,
    error: null,
    familyMembers: [],
};


function mapStateToProps(state) {
    return {
        trackMeReadResponse: state.trackMe.trackMeReadResponse,
        getLiveLocationResponse: state.trackMe.getLiveLocationResponse,
        trackMeStopResponse: state.trackMe.trackMeStopResponse,
        updateLiveLocation: state.trackMe.updateLiveLocation,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            checkIsTracking,
            getLiveLocation,
            stopTrackingAPI,
            updateLocationAPI
        },
        dispatch
    );
}
export default connect(mapStateToProps, mapDispatchToProps)(MainLayout);