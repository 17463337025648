const initialState = {
  data: null,
  isFetching: false,
  error: null,
  userInfoData: null,
  isFetchingUserInfo: false,
  passwordData: null,
  passwordError: null,
  isVerifyingPassword: false,
  isUpdatingUserInfo: false,
  updatedData: null,
  lockData: null,
  isVerifyingLock: false,
  lockError: null,
  profilePictureData: null,
  profilePictureError: null,
  isVerifyingPic: false,
  relationship:[],
  verifyLockData: null,
  verifyLockError: null,
  profileDetails: null,
  stateList: null,
};

export const profileReducer = (state = initialState, action) => {
  const {type, payload} = action;
  let currentState = state;
  switch(type) {
      case 'USERINFO_DATA_REQUEST': 
      currentState = {
        ...state,
        updatedData: null,
        isFetchingUserInfo: true,
        error: null,
      };
      break;
      case 'USERINFO_DATA_SUCCESS': 
      currentState = {
        ...state,
        userInfoData: payload,
        isFetchingUserInfo: false,
        error: null,
      };
      break;
      case 'USERINFO_DATA_FAILURE': 
      currentState = {
        ...state,
        isFetchingUserInfo: false,
        error: payload,
      };
      break;
      case 'USERINFO_UPDATE_REQUEST': 
      currentState = {
        ...state,
        updatedData: null,
        isUpdatingUserInfo: true,
        error: null,
      };
      break;
      case 'USERINFO_UPDATE_SUCCESS': 
      currentState = {
        ...state,
        isUpdatingUserInfo: false,
        updatedData: payload,
      };
      break;
      case 'USERINFO_UPDATE_FAILURE': 
      currentState = {
        ...state,
        isUpdatingUserInfo: false,
        error: payload,
      };
      break;
      case 'PASSWORD_UPDATE_REQUEST': 
      currentState = {
        ...state,
        isVerifyingPassword: true,
        error: payload,
      };
      break;
      case 'PASSWORD_UPDATE_SUCCESS': 
      currentState = {
        ...state,
        passwordData: payload,
        isVerifyingPassword: false,
        passwordError: null,
      };
      break;
      case 'PASSWORD_UPDATE_FAILURE': 
      currentState = {
        ...state,
        passwordData:null,
        isVerifyingPassword: false,
        passwordError: payload,
      };
      break;
      case 'SECURITY_LOCK_UPDATE_REQUEST': 
      currentState = {
        ...state,
        isVerifyingLock: true,
        error: payload,
      };
      break;
      case 'SECURITY_LOCK_UPDATE_SUCCESS': 
      currentState = {
        ...state,
        lockData: payload,
        isVerifyingLock: false,
        lockError: null,
      };
      break;
      case 'SECURITY_LOCK_UPDATE_FAILURE': 
      currentState = {
        ...state,
        lockData:null,
        isVerifyingLock: false,
        lockError: payload,
      };
      break;
      case 'PROFILE_PICTURE_UPDATE_REQUEST': 
      currentState = {
        ...state,
        isVerifyingPic: true,
        profilePictureError: payload,
      };
      break;
      case 'PROFILE_PICTURE_UPDATE_SUCCESS': 
      currentState = {
        ...state,
        profilePictureData: payload,
        isVerifyingPic: false,
        profilePictureError: null,
      };
      break;
      case 'PROFILE_PICTURE_UPDATE_FAILURE': 
      currentState = {
        ...state,
        profilePictureData:null,
        isVerifyingPic: false,
        profilePictureError: payload,
      };
      break;
      case 'RELATIONSHIP_DATA_SUCCESS':
        currentState = {
          ...state,
          relationship: payload
        };
        break;
        case 'SECURITY_LOCK_VERIFY_REQUEST': 
        currentState = {
          ...state,
          isVerifyingLock: true,
          error: payload,
        };
        break;
        case 'SECURITY_LOCK_VERIFY_SUCCESS': 
        currentState = {
          ...state,
          verifyLockData: payload,
          isVerifyingLock: false,
          verifyLockError: null,
        };
        break;
        case 'SECURITY_LOCK_VERIFY_FAILURE': 
        currentState = {
          ...state,
          verifyLockData:null,
          isVerifyingLock: false,
          verifyLockError: payload,
        };
        break;
        case 'SECURITY_LOCK_RESET':
          currentState = {
            ...state,
            verifyLockData:null,
            isVerifyingLock: false,
            verifyLockError: null
          };
         break;
         case 'UPDATE_PROFILE':
          currentState = { 
            ...state, 
            profileDetails: payload
           };
          break;
          case'STATE_REQUEST':
          currentState = {
            ...state,
           isFetching: true,
             error: null,
            };
          break;
          case 'GET_STATE_SUCCESS': 
          currentState = {
          ...state,
          stateList: payload,
          isFetching: false,
          error: null,
          };
          break;
           case 'GET_STATE_FAILURE': 
          currentState = {
            ...state,
           isFetching: true,
            error: payload,
          };
          break;

      default:
        
  }
  return currentState;
}
