import React, { useEffect, useState } from "react";
import { Grid, Typography, Button } from "@mui/material";
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import DatePicker from "react-date-picker";
import moment from "moment";
import { bindActionCreators } from "redux";
import { connect, useDispatch } from "react-redux";
import * as formatters from '../../../../../../utils/util';

const DebitCardDetails = ({onBackOrCancelClick, handleNext,bankAccountData}) => {
  const initialFormData = {
    associatedBankCard: '',
    cardNumber: '',
    expiryDate: '',
    cardPinNumber: '',
    cvv: '',
   cardPhoneNo:''
  };
  const hideEdit = window.localStorage.getItem("hideEdit") ? window.localStorage.getItem("hideEdit") : false;

  const dispatch = useDispatch()
  const [formData, setFormData] = useState(initialFormData);
  const [errors, setErrors] = useState(initialFormData);

  const [showCode, setShowCode] = useState(false);
  const [showCVV, setShowCVV] = useState(false);
  const handleClickShowCode = () => setShowCode(!showCode);
  const handleMouseDownCode = () => setShowCode(!showCode);
  
  useEffect(()=>{
    if(bankAccountData){
      console.log(bankAccountData)
     const initialFormData = {
      associatedBankCard: bankAccountData?.associatedBankCard,
        cardNumber:  bankAccountData?.cardNumber,
        expiryDate:  bankAccountData?.cardExpiryDate,
        cardPinNumber:  bankAccountData?.cardPinNo,
        cvv:  bankAccountData?.cardCvv,
       cardPhoneNo: bankAccountData?.cardPhoneNo
      };
      setFormData(initialFormData)
      console.log(formData,'formData')
    }
  },[bankAccountData])

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    console.log(formData,"formData");
  };

  const handleDateChange = (e) => {
    if(e){
      const date =  moment(e).format('YYYY-MM-DD') 
      setFormData({
        ...formData,
        ['expiryDate']: date,
      });
    }else{
      setFormData({
        ...formData,
        ['expiryDate']: e,
      });
    }
   
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    const newErrors = {};
    
    if (!formData?.associatedBankCard || formData?.associatedBankCard?.trim() === '') {
      newErrors.associatedBankCard = 'This is required.';
    }
    if (!formData?.cardNumber || formData?.cardNumber?.trim() === '') {
      newErrors.cardNumber = 'This is required.';
    }else  if ( formData?.cardNumber && formData?.cardNumber?.trim().length !== 16) {
      newErrors.cardNumber = 'Card number should be 16 digits length.';
    }
    if (!formData?.expiryDate || formData.expiryDate?.trim() === '') {
      newErrors.expiryDate = 'This is required.';
    }
    if (!formData?.cardPinNumber || formData?.cardPinNumber?.trim() === '') {
      newErrors.cardPinNumber = 'This is required.';
    } else if (formData?.cardPinNumber && (formData?.cardPinNumber?.trim().length< 4 || formData?.cardPinNumber?.trim().length > 6)) {
      newErrors.cardPinNumber = 'Enter valid card pin number.';
    } 
    if (!formData.cvv || formData?.cvv?.trim() === '') {
      newErrors.cvv = 'This is required.';
    } if (formData?.cvv && (formData?.cvv.trim()?.length < 3 || formData?.cvv.trim()?.length > 4)) {
      newErrors.cvv = 'Enter valid cvv number.';
    } 
    if (Object.keys(newErrors).length === 0) {
      setErrors({});
    
      const accountInfo = {
        associatedBankCard: formData?.associatedBankCard,
        cardNumber:  formData?.cardNumber,
        cardExpiryDate:  formData?.expiryDate,
        cardPinNo:  formData?.cardPinNumber,
        cardCvv:  formData?.cvv,
       cardPhoneNo: formData?.cardPhoneNo
      };
      const accountDetails = {...bankAccountData, ...accountInfo}
      console.log(accountDetails, 'accountsDetials')
      dispatch({
        type:"ADD_BANK_DETAILS",
        payload: accountDetails
      })
      handleNext();
    } else {
      setErrors(newErrors);
      console.log('Validation errors:', newErrors);
    }
  };

return (
    <Grid container className="account-info-container" >
        <Grid item xs={12} md={6} sx={{padding: 0}}>
            <div className="account-info-padding" >
                <div>
                    <Typography className='font-normal-small error-message'>{errors.associatedBankCard}</Typography>
                    <Typography className='font-normal-small profile-input-label'>Associated Bank card <span style={{color:"red"}}>{" *"}</span></Typography>
                    <input name="associatedBankCard" disabled={hideEdit} id="associatedBankCard" className={`profile-input ${errors.associatedBankCard ? 'err-input' : ''}`} aria-label='Associated Bank card' onChange={handleInputChange}  value={formatters.formatText(formData.associatedBankCard)}/>
                </div>
                <div>
                    <Typography className='font-normal-small error-message'>{errors.cardNumber}</Typography>
                    <Typography className='font-normal-small profile-input-label'>Card Number <span style={{color:"red"}}>{" *"}</span></Typography>
                    <input name="cardNumber" disabled={hideEdit} id="cardNumber" className={`profile-input ${errors.cardNumber ? 'err-input' : ''}`} aria-label='Card Number' onChange={handleInputChange}    maxLength={16} value={formatters.formatDebitCardNumber(formData.cardNumber)}/>
                </div>
                <div>
                    <Typography className='font-normal-small error-message'>{errors.expiryDate}</Typography>
                    <Typography className='font-normal-small profile-input-label'>Expiry Date <span style={{color:"red"}}>{" *"}</span></Typography>
                    <DatePicker name="expiryDate" disabled={hideEdit} minDate={new Date(1900, 0, 1)} className={`profile-input ${errors.expiryDate ? 'err-input' : ''}`} onChange={handleDateChange} value={formData.expiryDate}  />
                    {/* <input name="expiryDate" id="expiryDate" className={`profile-input ${errors.expiryDate ? 'err-input' : ''}`} aria-label='Expiry Date' onChange={handleInputChange}     value={formData.expiryDate}/> */}
                </div>
               
            </div>
        </Grid>
        <Grid item xs={12} md={6} sx={{padding: 0}}>
            <div className="account-info-padding" >
                <div>
                    <Typography className='font-normal-small error-message'>{errors.cardPinNumber}</Typography>
                    <Typography className='font-normal-small profile-input-label'>Card PIN Number <span style={{color:"red"}}>{" *"}</span></Typography>
                    <div className="input-holder"> 
                        <input name="cardPinNumber" disabled={hideEdit} id="cardPinNumber" className={`profile-input ${errors.cardPinNumber ? 'err-input' : ''}`}  aria-label='Card PIN Number' type={showCode ? "text" : "password"} onChange={handleInputChange}  minLength={4} maxLength={6} value={formatters.formatNumber(formData.cardPinNumber)}/>
                        <IconButton type="button" sx={{ p: '10px' }} aria-label="Card PIN Number" 
                        onClick={handleClickShowCode}
                        onMouseDown={handleMouseDownCode}
                        className="input-eye-icon pwd-icon1"
                        >
                        {showCode ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                    </div>
                </div>
                <div>
                    <Typography className='font-normal-small error-message'>{errors.cvv}</Typography>
                    <Typography className='font-normal-small profile-input-label'>CVV <span style={{color:"red"}}>{" *"}</span></Typography>
                    <div className="input-holder"> 
                        <input name="cvv" disabled={hideEdit} id="cvv" className={`profile-input ${errors.cvv ? 'err-input' : ''}`}  aria-label='CVV' type={showCVV ? "text" : "password"} onChange={handleInputChange} minLength={3} maxLength={4} value={formatters.formatNumber(formData.cvv)}/>
                        <IconButton type="button" sx={{ p: '10px' }} aria-label="CVV" 
                        onClick={()=>{setShowCVV(!showCVV)}}
                        onMouseDown={()=>{setShowCVV(!showCVV)}}
                        className="input-eye-icon pwd-icon1"
                        >
                        {showCVV ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                    </div>
                </div>
                <div>
                  <Typography className='font-normal-small profile-input-label'>Bank Card Phone Number</Typography>
                  <div className="input-holder">
                  <input type="tel" name="cardPhoneNo" id="cardPhoneNo" disabled={hideEdit} className={`profile-input`}  aria-label='relation'  onChange={handleInputChange}  maxLength={10} value={formatters.formatPhoneNumber(formData.cardPhoneNo)}/>
                  </div>
                </div>
            </div>
        </Grid>
        <div className="action-holder action-holder-3 btn-holder-margin">
          <Button variant="outlined"  className="primary-font-bold-6 btn-width profile-cancel-btn" onClick={onBackOrCancelClick}>Back</Button>
          <Button variant="outlined" className="primary-font-bold-6 btn-width profile-save-btn"  onClick={handleSubmit}>Next</Button>
        </div>
    </Grid>
    )
}


DebitCardDetails.defaultProps = {
  isFetching: null,
  error: null,
  familyMembers: [],
};

function mapStateToProps(state) {
  return {
    bankAccountData : state.bank.bankAccountData
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DebitCardDetails);

