import React, { useState, useEffect } from "react";
import {
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  Divider,
  DialogActions,
  Button,
  FormControl,
  Select,
  MenuItem,
  Box,
} from "@mui/material";
import CloseIcon from "../../../../../assets/icons/modal-close-icon.svg";
import {
  addPredefinedMessage,
  getAllPredefinedMessages,
  getAllCategory,
} from "../../redux/actions";
import { bindActionCreators } from "redux";
import { connect, useDispatch } from "react-redux";
import "../DisasterManagement/styles/DisasterManagement.css";

const AddMessages = ({
  openDialog,
  onClose,
  isMessageAPIFetching,
  createMessage,
  messageError,
  addPredefinedMessage,
  categoryList,
  getAllCategory,
}) => {
  const [message, setMessage] = useState("");
  const [listCategory, setListCategory] = useState([]);
  const [category, setCategory] = useState("");
  const [messageFedbackError, setMessageFeedbackError] = useState("");
  const [messageCategoryError, setMessageCategoryError] = useState("");
  const userDetails = localStorage.getItem("userInfo")
    ? JSON.parse(localStorage.getItem("userInfo"))
    : null;
  const dispatch = useDispatch();

  useEffect(() => {
    getAllCategory();
  }, []);

  useEffect(() => {
    if (categoryList && categoryList.status === 200) {
      setListCategory(categoryList.data);
    }
  }, [categoryList]);

  useEffect(() => {
    if (createMessage && createMessage.status === 200) {
      onClose(true);
      setMessageFeedbackError("");
      setMessage("");
      dispatch({
        type: "RESET_API_DATA",
        payload: null,
      });
    }
  }, [createMessage]);

  const onSave = () => {
    if (!category || category?.trim().length === 0) {
      setMessageCategoryError("This is required");
    }else{
      setMessageCategoryError("");
    }
    if (!message || message?.trim().length === 0) {
      setMessageFeedbackError("This is required");
    }else{
      setMessageFeedbackError("");
    }

    if(category && message){
      const payload = {
        accountId: userDetails?.accountId,
        message: message,
        categoryCode: category,
        createdBy: userDetails?.id,
      };
      addPredefinedMessage(payload);
    }
   
  };
  const cancelOperation = () => {
    onClose();
    setMessageFeedbackError("");
    setMessageCategoryError("");
    setMessage("");
    setCategory("")
  };

  const onChangeInput = (e) => {
    setCategory(e.target.value);
  };
  return (
    <Dialog
      open={openDialog}
      onClose={cancelOperation}
      className="change-pwd-dialog dialog-box predefined-box"
      aria-labelledby="change-pwd-dialog-title"
      aria-describedby="change-pwd-dialog-description"
    >
      <DialogTitle id="change-pwd-dialog-title">
        <Typography className="primary-font-bold-heading2">
          Predefined Message
        </Typography>
        {openDialog ? (
          <img
            src={CloseIcon}
            alt="close-icon"
            style={{ cursor: "pointer",width:"21px" }}
            onClick={cancelOperation}
            sx={{
              marginRight: "-30px",
            }}
          />
        ) : null}
      </DialogTitle>
      <Divider />
      <DialogContent>
      <Typography className="font-normal-small error-message">
          {messageCategoryError}
        </Typography>
        <Typography className="font-normal-small profile-input-label">
          {"Category"}
          <span style={{ color: "red" }}>{" *"}</span>
        </Typography>
       
        <FormControl
          style={{
            marginRight: 0,
            marginLeft: 0,
            marginTop: 0,
            marginBottom: 20,
            width: "100%",
          }}
        >
          <Select
            labelId="demo-simple-select-label"
            label="Relation"
            // className="relation-select"
            className={`relation-select ${
              messageCategoryError !== "" ? "err-input" : ""
            }`}
            value={(value) => {
              return <Box sx={{ display: "flex", gap: 1 }}>{category}</Box>;
            }}
            onChange={(e) => {
              onChangeInput(e, "categry");
            }}
            renderValue={(value) => {
              return (
                <Box sx={{ display: "flex", gap: 1 }}>
                  {categoryList[category]}
                </Box>
              );
            }}
          >
            {Object.keys(categoryList).map((item) => {
              return <MenuItem value={item}>{categoryList[item]}</MenuItem>;
            })}
          </Select>
        </FormControl>
        <Typography className="font-normal-small error-message">
          {messageFedbackError}
        </Typography>
        <Typography className="font-normal-small profile-input-label">
          Message
          <span style={{ color: "red" }}>{" *"}</span>
        </Typography>
        <textarea
        style={{height: "150px" ,fontFamily:"Roboto,Helvetica,Arial,sans-serif"}}
          className={`profile-input ${
            messageFedbackError !== "" ? "err-input" : ""
          }`}
          aria-label="first name"
          value={message}
          onChange={(e) => {
            setMessage(e.target.value);
          }}
        />
      </DialogContent>
      <Divider />
      <DialogActions>
        <div
          className="action-holder cancel-save-btn"
          style={{ marginTop: 20, marginBottom: 0 }}
        >
          <Button
            variant="outlined"
            className="primary-font-bold-6 btn-width profile-cancel-btn"
            disabled={isMessageAPIFetching}
            onClick={cancelOperation}
          >
            Cancel
          </Button>
          <Button
            variant="outlined"
            className="primary-font-bold-6 btn-width profile-save-btn"
            disabled={isMessageAPIFetching}
            onClick={onSave}
          >
            Save
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};

function mapStateToProps(state) {
  return {
    isMessageAPIFetching: state.setting.isMessageAPIFetching,
    createMessage: state.setting.createMessage,
    messageError: state.setting.messageError,
    categoryList: state.setting.categoryList,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      addPredefinedMessage,
      getAllCategory,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(AddMessages);
