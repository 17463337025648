import React, { useState, useEffect, useRef, useContext } from "react";
import "../../../../../../index.css";
import {
    Grid, Typography, Button, Accordion, AccordionSummary, AccordionDetails,
    IconButton, CardActions, CardContent, Dialog, DialogContent
} from "@mui/material";

import PlusIcon from '../../../../../../assets/icons/plus-icon.svg';
import MinusIcon from '../../../../../../assets/icons/minus-icon.svg';
import usePlacesAutocomplete, {
    getGeocode,
    getLatLng,
} from "use-places-autocomplete";
import { List } from "antd";
import * as formatters from '../../../../../../utils/util';
import { CloudUpload } from '@mui/icons-material';
import {UserInfoDetails} from "../../../../../../utils/userContext";
import defaultImage from '../../../../../../assets/images/my-info/default-Image.png';
import excelImage from '../../../../../../assets/images/my-info/ms-excel.svg';
import pdfImage from '../../../../../../assets/images/my-info/pdf-image.png';
import { LoadingButton } from "@mui/lab";
import { updateProperty, uploadPropertyFile,uploadTileFile } from "../../../redux/actions";
import { connect, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { useNavigate } from "react-router-dom";



const TitleCompany = ({
    onBackOrCancelClick,
    handleNext,
    updateProperty,
    propertyInventoryData,
    propertyUpdateResponse,
    uploadPropertyFile,
    uploadTileFile,
    propertyPictureData,
    uploadCompanyImage
}) => {
    const initialFormData = {
        "companyName": "",
        "companyPhone": "",
        "companyWebAddress": "",
        "companyPolicy": "",
        "agentName": "",
        "agentPhone": "",
        "agentEmail": "",
        "agentPolicy": "",
    };
    const [formData, setFormData] = useState(initialFormData);
    const [errors, setErrors] = useState(initialFormData);
    const [expanded, setExpanded] = useState(true)
    const [expanded2, setExpanded2] = useState(true);
    const [streetAddress, setStreetAddress] = useState("");
    const [companyCity, setCompanyCity] = useState("");
    const [companyState, setCompanyState] = useState("");
    const [companyZip, setCompanyZip] = useState("");
    const [agentAddress, setAgentAddress] = useState("");
    const [agentCity, setAgentCity] = useState("");
    const [agentState, setAgentState] = useState("");
    const [agentZip, setAgentZip] = useState("");
    const [location, setLocation] = useState({ lat: null, lng: null });
    const [isApiLoad, setIsApiLoad] = useState(false)
    const [s3Key, setS3Key] = useState("");
    const [companys3Key, setCompanyS3Key] = useState("");
    const [successDialouge, setSuccessDialuge] = useState(false)
    const [message, setMessage] = useState(false)
    const navigate = useNavigate()
    const hideEdit = window.localStorage.getItem("hideEdit") ? window.localStorage.getItem("hideEdit") : false;


    const {
        setValue: setValuePlacesAutocomplete,
        suggestions: { status, data },
        clearSuggestions
    } = usePlacesAutocomplete();

    const {
        setValue: setValueAgentAutocomplete,
        suggestions: { status: agentStatus, data: agentData },
        clearSuggestions: clearAgentSuggestions
    } = usePlacesAutocomplete();
    
    const fileInputRef = useRef(null);
    const fileInputRef2 = useRef(null);
    const [selectedImage, setSelectedImage] = useState(null);
    const [selectedCompanyImage, setSelectedCompanyImage] = useState(null);
    const [imageSource, setImageSource] = useState("");
    const [imageName, setImagename] = useState("");
    const [imageCompanySource, setImageCompanySource] = useState("");
    const [imageCompanyName, setImageCompanyname] = useState("");
    const { userObject } = useContext(UserInfoDetails)
    const [tileInfo, setTileInfo] = useState(null);
    const [removeImage, setRemoveImage] = useState(false);
    const [removeAgentImage, setRemoveAgentImage] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        if (propertyPictureData && propertyPictureData.status === 200) {
            const s3Image = propertyPictureData.data;
            if (s3Image) {
                setS3Key(s3Image?.s3Key)
            }

        }
    }, [propertyPictureData])

    useEffect(() => {
        if (uploadCompanyImage && uploadCompanyImage.status === 200) {
            const s3UploadImage = uploadCompanyImage.data;
            if (s3UploadImage) {
                setCompanyS3Key(s3UploadImage?.s3Key)
            }
        }
    }, [uploadCompanyImage])

    

    useEffect(() => {
        if (propertyInventoryData && propertyInventoryData.propertyTitle) {
            const { propertyTitle } = propertyInventoryData;
            console.log(propertyTitle,"propertyTitle");

            const { agentAddress, agentCity, agentEmail, agentName, agentPhone, previewImage,previewCompanyImage, agentState, agentZip,
                companyAddress, companyCity, companyName, companyPhone, companyPolicy, companyState, companyWebAddress, companyZip,agentPolicy_PreSignedUrl,companyUploadPolicy_PreSignedUrl,agentPolicyName,companyUploadPolicyName } = propertyTitle || {};
            setFormData({
                companyName, companyPhone, companyPolicy, companyWebAddress,
                agentEmail, agentName, agentPhone
            });
            setStreetAddress(companyAddress);
            setCompanyCity(companyCity);
            setCompanyZip(companyZip);
            setCompanyState(companyState);
            setAgentAddress(agentAddress);
            setAgentCity(agentCity);
            setAgentState(agentState);
            setAgentZip(agentZip);
            if (agentPolicy_PreSignedUrl) {
                const imageFile = propertyTitle?.agentPolicy;
                const fileExtension = imageFile.split('.').pop().toLowerCase();
                if (fileExtension === 'pdf') {
                    setImageSource(pdfImage)
                } else if (fileExtension === 'xlsx') {
                    setImageSource(excelImage)
                } else if (fileExtension === 'jpg' || fileExtension === 'jpeg' || fileExtension === 'png') {
                    setImageSource(propertyTitle?.agentPolicy_PreSignedUrl)
                } else {
                    setImageSource(defaultImage)
                }
                setImagename(propertyTitle?.agentPolicyName)
            } else if (previewImage) {
                setImageSource(URL.createObjectURL(previewImage));
            }

            if (companyUploadPolicy_PreSignedUrl) {
                const imageFile = propertyTitle?.companyUploadPolicy;
                const fileExtension = imageFile.split('.').pop().toLowerCase();
                if (fileExtension === 'pdf') {
                    setImageCompanySource(pdfImage)
                } else if (fileExtension === 'xlsx') {
                    setImageCompanySource(excelImage)
                } else if (fileExtension === 'jpg' || fileExtension === 'jpeg' || fileExtension === 'png') {
                    setImageCompanySource(propertyTitle?.companyUploadPolicy_PreSignedUrl)
                } else {
                    setImageCompanySource(defaultImage)
                }
                setImageCompanyname(propertyTitle?.companyUploadPolicyName)
            } else if (previewCompanyImage) {
                setImageCompanySource(URL.createObjectURL(previewCompanyImage));
            }
        }
    }, [propertyInventoryData]);

    useEffect(() => {
        if (propertyUpdateResponse && propertyUpdateResponse.status === 200) {
            if(tileInfo){
                dispatch({
                    type: "ADD_PROPERTY_INVENTORY",
                    payload: tileInfo
                })
                dispatch({
                    type: "PROPERTY_UPDATE_SUCCESS",
                    payload: {}
                })
                setIsApiLoad(false)
                if( propertyInventoryData?.propertyTitle?.id){
                    setMessage("Property Updated Successfully")
    
                }else{
                    setMessage("Property Added Successfully")
    
                }
                setSuccessDialuge(true)
                setTimeout(() => {
                    setSuccessDialuge(false);
                    setMessage("");
                    window.location.href = "/my-property-inventory"
                }, 2000);
            }
           
        }
    }, [propertyUpdateResponse, dispatch, tileInfo, userObject.accountId, userObject.id])
    


    const handleFileUpload = () => {
        fileInputRef.current.click();
    };
    const handleFileUpload2 = () => {
        fileInputRef2.current.click();
    };
    const handleFile = (file, from) => {
        if (file) {
            let agentPolicyUpload = new FormData();
            agentPolicyUpload.set("userId", userObject.id);
            agentPolicyUpload.set("file", file);
            agentPolicyUpload.set("accountId", userObject.accountId);
            agentPolicyUpload.set('fileTag', from)
            if (from === 'Agent Policy') {
                setImagename(file.name)
                if (file.type.includes("image/")) {
                    const Imageurl = URL.createObjectURL(file);
                    setImageSource(Imageurl);
                } else if (file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
                    setImageSource(excelImage);
                } else if (file.type === "application/pdf") {
                    setImageSource(pdfImage);
                } else {
                    setImageSource(defaultImage);
                }
                uploadPropertyFile(agentPolicyUpload);

            } else {
                setImageCompanyname(file.name)
                if (file.type.includes("image/")) {
                    const Imageurl = URL.createObjectURL(file);
                    setImageCompanySource(Imageurl);
                } else if (file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
                    setImageCompanySource(excelImage);
                } else if (file.type === "application/pdf") {
                    setImageCompanySource(pdfImage);
                } else {
                    setImageCompanySource(defaultImage);
                }
                uploadTileFile(agentPolicyUpload)

            }
        }
    }
    const handleImageRemove = () => {
        setImageSource("");
        setImagename("");
        setRemoveAgentImage(true)

    }
    const handleRemove = () => {
        setRemoveImage(true)
        setImageCompanySource("");
        setImageCompanyname("");
    }

    const handleSelect = async (item) => {
        setCompanyCity("");
        setCompanyState("");
        setStreetAddress("");
        setValuePlacesAutocomplete({ value: item.description });
        if (item && item.terms && item.terms.length > 3) {
            const length = item.terms.length;
            setCompanyState(item.terms[length - 2]?.value);
            setCompanyCity(item.terms[length - 3]?.value)
            let street = '';
            for (let i = 0; i < length - 3; i++) {
                street = street + (i !== 0 ? ', ' : "") + item.terms[i]?.value;
            }
            setStreetAddress(street)
        }
        else if (item?.terms && item.terms.length === 3) {
            const length = item.terms.length;
            setCompanyCity(item.terms[length - 2]?.value)
            let street = '';
            for (let i = 0; i < length - 2; i++) {
                street = street + (i !== 0 ? ', ' : "") + item.terms[i]?.value;
            }
            setStreetAddress(street)
        }
        else if (item?.terms && item.terms.length === 2) {
            const length = item.terms.length;
            let street = '';
            for (let i = 0; i < length - 1; i++) {
                street = street + (i !== 0 ? ', ' : "") + item.terms[i]?.value;
            }
            setStreetAddress(street)
        }
        else if (item?.terms && item.terms.length === 1) {
            setStreetAddress(item.terms[0]?.value)
        } else {
            setStreetAddress(item.description)
        }
        clearSuggestions();
        const result = await getGeocode({ placeId: item.place_id })
        const { lat, lng } = await getLatLng(result[0])
        setLocation({ lat: lat, lng: lng })
    };
    const handleAgentSelect = async (item) => {
        setAgentCity("");
        setAgentState("");
        setAgentAddress("");
        setValueAgentAutocomplete({ value: item.description });
        if (item && item.terms && item.terms.length > 3) {
            const length = item.terms.length;
            setAgentState(item.terms[length - 2]?.value);
            setAgentCity(item.terms[length - 3]?.value)
            let street = '';
            for (let i = 0; i < length - 3; i++) {
                street = street + (i !== 0 ? ', ' : "") + item.terms[i]?.value;
            }
            setAgentAddress(street)
        }
        else if (item?.terms && item.terms.length === 3) {
            const length = item.terms.length;
            setAgentCity(item.terms[length - 2]?.value)
            let street = '';
            for (let i = 0; i < length - 2; i++) {
                street = street + (i !== 0 ? ', ' : "") + item.terms[i]?.value;
            }
            setAgentAddress(street)
        }
        else if (item?.terms && item.terms.length === 2) {
            const length = item.terms.length;
            let street = '';
            for (let i = 0; i < length - 1; i++) {
                street = street + (i !== 0 ? ', ' : "") + item.terms[i]?.value;
            }
            setAgentAddress(street)
        }
        else if (item?.terms && item.terms.length === 1) {
            setAgentAddress(item.terms[0]?.value)
        } else {
            setAgentAddress(item.description)
        }
        clearAgentSuggestions();
        const result = await getGeocode({ placeId: item.place_id })
        const { lat, lng } = await getLatLng(result[0])
        setLocation({ lat: lat, lng: lng })
    };
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        const newErrors = {};
        if (Object.keys(newErrors).length === 0) {
            let companyDetails = {
                "propertyTitle": {
                    ...formData,
                    "companyAddress": streetAddress,
                    "companyCity": companyCity,
                    "companyZip": companyZip,
                    "companyState": companyState,
                    "agentAddress": agentAddress,
                    "agentCity": agentCity,
                    "agentZip": agentZip,
                    "agentState": agentState,
                    "agentPolicy": removeAgentImage ? "" : s3Key ? s3Key : propertyInventoryData?.propertyTitle?.agentPolicy,
                    "companyUploadPolicy": removeImage ? "" :companys3Key ? companys3Key : propertyInventoryData?.propertyTitle?.companyUploadPolicy,
                    "companyUploadPolicyName":imageCompanyName,
                    "agentPolicyName":imageName,
                    "accountId": userObject.accountId,
                    "updatedBy": userObject.id,
                    "propertyId": propertyInventoryData?.propertyId,
                    "id":propertyInventoryData ? propertyInventoryData?.propertyTitle?.id : ""
                }
            };

            let companyInfoObject = {
                ...companyDetails,
                "propertyTitle": {
                    ...companyDetails.propertyTitle,
                    "previewImage": selectedImage,
                    "previewCompanyImage": selectedCompanyImage
                }
            };

            const tileDetails = { ...propertyInventoryData, ...companyInfoObject }

            setTileInfo(tileDetails)
            updateProperty(companyDetails)
            setIsApiLoad(true)

        } else {
            setErrors(newErrors);
            console.log('Validation errors:', newErrors);
        }
    };
    return (

        <Grid container spacing={2} style={{ margin: 0, paddingTop: 0, width: '100%', justifyContent: 'start' }}>
            <Grid
                item
                xs={12}
                md={12}
                // style={{ padding: "30px" }}
                className="accordion-holder property-form-padding"
            >
                <div className={(expanded || expanded2) ? 'h500' : ''}>
                    <Accordion defaultExpanded>
                        <AccordionSummary
                            expandIcon={expanded ? <img src={MinusIcon} alt="Minus Icon"/> : <img src={PlusIcon} alt="PlusIcon"/>}
                            onClick={(event) => {
                                event.stopPropagation();
                                event.preventDefault();
                                setExpanded(!expanded);
                            }}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography className="primary-font-bold-6">Company Info</Typography>
                        </AccordionSummary>
                        <AccordionDetails className="pl-0 property-accordian-padding">
                            <Grid container spacing={6} columns={16} style={{ margin: 0, paddingTop: 0, width: '100%', justifyContent: 'start' }}>
                                <Grid item xs={12} sm={8} md={8} className='item-padding' style={{ padding: "10px 20px" }}>
                                    <div className="flex-boxes" style={{ display: 'flex' }}>
                                        <div className="full-width">
                                            <Typography className='font-normal-small profile-input-label'>Company name   </Typography>
                                            <input name="companyName" disabled={hideEdit} id="companyName" className={`profile-input`} aria-label='companyName' onChange={handleInputChange} value={formData.companyName} />
                                        </div>
                                    </div>

                                    <div className="flex-boxes" style={{ display: 'flex' }}>
                                        <div className="full-width">
                                            <Typography className='font-normal-small profile-input-label'>Company Address   </Typography>
                                            <input type="text" disabled={hideEdit} className={`profile-input`} name="companyAddress" id="companyAddress" aria-label='companyAddress' value={streetAddress} onChange={(e) => { setValuePlacesAutocomplete(e.target.value); setStreetAddress(e.target.value) }} />
                                            {status === 'OK' && (
                                                <List
                                                    dataSource={data}
                                                    style={{ overflowY: "auto", maxHeight: "200px", }}
                                                    bordered={data && data.length > 0 ? true : false}
                                                    renderItem={(item) => (
                                                        <List.Item
                                                            onClick={() => { handleSelect(item) }}
                                                        >
                                                            <List.Item.Meta title={item.description} />
                                                        </List.Item>
                                                    )}
                                                />
                                            )
                                            }
                                        </div>
                                    </div>

                                    <div className="flex-boxes" style={{ marginLeft: 0 }}>
                                        <div className="full-width">
                                            <Typography className='font-normal-small profile-input-label'>Zip   </Typography>
                                            <input name="companyZip"disabled={hideEdit}  id="companyZip" className={`profile-input`} aria-label='companyZip' value={formatters.formatNumber(companyZip)} onChange={(e) => { setCompanyZip(e.target.value) }} />
                                        </div>
                                        <div className="full-width">
                                            <Typography className='font-normal-small profile-input-label'>City</Typography>
                                            <input name="companyCity" disabled={hideEdit} id="companyCity" className={`profile-input`} aria-label='companyCity' value={companyCity} onChange={(e) => { setCompanyCity(e.target.value) }} />
                                        </div>
                                        <div className="full-width">
                                            <Typography className='font-normal-small profile-input-label'>State</Typography>
                                            <input name="companyState" disabled={hideEdit} id="companyState" className={`profile-input`} aria-label='companyState' value={companyState} onChange={(e) => { setCompanyState(e.target.value) }} />
                                        </div>
                                    </div>

                                </Grid>

                                <Grid item xs={12} sm={8} md={8} className='item-padding' style={{ padding: "10px 20px" }}>
                                    <div className="flex-boxes" style={{ display: 'flex' }}>
                                        <div className="full-width">
                                            <Typography className='font-normal-small profile-input-label'>Company Phone   </Typography>
                                            <input name="companyPhone" disabled={hideEdit} maxLength={10} id="companyPhone" className={`profile-input`} aria-label='companyPhone' onChange={handleInputChange} value={formatters.formatPhoneNumber(formData.companyPhone)} />
                                        </div>
                                    </div>

                                    <div className="flex-boxes" style={{ display: 'flex' }}>
                                        <div className="full-width">
                                            <Typography className='font-normal-small profile-input-label'>Web Address   </Typography>
                                            <input name="companyWebAddress" disabled={hideEdit} id="companyWebAddress" className={`profile-input`} aria-label='companyWebAddress' onChange={handleInputChange} value={formData.companyWebAddress} />
                                        </div>
                                    </div>

                                    <div className="flex-boxes" style={{ display: 'flex' }}>
                                        <div className="full-width">
                                            <Typography className='font-normal-small profile-input-label'>Value of Policy </Typography>
                                            <input name="companyPolicy" disabled={hideEdit} id="companyPolicy" className={`profile-input`} aria-label='companyPolicy' onChange={handleInputChange} value={formData.companyPolicy} />
                                        </div>
                                    </div>

                                    <div className="flex-boxes" style={{display:'flex'}}>
                                        <div className="full-width">
                                            <Typography className="font-normal-small profile-input-label">Upload Title Insurance Policy</Typography>
                                            <div style={{ position: 'relative', display: 'inline-block' }} className="upload-policy-div" >
                                                <input
                                                    type="file"
                                                    disabled={hideEdit} 
                                                    accept="image/*, .pdf, .ppt, .pptx, .doc, .docx, .xls, .xlsx, application/vnd.openxmlformats-officedocument.presentationml.presentation, application/msword"
                                                    ref={fileInputRef2}
                                                    style={{ display: 'none' }}
                                                    onChange={(e) => { setSelectedCompanyImage(e.target.files[0]); handleFile(e.target.files[0],"Company Policy")}}
                                                />
                                                <div
                                                    className="profile-input upload-policy-width"
                                                    style={{
                                                        // width: '492px',
                                                        fontSize: "13.3333px",
                                                        cursor: "pointer", marginBottom: '20px',
                                                        backgroundColor: hideEdit ? "rgb(221 221 221 / 22%)" : "#fff"
                                                    }}
                                                    onClick={handleFileUpload2}
                                                >
                                                    {imageCompanyName}
                                                    <IconButton
                                                        style={{
                                                            position: 'absolute', right: '1px', background: "#eee", borderRadius: 0, padding: "8px 9px 11px 9px",
                                                            top: "1px",
                                                            borderTopRightRadius: "8px",
                                                            borderBottomRightRadius: "8px",
                                                        }}
                                                        aria-label="upload file"
                                                        disabled={hideEdit} 
                                                        onClick={(event) => {
                                                            event.stopPropagation();
                                                            handleFileUpload2();
                                                        }}
                                                    >
                                                        <CloudUpload />
                                                    </IconButton>
                                                </div>
                                            </div>
                                            {imageCompanySource && (<div style={{ width: " 180px", position: "relative", borderRadius: "5px", marginBottom: "23px" }}>
                                                <CardContent sx={{ padding: 0 }}>
                                                    <img
                                                        src={imageCompanySource}
                                                        alt="Upload Policy"
                                                        style={{
                                                            width: "88%",
                                                            height: "140px",
                                                            objectFit: "cover",
                                                            borderRadius: "5px",
                                                            cursor: "pointer"
                                                        }}
                                                    />
                                                </CardContent>
                                                <CardActions sx={{ padding: 0 }}>
                                                   {!hideEdit && ( <div className="action-button-dlt" disabled={hideEdit} style={{ position: "absolute", top: "6px", right: "23px" }} onClick={(event) => {
                                                        event.stopPropagation();
                                                        handleRemove();
                                                    }}></div>
                                                    )}
                                                </CardActions>
                                            </div>

                                            )}
                                        </div>
                                    </div>

                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion defaultExpanded>
                        <AccordionSummary
                            expandIcon={expanded2 ? <img src={MinusIcon} alt="Minus Icon" /> : <img src={PlusIcon} alt="PlusIcon" />}
                            onClick={(event) => {
                                event.stopPropagation();
                                event.preventDefault();
                                setExpanded2(!expanded2);
                            }}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography className="primary-font-bold-6">Agent Info</Typography>
                        </AccordionSummary>
                        <AccordionDetails className="pl-0 property-accordian-padding">
                            <Grid container spacing={6} columns={16} style={{ margin: 0, paddingTop: 0, width: '100%', justifyContent: 'start' }}>
                                <Grid item xs={12} sm={8} md={8} className='item-padding' style={{ padding: "10px 20px" }}>
                                    <div className="flex-boxes" style={{ display: 'flex' }}>
                                        <div className="full-width">
                                            <Typography className='font-normal-small profile-input-label'>Agent name   </Typography>
                                            <input name="agentName"disabled={hideEdit}  id="agentName" className={`profile-input`} aria-label='agentName' onChange={handleInputChange} value={formData.agentName} />
                                        </div>
                                    </div>

                                    <div className="flex-boxes" style={{ display: 'flex' }}>
                                        <div className="full-width">
                                            <Typography className='font-normal-small profile-input-label'>Agent Address   </Typography>
                                            <input name="agentAddress" disabled={hideEdit} id="agentAddress" className={`profile-input`} aria-label='agentAddress' value={agentAddress} onChange={(e) => { setValueAgentAutocomplete(e.target.value); setAgentAddress(e.target.value) }} />
                                            {agentStatus === 'OK' && (
                                                <List
                                                    dataSource={agentData}
                                                    style={{ overflowY: "auto", maxHeight: "200px" }}
                                                    bordered={agentData && agentData.length > 0 ? true : false}
                                                    renderItem={(item) => (
                                                        <List.Item onClick={() => { handleAgentSelect(item) }}>
                                                            <List.Item.Meta title={item.description} />
                                                        </List.Item>
                                                    )}
                                                />
                                            )}

                                        </div>
                                    </div>

                                    <div className="flex-boxes" style={{ marginLeft: 0 }}>
                                        <div className="full-width">
                                            <Typography className='font-normal-small profile-input-label'>Zip   </Typography>
                                            <input name="agentZip" disabled={hideEdit} id="agentZip" className={`profile-input`} aria-label='agentZip' value={formatters.formatNumber(agentZip)} onChange={(e) => { setAgentZip(e.target.value) }} />
                                        </div>
                                        <div className="full-width">
                                            <Typography className='font-normal-small profile-input-label'>City</Typography>
                                            <input name="agentCity" disabled={hideEdit}  id="agentCity" className={`profile-input`} aria-label='agentCity' value={agentCity} onChange={(e) => { setAgentCity(e.target.value) }} />
                                        </div>
                                        <div className="full-width">
                                            <Typography className='font-normal-small profile-input-label'>State</Typography>
                                            <input name="agentState" disabled={hideEdit} id="agentState" className={`profile-input`} aria-label='agentState' value={agentState} onChange={(e) => { setAgentState(e.target.value) }} />
                                        </div>
                                    </div>

                                </Grid>

                                <Grid item xs={12} sm={8} md={8} className='item-padding' style={{ padding: "10px 20px" }}>
                                    <div className="flex-boxes" style={{ display: 'flex' }}>
                                        <div className="full-width">
                                            <Typography className='font-normal-small profile-input-label'>Phone   </Typography>
                                            <input name="agentPhone" disabled={hideEdit}  maxLength={10} id="agentPhone" className={`profile-input`} aria-label='agentPhone' onChange={handleInputChange} value={formatters.formatPhoneNumber(formData.agentPhone)} />
                                        </div>
                                    </div>

                                    <div className="flex-boxes" style={{ display: 'flex' }}>
                                        <div className="full-width">
                                            <Typography className='font-normal-small profile-input-label'> Agent Email Address</Typography>
                                            <input name="agentEmail" disabled={hideEdit} id="agentEmail" className={`profile-input`} aria-label='agentEmail' onChange={handleInputChange} value={formData.agentEmail} />
                                        </div>
                                    </div>

                                    <div className="flex-boxes" style={{ display: 'flex' }}>
                                        <div className="full-width">
                                            <Typography className='font-normal-small profile-input-label'>Upload Policy </Typography>
                                            <div style={{ position: 'relative', display: 'inline-block' }} className="upload-policy-div">
                                                <input
                                                    type="file"
                                                    disabled={hideEdit} 
                                                    accept="image/*, .pdf, .ppt, .pptx, .doc, .docx, .xls, .xlsx, application/vnd.openxmlformats-officedocument.presentationml.presentation, application/msword"
                                                    ref={fileInputRef}
                                                    style={{ display: 'none' }}
                                                    onChange={(e) => { setSelectedImage(e.target.files[0]);handleFile(e.target.files[0],'Agent Policy'); }}
                                                />
                                                <div
                                                    className="profile-input upload-policy-width"
                                                    style={{
                                                        // width: '492px',
                                                        fontSize: "13.3333px",
                                                        cursor: "pointer", marginBottom: '20px',
                                                        backgroundColor: hideEdit ? "rgb(221 221 221 / 22%)" : "#fff"
                                                    }}
                                                    onClick={handleFileUpload}
                                                >
                                                    {imageName}
                                                    <IconButton
                                                        style={{
                                                            position: 'absolute', right: '1px', background: "#eee", borderRadius: 0, padding: "8px 9px 11px 9px",
                                                            top: "1px",
                                                            borderTopRightRadius: "8px",
                                                            borderBottomRightRadius: "8px",
                                                        }}
                                                        aria-label="upload file"
                                                        disabled={hideEdit} 
                                                        onClick={(event) => {
                                                            event.stopPropagation();
                                                            handleFileUpload();
                                                        }}
                                                    >
                                                        <CloudUpload />
                                                    </IconButton>
                                                </div>
                                            </div>
                                            {imageSource && (<div style={{ width: " 180px", position: "relative", borderRadius: "5px", marginBottom: "23px" }}>
                                                <CardContent sx={{ padding: 0 }}>
                                                    <img
                                                        src={imageSource}
                                                        alt="Upload Policy"
                                                        style={{
                                                            width: "88%",
                                                            height: "140px",
                                                            objectFit: "cover",
                                                            borderRadius: "5px",
                                                            cursor: "pointer"
                                                        }}
                                                    />
                                                </CardContent>
                                                <CardActions sx={{ padding: 0 }}>
                                                   {!hideEdit && ( <div className="action-button-dlt" disabled={hideEdit} style={{ position: "absolute", top: "6px", right: "23px" }} onClick={(event) => {
                                                        event.stopPropagation();
                                                        handleImageRemove();
                                                    }}></div>
                                                    )}
                                                </CardActions>
                                            </div>

                                            )}
                                        </div>
                                    </div>

                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                </div>
            </Grid>
            <div className="action-holder" style={{ marginTop: 0, marginBottom: 50 }}>
                <Button variant="outlined" className="primary-font-bold-6 action-btn btn-height profile-cancel-btn" onClick={onBackOrCancelClick}>Back</Button>
                {
                    !isApiLoad && !hideEdit && <Button variant="outlined" className="primary-font-bold-6 action-btn btn-height profile-save-btn" onClick={handleSubmit}>Save & Continue</Button>
                }
                 {
                    hideEdit && <Button variant="outlined" className="primary-font-bold-6 action-btn btn-height profile-save-btn" onClick={()=>{
                        window.localStorage.removeItem('selectedProperty');
                        window.localStorage.removeItem('hideEdit');
                        navigate("/my-property-inventory");
                    }}>Close</Button>
                }
                {isApiLoad && <LoadingButton
                    size="small"
                    className="primary-font-bold-6 action-btn btn-height profile-save-btn"
                    loading={true}
                    loadingPosition="start"
                    variant="outlined"
                    disabled
                    style={{ opacity: '0.5' }}
                >
                    <span>Saving</span>
                </LoadingButton>}
            </div>
            {message && (
                <Dialog
                    open={successDialouge}
                    onClose={() => {
                        setSuccessDialuge(false);
                    }}
                    className="small-dialog dialog-box"
                    aria-labelledby="dialog-title"
                    aria-describedby="dialog-description"
                >
                    <DialogContent className="content-dialog">
                        <Typography className="primary-font-normal-4">
                            {message}
                        </Typography>
                    </DialogContent>
                </Dialog>
            )}
        </Grid>
    );
};

function mapStateToProps(state) {
    return {
        propertyInventoryData: state.propertyInventory.propertyInventoryData,
        propertyPictureData: state.propertyInventory.propertyPictureData,
        uploadCompanyImage: state.propertyInventory.uploadCompanyImage,
        propertyUpdateResponse: state.propertyInventory.propertyUpdateResponse
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            updateProperty,
            uploadPropertyFile,
            uploadTileFile

        },
        dispatch
    );
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TitleCompany);
