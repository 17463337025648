import React, { useState, useEffect,useRef,useContext  } from "react";
import { Grid, Typography, Button } from "@mui/material";
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import UploadImage from '../../../../../../assets/icons/upload-icon.svg';
import usePlacesAutocomplete from "use-places-autocomplete";
import { List } from "antd";
import * as formatters from '../../../../../../utils/util';
import { connect,useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { uploadPropertyFile,updateProperty,getSinglePropertyList } from "../../../redux/actions";
import {UserInfoDetails} from "../../../../../../utils/userContext";
import { LoadingButton } from "@mui/lab";
import { useParams } from "react-router-dom";





const OwnersInfo = ({ 
  onBackOrCancelClick, 
  handleNext,
  propertyInventoryData,
  uploadPropertyFile,
  propertyPictureData,
  updateProperty,
  propertyUpdateResponse,
  getSinglePropertyList,
  propertyListData
}) => {
  const initialFormData = {
    owner: '',
    itemName: '',
    address: '',
    zip: '',
    city: '',
    state: ''
  };
  const [formData, setFormData] = useState(initialFormData);
  const [errors, setErrors] = useState(initialFormData);
  const [selectedImage, setSelectedImage] = useState(null);
  const [previewUrl, setPreviewUrl] = useState("");
  const { setValue, suggestions: { status, data }, clearSuggestions } = usePlacesAutocomplete();
  const [streetAddress, setStreetAddress] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zip, setZip] = useState("");
  const [s3Key, setS3Key] = useState(null);
  const [ownerInfo, setOwnerInfo] = useState(null);
  const fileInputRef = useRef(null);
  const {userObject} = useContext(UserInfoDetails)
  const [isApiLoad,setIsApiLoad] = useState(false)
  const {propertyId} = useParams();
  const[propResData, setPropResData] = useState("")
  const[imageFile, setImageFile] = useState("")
  const hideEdit = window.localStorage.getItem("hideEdit") ? window.localStorage.getItem("hideEdit") : false;




  const handleClick = () => {
    fileInputRef.current.click();
  };

  const triggerFileInput = (e) => {
    e.preventDefault();
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const dispatch = useDispatch();

  const userDetails = localStorage.getItem("userInfo")
  ? JSON.parse(localStorage.getItem("userInfo"))
  : null;



  const handleFile = file => {
    //you can carry out any file validations here...
    if (file) {
      setPreviewUrl(URL.createObjectURL(file));
      let ownerInfoImage = new FormData();
      ownerInfoImage.set("userId", userDetails.id);
      ownerInfoImage.set("file", file);
      ownerInfoImage.set("accountId", userDetails?.accountId);
      ownerInfoImage.set('fileTag',  'ProfilePicture')
      setImageFile(ownerInfoImage)

    }
   
  }
  const handleOnDrop = event => {
    event.preventDefault();
    event.stopPropagation();
    //let's grab the image file
    let imageFile = event.dataTransfer.files[0];
    handleFile(imageFile);
  }

  const handleDragOver = event => {
    event.preventDefault();
  }


  useEffect(() => {
    getSinglePropertyList(propertyId)
  },[propertyId])

 
  useEffect(() => {
    if (propertyListData && propertyListData.status === 200) {
      dispatch({
        type: "ADD_PROPERTY_INVENTORY",
        payload: propertyListData.data
      })
      setPropResData(propertyListData?.data)
      if (propertyListData && Object.keys(propertyListData).length > 0) {
        const { propertyOwnerInfo } = propertyListData.data;
        const { owner, itemName, address, city, zip, state, image_PreSignedUrl } = propertyOwnerInfo || {};
        setFormData({ owner, itemName });
        setStreetAddress(address);
        setCity(city);
        setZip(zip);
        setState(state);
        if (image_PreSignedUrl) {
          setPreviewUrl(image_PreSignedUrl);
        }
      }
    } else if (propertyListData && propertyListData.status === 404) {
      // setPropertyData([])
    }
  }, [propertyListData])
  
  useEffect(() => {
    if (selectedImage) {
      setPreviewUrl(URL.createObjectURL(selectedImage));
    }
  }, [selectedImage]);

  useEffect(() => {
    console.log("propertyInventoryData",propertyInventoryData)
    if (propertyInventoryData && propertyInventoryData.propertyOwnerInfo) {
      const { propertyOwnerInfo } = propertyInventoryData;
      const { owner, itemName, address, city, zip, state,previewImage } = propertyOwnerInfo || {};
      setFormData({ owner, itemName });
      setStreetAddress(address);
      setCity(city);
      setZip(zip);
      setState(state);
      setPropResData(propertyInventoryData)
      if(previewImage){
        setPreviewUrl(URL.createObjectURL(previewImage));
      }
      
    }
  }, [propertyInventoryData,propertyInventoryData?.propertyOwnerInfo]);

  
  useEffect(()=>{
    if(propertyPictureData && propertyPictureData.status === 200){
        const s3Image = propertyPictureData.data;
        if(s3Image){
          setS3Key(s3Image?.s3Key)
        }
        
    }
  },[propertyPictureData])

  useEffect(() => {
    if (propertyUpdateResponse && propertyUpdateResponse.status === 200) {
      const propertyId = propertyUpdateResponse?.data?.propertyId;
      getSinglePropertyList(propertyId);
      let ownerInfoPayload = {
        ...ownerInfo,
        "propertyId": propertyId || propertyId,
        "accountId": userObject.accountId,
        "updatedBy": userObject.id
      }
      if(ownerInfo){
        if(propResData){
          const updatedPropertyOwnerInfo = {
            ...propResData?.propertyOwnerInfo,
            ...ownerInfo?.propertyOwnerInfo
          };
    
          const updatedPropertyDetails = {
            ...propResData,
            propertyOwnerInfo: updatedPropertyOwnerInfo
          };
          dispatch({
            type: "ADD_PROPERTY_INVENTORY",
            payload: updatedPropertyDetails
          })
        }else{
          dispatch({
            type: "ADD_PROPERTY_INVENTORY",
            payload: ownerInfoPayload
          })
        }
       
        
        dispatch({
          type: "PROPERTY_UPDATE_SUCCESS",
          payload: {}
        })
        setIsApiLoad(false)
        handleNext(); 
      }
      
    }
  }, [propertyUpdateResponse, dispatch, handleNext, ownerInfo, userObject.accountId, userObject.id])

  const handleCancelClick = () => {
    dispatch({
      type: "ADD_PROPERTY_INVENTORY",
      payload: {}
    })
    onBackOrCancelClick();

  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };


  const handleSelect = async (item) => { 
    setCity("");
    setState("");
    setStreetAddress("");
    setValue(item.description, false);
    if (item && item.terms && item.terms.length > 3) {
      const length = item.terms.length;
      setState(item.terms[length - 2]?.value);
      setCity(item.terms[length - 3]?.value)
      let street = '';
      for (let i = 0; i < length - 3; i++) {
        street = street + (i !== 0 ? ', ' : "") + item.terms[i]?.value;
      }
      setStreetAddress(street)
    }
    else if (item?.terms && item.terms.length === 3) {
      const length = item.terms.length;
      setCity(item.terms[length - 2]?.value)
      let street = '';
      for (let i = 0; i < length - 2; i++) {
        street = street + (i !== 0 ? ', ' : "") + item.terms[i]?.value;
      }
      setStreetAddress(street)
    }
    else if (item?.terms && item.terms.length === 2) {
      const length = item.terms.length;
      let street = '';
      for (let i = 0; i < length - 1; i++) {
        street = street + (i !== 0 ? ', ' : "") + item.terms[i]?.value;
      }
      setStreetAddress(street)
    }
    else if (item?.terms && item.terms.length === 1) {
      setStreetAddress(item.terms[0]?.value)
    } else {
      setStreetAddress(item.description)
    }
    clearSuggestions();
  };

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   handleNext()
  // }

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newErrors = {};
    if (formData.owner.trim() === '') {
      newErrors.owner = 'This is required.';
    }
    if (formData.itemName.trim() === '') {
      newErrors.itemName = 'This is required.';
    }

    if (Object.keys(newErrors).length === 0) {
      setIsApiLoad(true)
      let imageKey = ""
      try {
        if (imageFile) {
          const imageResponse = await uploadPropertyFile(imageFile); // api 1
          const handleResponse = imageResponse?.payload
          if (handleResponse && handleResponse.status === 200) {
            const s3ImageData = handleResponse.data;
            if (s3ImageData) {
              imageKey = s3ImageData?.s3Key
              dispatch({
                type: "PROPERTY_IMAGE_UPDATE_SUCCESS",
                payload: {}
              })
            }
  
          }
        }
       
        let ownerInfoDetails = {
          "propertyOwnerInfo": {
            ...formData,
            "address": streetAddress,
            "city": city,
            "zip": zip,
            "state": state,
            "image": imageKey ? imageKey : propResData?.propertyOwnerInfo?.image,
            "accountId": userObject.accountId,
            "updatedBy": userObject.id,
            "propertyId": Number(propertyId) || '',
            "id":propertyInventoryData?.propertyOwnerInfo ? propertyInventoryData?.propertyOwnerInfo?.id : ""
          }
        };
        let ownerInfoObject = {
          ...ownerInfoDetails,
          "propertyOwnerInfo": {
              ...ownerInfoDetails.propertyOwnerInfo,
              "previewImage":selectedImage
          }
      };
  
        setOwnerInfo(ownerInfoObject)
        await updateProperty(ownerInfoDetails)
  
      } catch (error) {
      setIsApiLoad(false)
        console.error('An error occurred:', error);
      }  
    } else {
      setErrors(newErrors);
      console.log('Validation errors:', newErrors);
    }
  };
  return (
    <Grid container style={{ margin: 0, width: '100%' }}>
      <Grid item xs={12} md={6} sx={{ padding: 0 }}>
        <div className="property-form-padding" >
          <div>
            <Typography className='font-normal-small error-message'>{errors.owner}</Typography>
            <Typography className='font-normal-small profile-input-label'>Owner of Property (Required)</Typography>
            <input type="text" disabled={hideEdit} name="owner" id="owner" className={`profile-input ${errors.owner ? 'err-input' : ''}`} aria-label='owner' onChange={handleInputChange} value={formData.owner} />
          </div>
          <div>
            <Typography className='font-normal-small error-message'>{errors.itemName}</Typography>
            <Typography className='font-normal-small profile-input-label'>Home Name/Item Name (Required)</Typography>
            <input type="text" disabled={hideEdit}  name="itemName" id="itemName" className={`profile-input ${errors.itemName ? 'err-input' : ''}`} aria-label='itemName' onChange={handleInputChange} value={formData.itemName} />
          </div>
          <div>

            <Typography className='font-normal-small profile-input-label'>Address of Property</Typography>
            <input type="text" disabled={hideEdit}  className={`profile-input`} name="address" id="address" aria-label='address' value={streetAddress} onChange={(e) => { setValue(e.target.value); setStreetAddress(e.target.value) }} />
            {status === 'OK' && (
              <List
                dataSource={data}
                style={{ overflowY: "auto", maxHeight: "200px", }}
                bordered={data && data.length > 0 ? true : false}
                renderItem={(item) => (
                  <List.Item
                    onClick={() => { handleSelect(item) }}
                  >
                    <List.Item.Meta title={item.description} />
                  </List.Item>
                )}
              />
            )
            }
          </div>
          <div className="flex-boxes" style={{ marginLeft: 0 }}>
            <div className="full-width">
              <Typography className='font-normal-small profile-input-label'>Zip   </Typography>
              <input name="zip" disabled={hideEdit}  id="zip" maxLength={10} className={`profile-input`} aria-label='zip' value={formatters.formatNumber(zip)} onChange={(e) => { setZip(e.target.value) }} />
            </div>
            <div className="full-width">
              <Typography className='font-normal-small profile-input-label'>City</Typography>
              <input name="city" disabled={hideEdit}  id="city" className={`profile-input`} aria-label='city' value={city} onChange={(e) => { setCity(e.target.value) }} />
            </div>
            <div className="full-width">
              <Typography className='font-normal-small profile-input-label'>State</Typography>
              <input name="state" disabled={hideEdit}  id="state" className={`profile-input`} aria-label='state' value={state} onChange={(e) => { setState(e.target.value) }} />
            </div>
          </div>
        </div>
      </Grid>
      <Grid item xs={12} md={6} sx={{ padding: 0 }}>
        <div className="property-form-padding" style={{  position: "relative" }}>
          {previewUrl && !hideEdit &&  (<>
            <div style={{ position: "absolute", top: "33px", right: "84px" }} className="action-button-dlt" onClick={() => setPreviewUrl("")}></div>
            <div style={{ position: "absolute", top: "33px", right: "34px" }} className="action-button-edit" onClick={handleClick}></div>
          </>
          )}
          <input
            accept="image/*"
            type="file"
            id="select-image"
            style={{ display: "none" }}
            ref={fileInputRef}
            disabled={hideEdit} 
            onChange={(e) => { setSelectedImage(e.target.files[0]); handleFile(e.target.files[0]); }}
          />
          {/* <label htmlFor="select-image" > */}
            <div className="photo-upload-container drop_zone" style={{ height: 272, marginBottom: 30 }} onDragOver={handleDragOver} onDrop={handleOnDrop} onClick={triggerFileInput} >
            {previewUrl ? <div className="image">
                <img src={previewUrl} alt='' className="property-image-preview" />
              </div> : <div> <img src={UploadImage} alt={'upload-avatar'} style={{ cursor: 'pointer' }}  />
            <Typography className='primary-font-normal-3 profile-input-label' color='#ABAFB3'>Drag and drop a file here <br />or click to <span style={{ color: '#4979FF' }}>upload</span></Typography> </div> 
                }
            </div>
          {/* </label> */}
       
        </div>
      </Grid>
      <div className="action-holder" style={{ marginTop: 10, marginBottom: 50 }}>
        <Button variant="outlined" className="primary-font-bold-6 action-btn btn-height  profile-cancel-btn" onClick={handleCancelClick}>Cancel</Button>
        {
          !isApiLoad && !hideEdit && <Button variant="outlined" className="primary-font-bold-6 action-btn btn-height profile-save-btn" onClick={handleSubmit}>Save & Continue</Button>
        }
          {
          hideEdit && <Button variant="outlined" className="primary-font-bold-6 action-btn btn-height profile-save-btn" onClick={()=>{handleNext();}}>Next</Button>
        }
        {isApiLoad && <LoadingButton
          size="small"
          className="primary-font-bold-6 action-btn btn-height profile-save-btn"
          loading={true}
          loadingPosition="start"
          variant="outlined"
          disabled
          style={{ opacity: '0.5' }}
        >
          <span>Saving</span>
        </LoadingButton>}
        
      </div>
    </Grid>
  )
}

OwnersInfo.defaultProps = {
  isFetching: null,
  propertyInventoryData: null,
  propertyPictureData: null,
};

function mapStateToProps(state) {
  return {
    propertyInventoryData: state.propertyInventory.propertyInventoryData,
    propertyPictureData: state.propertyInventory.propertyPictureData,
    propertyUpdateResponse: state.propertyInventory.propertyUpdateResponse,
    propertyListData: state.propertyInventory.propertyListData
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      uploadPropertyFile,
      updateProperty,
      getSinglePropertyList
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OwnersInfo);

