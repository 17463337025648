import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import MenuItem from "@mui/material/MenuItem";
import { Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useState,useEffect,useRef } from "react";
import { Navigate, useLocation } from 'react-router-dom';
import {
  Drawer,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Collapse,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { styled, useTheme } from "@mui/material/styles";
import constants from "../../../../../src/config/constants";

import MYREDFOLDER from "../../../../assets/images/MyRedFolderLogo.png";
import PlansIcon from "../../../../assets/icons/my-plans-black-icon.svg";
import PlansIconRed from "../../../../assets/icons/my-plans-red-icon.svg";
import PlansIconWhite from "../../../../assets/icons/my-plans-white-icon.svg";
import InfoIcon from "../../../../assets/icons/info-black-icon.svg";
import InfoIconWhite from "../../../../assets/icons/info-icon-white.svg";
import InfoIconRed from "../../../../assets/icons/info-red.svg";
import FileIcon from "../../../../assets/icons/file-black-icon.svg";
import FileIconRed from "../../../../assets/icons/file-red-icon.svg";
import FileIconWhite from "../../../../assets/icons/file-white-icon.svg";
import SettingIcon from "../../../../assets/icons/setting-black-icon.svg";
import SettingIconRed from "../../../../assets/icons/setting-red-icon.svg";
import SettingIconWhite from "../../../../assets/icons/setting-white-icon.svg";
import close from "../../../../assets/icons/close-white-icon.svg";
import "../../../../containers/App/App.css";
import "./Header.css";
import AlertMessage from "../../../../containers/Pages/UserProfile/components/AlertMessage";

const TopBar = () => {
  const [active, setActive] = React.useState(
    // localStorage.getItem("menuTitle")
    //   ? localStorage.getItem("menuTitle")
    //   : "My Info"
  );
  const [showDialog,setShowDialog] = useState(false)

  const navigate = useNavigate();
  const { t } = useTranslation("common");
  const userObject = window.localStorage.getItem("userInfo")
    ? JSON.parse(window.localStorage.getItem("userInfo"))
    : null;
  const pages = [
    {
      menuTitle: "MY Info",
      icon: InfoIcon,
      hoverIcon: InfoIconRed,
      pageURL: "/home",
      isPublic: false,
      isdropDown: false,
      className: "alignment",
      disabled: false,
      display: true,
    },
    {
      menuTitle: "MY Plans",
      icon: PlansIcon,
      hoverIcon: PlansIconRed,
      pageURL: "#",
      isPublic: false,
      isdropDown: false,
      className: "alignment",
      disabled: true,
      display: true,
    },
    {
      menuTitle: "MY Files",
      icon: FileIcon,
      hoverIcon: FileIconRed,
      pageURL: "#",
      isPublic: false,
      isdropDown: false,
      className: "",
      disabled: true,
      display: true,
    },
    {
      menuTitle: "Settings",
      icon: SettingIcon,
      hoverIcon: SettingIconRed,
      pageURL: "/settings",
      isPublic: false,
      isdropDown: false,
      className: "",
      disabled: false,
      display: false,
    },
  ];
  const sidenavItem = [
    {
      menuTitle: "MY Info",
      icon: InfoIconWhite,
      hoverIcon: InfoIconRed,
      pageURL: "/home",
      disabled: false,
      display: true,
      isEnable:true,
    },
    {
      menuTitle: "MY Plans",
      icon: PlansIconWhite,
      hoverIcon: PlansIconRed,
      pageURL: "#",
      disabled: true,
      display: true,
      isEnable:false,
     
    },
    {
      menuTitle: "MY Files",
      icon: FileIconWhite,
      hoverIcon: FileIconRed,
      pageURL: "#",
      disabled: true,
      display: true,
      isEnable:false,
    },
    {
      menuTitle: "Settings",
      icon: SettingIconWhite,
      hoverIcon: SettingIconRed,
      pageURL: "/settings",
      disabled: false,
      display: false,
      isEnable:false,
      
    },
  ];
  
  const location = useLocation();
  const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    marginLeft: "16px",
  }));

  // const [open, setOpen] = React.useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
   
  };
  const handlecloseDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
    // setOpen(false);
  };
  
  useEffect(() => {
    const initialResizeEvent = new Event('resize');
    window.dispatchEvent(initialResizeEvent);
  }, [location.pathname]);
  
  useEffect(() => {

    const pathname = location.pathname;
    if (pathname === '/home') setActive('MY Info');
    // else if (pathname ==='/') setActive("MY Plans");
    // else if (pathname ==='/') setActive("MY Files");
    else if (pathname ==='/settings') setActive("Settings");
    // else if  (pathname === '/')  setActive('MY Info')
  }, [location.pathname]);
 
  const handleMenuClick = (menu) => {
    if(menu.disabled){
      setShowDialog(true);
      setTimeout(() => {
        setShowDialog(false);
      }, 2000);
    }else{
      navigate(menu.pageURL);
    }
    // setActive(menu.menuTitle);
    // localStorage.setItem("menuTitle", menu.menuTitle);
  };
  
   

  return (
    <AppBar position="static" style={{ background: "white", padding: "0px" }}>
  
        <Toolbar disableGutters className="bigsize">
        <Box className=" header-navbar-container">
            <Grid
             
              container
              // xs={12}
              // sm={12}
              // md={12}
              // lg={12}
              sx={{ display:"flex",justifyContent: "space-between" }}
            >
              <Grid className="img-size">
                <img
                  src={MYREDFOLDER}
                  alt="myredfolderlogo"
                  id="mrflogo"
                  // onClick={() => {
                  //   navigate("/home")
                  //   localStorage.setItem("menuTitle", "Home");
                  // }}
                  style={{  marginTop: "10px" ,objectFit:"fill" }}
                />
              </Grid>
              <Grid 
              // sx={{ mr: "-16px" }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    textAlign: "center",
                    justifyContent: "flex-end",
                    gap: "10px",
                    width: "100%",
                    paddingLeft: 0,
                    marginTop: "3px",
                  }}
                  id="topbar-menu-content"
                >
                  {pages.map((page) => (
                    <>
                      {((userObject && userObject.userRoleCode === "owner") ||
                        page.display ||
                        (userObject &&
                          userObject.userRoleCode === "admin")) && (
                        <div>
                          <MenuItem
                            key={page}
                            
                            onClick={(e) => {
                              handleMenuClick(page);
                            }}
                            // disabled={page.disabled}
                            className={
                              active === page.menuTitle
                                ? "menu-top-bar active-menu"
                                : "menu-top-bar"
                            }
                          >
                            <div className="single-menu ">
                              <div className="single-menu-icon figure">
                                <img
                                  key={page.menuTitle + "_icon"}
                                  alt={page.menuTitle}
                                  className="Sirv image-main"
                                  src={page.icon}
                                />
                                <img
                                  key={page.menuTitle + "_icon1"}
                                  alt={page.menuTitle + "_2"}
                                  className="Sirv image-hover"
                                  src={page.hoverIcon}
                                />
                              </div>
                              <Typography
                            
                                className={`single-menu-text primary-font-medium ${page.className}`}
                               sx={{maxWidth: "127px",whiteSpace:"pre-wrap",wordWrap: 'break-word'}}
                              >
                                {t(`${page.menuTitle}`)}
                              </Typography>
                            </div>
                          </MenuItem>
                        </div>
                      )}
                    </>
                  ))}
                </Box>
              </Grid>
            </Grid>
            </Box>
       
        </Toolbar>
        <Toolbar disableGutters className="smallsize">
            <Grid container  className="header-navbar-container">
            <Grid sx={{ my: "auto" }} item xs={1} sm={1} md={1} lg={1}>
              <Grid
                sx={{
                  ml: "-5px",
                }}
              >
                <IconButton
                  edge="center"
                  color="black"
                  aria-label="menu"
                  onClick={toggleDrawer}

                >
                  <MenuIcon className="menu-icon-size" />
                </IconButton>
              </Grid>
            </Grid>
            <Grid item container xs={11} md={11} lg={11} >
              <Grid className="img-size">
                <img
                  src={MYREDFOLDER}
                  alt="myredfolderlogo"
                  id="mrflogo"
                  // onClick={() => {
                  //   navigate("/home")

                  // }}
                  style={{ marginRight: 10, marginTop: "6px" }}
                />
              </Grid>
            </Grid>
            <Grid>
              <Drawer anchor="left" className="side-nav" open={isDrawerOpen} onClose={toggleDrawer}>
                <DrawerHeader>
                  <img
                    src={close}
                    onClick={handlecloseDrawer}
                    alt="close"
                    height={20}
                    width={20}
                  />
                </DrawerHeader>
                <List sx={{ pb: "0px" }}>
                  {sidenavItem?.map((page, index) => (
                    
                    
                    <>
                      {((userObject && userObject.userRoleCode === "owner") ||
                        page.display ||
                        (userObject &&
                          userObject.userRoleCode === "admin")) && (
                      <ListItem
                        key={page.id}
                        onClick={(e) => {
                          // if(page.isEnable){
                          handleMenuClick(page);
                          // }
                        }}
                      
                        sx={{
                          color: active === page.menuTitle ? "red" : "white", opacity: page.disabled ? "0.38":"1", 
                        }}
                      >
                        {active === page.menuTitle ? (
                          <img
                            key={page.menuTitle + "_icon"}
                            alt={page.menuTitle}
                            src={page.hoverIcon}
                          />
                        ) : (
                          <img
                            key={page.menuTitle + "_icon"}
                            alt={page.menuTitle}
                            src={page.icon}
                          />
                        )}
 
                        <ListItemText
                          className="list-menu"
                          // primary={page.menuTitle}
                          
                          sx={{
                            pl: "15px",
                            color: active === page.menuTitle ? "red" : "white",
                            textTransform:"uppercase"
                          }}
                          
                        >
                           {t(`${page.menuTitle}`)}</ListItemText>
                      </ListItem>
                          )}
                    </>
                  ))}
                </List>
              </Drawer>
            </Grid>
          </Grid>
        </Toolbar>
        {showDialog && <AlertMessage isOpen={true} onClose={false} message={"This function is under construction"} />}

    </AppBar>
  );
};
export default TopBar;