import React, { useContext, useEffect, useState } from "react";
import {
  Grid,
  Radio,
  Typography,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Button,
} from "@mui/material";
import DatePicker from "react-date-picker";
import { UserInfoDetails } from "../../../../../../utils/userContext";
import { updateProperty } from "../../../redux/actions";
import { connect, useDispatch } from "react-redux";
import { LoadingButton } from "@mui/lab";
import { bindActionCreators } from "redux";
import moment from "moment";

const PropertyFinancing = ({
  propertyInventoryData,
  propertyUpdateResponse,
  handleNext,
  onBackOrCancelClick,
  updateProperty
}) => {
  const initialFormData = {
    nameOfFinanceCompany: "",
    loanNo: "",
    address: "",
    loanAmount: "",
    phoneNo: "",
    term: "",
    agent: "",
    payoffDate: "",
    emailAddress: "",
    prepaymentPenalty: "",
    website: "",
  };
  const dispatch = useDispatch();
  const [formData, setFormData] = useState(initialFormData);
  const [errors, setErrors] = useState(initialFormData);
  const [financeInfo, setFinanceInfo] = useState({});
  const [isApiLoad, setIsApiLoad] = useState(false);
  const { userObject } = useContext(UserInfoDetails);
  const [selectedType, setSelectedType] = useState("");

  const [value, setValue] = useState("true");
  const hideEdit = window.localStorage.getItem("hideEdit")
    ? window.localStorage.getItem("hideEdit")
    : false;
  useEffect(() => {
    if (propertyInventoryData?.propertyFinancing) {
      const { propertyFinancing } = propertyInventoryData;
      setFormData(propertyFinancing);
      setValue(propertyFinancing?.prepaymentPenalty ? "true" : "false");
    }
  }, [propertyInventoryData]);

 
  const handleInputChange = (e) => {
     const {name, value} = e.target;
     let updatedFormData = { ...formData };

     if (name === "loanNo" || name === "loanAmount") {
      updatedFormData[name] = Number(value);
     }else if(name === "prepaymentPenalty"){
       let boolTrue = value === "true";
       updatedFormData[name] = boolTrue;
       setValue(value);
     } else {
       updatedFormData[name] = value;
     }

    setFormData(updatedFormData);
  };



  const handleDateChange = (e) => {
    if(e) {
      const date = moment(e).format('MM-DD-YYYY')
      setFormData({
        ...formData,
        payoffDate: date,
      })
    }else{
      setFormData({
        ...formData,
        payoffDate:e,
      })
    }
  }
  
   
  const handleSubmit = (e) => {
    e.preventDefault();
    setIsApiLoad(true);


    const propertyFinanceDetails = {
      "propertyFinancing": {
      ...formData,
      "propertyId": propertyInventoryData?.propertyId,
      "accountId": userObject.accountId,
      "updatedBy": userObject.id,
      "id": propertyInventoryData ? propertyInventoryData?.propertyFinancing?.id : "",
    },
    };
    const propertyDetails = {...propertyInventoryData, ...propertyFinanceDetails}
    setFinanceInfo(propertyDetails);
    updateProperty(propertyFinanceDetails)

 };

  useEffect(() => {
    if (propertyUpdateResponse && propertyUpdateResponse.status === 200) {
      if(Object.keys(financeInfo).length > 0){
        setIsApiLoad(false);
        dispatch({
          type: "ADD_PROPERTY_INVENTORY",
          payload: financeInfo,
        });
        dispatch({
          type: "PROPERTY_UPDATE_SUCCESS",
          payload: {},
        });
        handleNext();
      }
     
    }
  }, [
    propertyUpdateResponse
  ]);

  return (
    <Grid
      container
      spacing={2}
      className="property-form-padding"
      style={{
        margin: 0,
        // padding: 32,
        paddingTop: 0,
        width: "100%",
        justifyContent: "start",
      }}
    >
      <Grid item xs={12} sm={12} md={6} lg={6} className="property-form-padding" >
        <div className="full-width">
          <Typography className="font-normal-small profile-input-label">
            Name of Finance Company/Bank
          </Typography>
          <input
            type="text"
            id="nameOfFinanceCompany"
            name="nameOfFinanceCompany"
            aria-label="bank"
            disabled={hideEdit}
            className={`profile-input`}
            onChange={handleInputChange}
            value={formData.nameOfFinanceCompany}

          />
        </div>
        <div className="full-width">
          <Typography className="font-normal-small profile-input-label">
            Address
          </Typography>
          <input
            type="text"
            name="address"
            id="address"
            aria-label="Address"
            disabled={hideEdit}
            className={`profile-input`}
            onChange={handleInputChange}
            value={formData.address}
          />
        </div>
        <div className="full-width">
          <Typography className="font-normal-small profile-input-label">
            Phone Number
          </Typography>
          <input
            type="text"
            name="phoneNo"
            id="phoneNo"
            aria-label="phoneNumber"
            disabled={hideEdit}
            className={`profile-input`}
            onChange={handleInputChange}
            value={formData.phoneNo}
          />
        </div>
        <div className="full-width">
          <Typography className="font-normal-small profile-input-label">
            Agent
          </Typography>
          <input
            type="text"
            name="agent"
            id= "agent"
            aria-label="Agent"
            disabled={hideEdit}
            className={`profile-input`}
            onChange={handleInputChange}
            value={formData.agent}
          />
        </div>
        <div className="full-width">
          <Typography className="font-normal-small profile-input-label">
            Email Address
          </Typography>
          <input
            type="text"
            name="emailAddress"
            id="emailAddress"
            aria-label="Email"
            disabled={hideEdit}
            className={`profile-input`}
            onChange={handleInputChange}
            value={formData.emailAddress}

          />
        </div>
        <div className="full-width">
          <Typography className="font-normal-small profile-input-label">
            Website
          </Typography>
          <input
            type="text"
            name="website"
            id="website"
            aria-label="website"
            disabled={hideEdit}
            className={`profile-input`}
            onChange={handleInputChange}
            value={formData.website}

          />
        </div>
      </Grid>
      <Grid item xs={12} sm={12} md={6}  lg={6} className="property-form-padding" >
        <div className="full-width">
          <Typography className="font-normal-small profile-input-label">
            Loan Number
          </Typography>
          <input
            type="text"
            name="loanNo"
            id="loanNo"
            aria-label="loanNo"
            disabled={hideEdit}
            className={`profile-input`}
            onChange={handleInputChange}
            value={formData.loanNo}
          />
        </div>
        <div className="full-width">
          <Typography className="font-normal-small profile-input-label">
            Loan Amount
          </Typography>
          <input
            type="text"
            name="loanAmount"
            id="loanAmount"
            aria-label="loanAmount"
            disabled={hideEdit}
            className={`profile-input`}
            onChange={handleInputChange}
            value={formData.loanAmount}

          />
        </div>
        <div className="full-width">
          <Typography className="font-normal-small profile-input-label">
            Term
          </Typography>
          <input
            type="text"
            name="term"
            id="term"
            aria-label="term"
            disabled={hideEdit}
            className={`profile-input`}
            onChange={handleInputChange}
            value={formData.term}
            
          />
        </div>
        <div className="full-width">
          <Typography className="font-normal-small profile-input-label">
            Pay Off Date
          </Typography>
          <DatePicker
            name="payoffDate"
            id="payoffDate"
            minDate={new Date(1900, 0, 1)}
            className={`profile-input`}
            aria-label="payoffDate"
            value={formData.payoffDate}
            onChange={handleDateChange}
            disabled={hideEdit}
          />
        </div>
        <div className="full-width">
          <Typography className="font-normal-small profile-input-label">
            Prepayment Penalty
          </Typography>
          <FormControl style={{ display: "flow" }}>
            <RadioGroup
              value={value}
              onChange={handleInputChange}
              style={{ display: "flow" }}
              name="prepaymentPenalty"
              aria-label="prepaymentPenalty"
            >
              <FormControlLabel
                value={"true"}
                control={<Radio sx={{ "&.Mui-checked": { color: "red" } }} />}
                label="Yes"
                disabled={hideEdit}
              />
              <FormControlLabel
                value={"false"}
                control={<Radio sx={{ "&.Mui-checked": { color: "red" } }} />}
                label="No"
                disabled={hideEdit}
              />
            </RadioGroup>
          </FormControl>
        </div>
      </Grid>

      <div className="action-holder holder-margin" style={{ display: "flex" }}>
        <Button
          variant="outlined"
          className="primary-font-bold-6 action-btn btn-height profile-cancel-btn"
          onClick={onBackOrCancelClick}
        >
          Back
        </Button>
        {!isApiLoad && !hideEdit && (
          <Button
            variant="outlined"
            className="primary-font-bold-6 action-btn btn-height profile-save-btn"
            onClick={handleSubmit}
          >
            save & Continue
          </Button>
        )}
        {hideEdit && (
          <Button
            variant="outlined"
            className="primary-font-bold-6 action-btn btn-height profile-save-btn"
            onClick={() => {
              handleNext();
            }}
          >
            Next
          </Button>
        )}
        {isApiLoad && (
          <LoadingButton
            size="small"
            className="primary-font-bold-6 action-btn btn-height profile-save-btn"
            loading={true}
            loadingPosition="start"
            variant="outlined"
            disabled
            style={{ opacity: "0.5" }}
          >
            <span>Saving</span>
          </LoadingButton>
        )}
      </div>
    </Grid>
  );
};

function mapStateToProps(state) {
  return {
    propertyInventoryData: state.propertyInventory.propertyInventoryData,
    propertyUpdateResponse: state.propertyInventory.propertyUpdateResponse,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateProperty,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(PropertyFinancing);