import React, { useState, useEffect, useRef, useContext } from "react";
import "../../../../../../index.css";
import { Grid, Typography, Button, Select, MenuItem, Popper } from "@mui/material";
import DatePicker from 'react-date-picker';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import { connect, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { updateProperty,getHomeType,getPropertyType } from "../../../redux/actions";
import * as formatters from '../../../../../../utils/util';
import { LoadingButton } from "@mui/lab";
import {UserInfoDetails} from "../../../../../../utils/userContext";
import moment from "moment";
import Info from "../../../../../../assets/icons/info-i-icon.svg";
import Tooltip,{tooltipClasses} from "@mui/material/Tooltip";
import {styled} from "@mui/material/styles";

const LightTooltip = styled(({className, ...props}) => (
  <Tooltip {...props} arrow classes={{popper: className}} />
))(({theme}) => ({
  [`& .${tooltipClasses.arrow}`]:  {
    Color:'#FF8620',
  },
  [`& .${tooltipClasses.tooltip}`]: {
    Color:'white',
    borderRadius:"7px",
    minWidth: 1200,
    padding: "15px",
    fontSize:"15px",
    marginLeft:"50px",
    fontWeight:400,
    [theme.breakpoints.down('sm')]: {
      minWidth: 180, 
      padding: '8px', 
      fontSize: '12px', 
      marginLeft: '0px', 
    },
  },
}));


const PropertyInfo = ({ onBackOrCancelClick, handleNext, propertyInventoryData, updateProperty, propertyUpdateResponse,getHomeType,homeType,propertyType,getPropertyType }) => {
  const dispatch = useDispatch();
  const initialFormData = {
    "homeType": "",
    "quantity": "",
    "purchaseDate": "",
    "color": "",
    "areaOfsqft": '',
    "type": "",
    "unitCost": "",
    "estReplacementCost": "",
    "numberOfBedrooms": "",
    "numberOfBathrooms": "",
    "attachedGarage": "",
    "carGarage": "",
    "subDetails": ""
  };
  const [formData, setFormData] = useState(initialFormData);
  const [errors, setErrors] = useState(initialFormData);
  const [propertyInfo, setPropertyInfo] = useState({})
  const [isApiLoad, setIsApiLoad] = useState(false)
  const {userObject} = useContext(UserInfoDetails)
  const[homeTypeList,setHomeTypeList] = useState([])
  const [selectedType, setSelectedType] = useState('');
  const [typeList, setTypeList] = useState(initialFormData);

  const hideEdit = window.localStorage.getItem("hideEdit") ? window.localStorage.getItem("hideEdit") : false;


  useEffect(() => {
    getHomeType()
    getPropertyType()
  },[])

  useEffect(() => {
    if( homeType && homeType.status === 200){
      const loadType = homeType?.data;
      setHomeTypeList(loadType)
    }
  },[homeType])

  useEffect(() => {
    if(  propertyType && propertyType.status === 200){
      const loadProperty = propertyType?.data;
      setTypeList(loadProperty)
    } 
  },[propertyType])

//   useEffect(()=>{
//     console.log(typeList, 'list');
//     console.log(formData,'formData')
//   }
// )



  useEffect(() => {
    if (propertyInventoryData?.propertyInfo) {
      const { propertyInfo } = propertyInventoryData;
      setFormData(propertyInfo);
      setTypeList(typeList)
     
    }
  }, [propertyInventoryData]);

  const handleInputTypeChange = (event) => {
    //setSelectedType(event.target.value);
    setFormData({
      ...formData,
      type : event.target.value
    });
  };

  const handleDateChange = (e) => {
    if(e){
      const date =  moment(e).format('MM-DD-YYYY') 
      setFormData({
        ...formData,
        ['purchaseDate']: date,
      });
    }else{
      setFormData({
        ...formData,
        ['purchaseDate']: e,
      });
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    let updatedFormData = { ...formData };
    if (name === "unitCost" || name === "estReplacementCost") {
      const regex = /^[0-9]{0,13}(?:\.[0-9]{0,2})?$/;
      if (regex.test(value) || value === '') { 
        updatedFormData[name] = value;
      }
    } else {
      updatedFormData[name] = value;
    }


    setFormData(updatedFormData);
  };
  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   handleNext()
  // }
  const handleSubmit = (e) => {
    e.preventDefault();
    const newErrors = {};

    if (typeof formData.quantity === 'string' && formData.quantity.trim() === '') {
      newErrors.quantity = 'This is required.';
    }
    if (typeof formData.unitCost === 'string' && formData.unitCost.trim() === '') {
      newErrors.unitCost = 'This is required.';
    }
    

    if (Object.keys(newErrors).length === 0) {
      const propertyInfoDetails = {
        "propertyInfo": {
          ...formData,
          "propertyId": propertyInventoryData?.propertyId,
          "accountId": userObject.accountId,
          "updatedBy": userObject.id,
          //"type":selectedType,
          "type":formData.type,
          "id":propertyInventoryData ? propertyInventoryData?.propertyInfo?.id : ""
        }
      };
      const propertyDetails = { ...propertyInventoryData, ...propertyInfoDetails }
      setPropertyInfo(propertyDetails)
      setIsApiLoad(true)
      updateProperty(propertyInfoDetails)
    } else {
      setErrors(newErrors);
    }
  };

  useEffect(() => {
    if (propertyUpdateResponse && propertyUpdateResponse.status === 200) {
      if(Object.keys(propertyInfo).length > 0){
        setIsApiLoad(false)
        dispatch({
          type: "ADD_PROPERTY_INVENTORY",
          payload: propertyInfo
        })
        dispatch({
          type: "PROPERTY_UPDATE_SUCCESS",
          payload: {}
        })
        handleNext();
      }
   
    }
  }, [propertyUpdateResponse])

  return (
    <Grid container spacing={6} columns={16}  style={{ margin: 0, paddingTop: 0, width: '100%', justifyContent: 'start' }}>
      <Grid item xs={12} sm={8} md={8} className="item-padding">
        <div className="flex-boxes" style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div className="home-width" style={{ alignContent:"center"}}>
            <Typography className='font-normal-small profile-input-label'>Home Type 
                <LightTooltip enterTouchDelay={0} leaveTouchDelay={5000} arrow style={{marginLeft: "5px"}} placement="bottom" title={
                <>
                <Typography>
                1. single-Family Residence - A standalone structure typically occupied by one household or family, offering privacy and space.
                </Typography>
                <br/>
                <Typography>
                2. Condominium (Condo) - A private residence within a larger building or complex where common areas are jointly owned and maintained by the condo association.
                </Typography>
                <br/>
                <Typography>
                3. Townhouse - A multi-floor home that shares one or more walls with other similar units but has its own entrance and often includes multiple levels.
                </Typography>
                <br/>
                <Typography>
                4. Multi-Family Home - A single building set up to accommodate more than one family living separately;
                these can range from duplexes (two units) to buildings with several units.
                </Typography>
                <br/>
                <Typography>
                5. Cooperative (Co-op) - A type of living arrangement where residents do not own their specific unitoutright but instead own a share in the entire building, which entitles them to the use of a specific unit.
                </Typography>
                <br/>
                <Typography>
                6. Mobile Home - A prefabricated home that is manufactured off-site and moved to a place for use, typically located within a mobile home park.
                </Typography>
                <br/>
                <Typography>
                7. Modular Home - A home constructed in sections at a factory and then transported to a site where it is assembled on a permanent foundation.
                </Typography>
                <br/>
                <Typography>
                8. Manufactured Home - Similar to a mobile home but generally larger and designed to be placed on a permanent foundation, though still prefabricated.
                </Typography>
                <br/>
                <Typography>
                9. Villa - A type of house that is often luxurious, located in a suburban or rural setting, and can be detached or semi-detached. 
                </Typography>
                <br/>
                <Typography>
                10. Bungalow - A single-story house, often with a small footprint, focusing on simple living spaces and often featuring a large front porch.  
                </Typography>
                <br/>
                <Typography>
                11. Cottage - A small, cozy house typically found in rural or semi-rural locations, often characterized by picturesque style and an emphasis on comfort.  
                </Typography>
                <br/>
                <Typography>
                12. Farmhouse - Located in rural areas, these homes are typically surrounded by a farm or a large garden, often reflecting a rustic style. 
                </Typography>
                <br/>
                <Typography>
                13. Loft - An open concept living space usually located in converted industrial buildings, known for high ceilings and large windows. 
                </Typography>
                <br/>
                <Typography>
                14. Penthouse - An apartment on the top floor of a high-rise building, typically luxurious and featuring expansive views. 
                </Typography>
                </>
                }>
                  <img src={Info} alt=""   style={{marginLeft:"10px",height:"15px", width:"15px"}}/>
                 </LightTooltip>
            </Typography>
            <Select id="homeType" name="homeType" className="profile-input state-input font-normal-small" disabled={hideEdit} value={formData.homeType} onChange={(e) => { handleInputChange(e, "homeType") }}>
              {Object.keys(homeTypeList)
                .sort((a, b) => a.localeCompare(b))
                .map((key) => (
                  <MenuItem key={key} value={key}>
                    {homeTypeList[key]}
                  </MenuItem>
                ))}
            </Select>
          </div>
          <div className="quality-width" >
            <Typography className='font-normal-small error-message'>{errors.quantity}</Typography>
            <Typography className='font-normal-small profile-input-label'>Quantity (Required)</Typography>
            <input id="quantity"  disabled={hideEdit} name="quantity" className={`profile-input ${errors.quantity ? 'err-input' : ''}`} aria-label='quantity' onChange={handleInputChange} value={formatters.formatNumber(formData.quantity)} />
          </div>

        </div>

        <div className="flex-boxes" style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div className="purchase-date-width" >
            <Typography className='font-normal-small profile-input-label'>Date of Purchase   </Typography>
            <DatePicker disabled={hideEdit}  name="purchaseDate" id="purchaseDate" minDate={new Date(1900, 0, 1)} className={`profile-input`} aria-label='purchaseDate' onChange={handleDateChange} value={formData.purchaseDate} />
          </div>
          <div className="color-width" >
            <Typography className='font-normal-small profile-input-label'>Color  </Typography>
            <input name="color" id="color" disabled={hideEdit}  className={`profile-input`} aria-label='color' onChange={handleInputChange} value={formData.color} />
          </div>
          <div className="area-width" >
            <Typography className='font-normal-small profile-input-label'>Area(In Square Feet)  </Typography>
            <input name="areaOfsqft"  disabled={hideEdit} id="areaOfsqft" className={`profile-input`} aria-label='areaOfsqft' onChange={handleInputChange} value={formData.areaOfsqft} />
          </div>
        </div>

        <div className="flex-boxes" style={{ display: 'flex' }}>
          <div className="full-width">
            <Typography className='font-normal-small profile-input-label'> Property Type</Typography>
            <Select id="type" className="profile-input state-input font-normal-small" disabled={hideEdit} name="type" value={formData.type} onChange={(e) => {handleInputChange(e, "type")}}>
             {Object.keys(typeList)
              .sort(() => typeList.id)
              .map((key) => (
                <MenuItem key={key} value={key}>
                  {typeList[key]}
                </MenuItem>
              ))
             }
            </Select>
          </div>
        </div>

        <div className="flex-boxes" style={{ display: 'flex' }}>
          <div className="full-width">
            <Typography className='font-normal-small error-message'>{errors.unitCost}</Typography>
            <Typography className='font-normal-small profile-input-label'>Purchase Price (Required) </Typography>
            <input id="unitCost"  disabled={hideEdit} name="unitCost" className={`profile-input ${errors.unitCost ? 'err-input' : ''}`} aria-label='unitCost' onChange={handleInputChange} value={formData.unitCost} />
          </div>
        </div>
        <div className="flex-boxes" style={{ display: 'flex' }}>
          <div className="full-width">
            <Typography className='font-normal-small profile-input-label'>Estimated Replacement Cost  </Typography>
            <input name="estReplacementCost"  disabled={hideEdit} id="estReplacementCost" className={`profile-input`} aria-label='estReplacementCost' onChange={handleInputChange} value={formData.estReplacementCost} />
          </div>
        </div>
      </Grid>
      <Grid item xs={12} sm={8} md={8} className="item-padding">
        <div className="flex-boxes" style={{ display: 'flex' }}>
          <div className="full-width">
            <Typography className='font-normal-small profile-input-label'>Number of Bedrooms </Typography>
            <input id="numberOfBathrooms"  disabled={hideEdit} name="numberOfBathrooms" className={`profile-input`} aria-label='numberOfBathrooms' onChange={handleInputChange} value={formData.numberOfBathrooms} />
          </div>
        </div>
        <div className="flex-boxes" style={{ display: 'flex' }}>
          <div className="full-width">
            <Typography className='font-normal-small profile-input-label'>Number of Bathrooms </Typography>
            <input id="numberOfBedrooms"  disabled={hideEdit} name="numberOfBedrooms" className={`profile-input`} aria-label='numberOfBedrooms' onChange={handleInputChange} value={formData.numberOfBedrooms} />
          </div>
        </div>

        <div className="flex-boxes" style={{ display: 'flex' }}>
          <div className="full-width">
            <Typography className='font-normal-small profile-input-label'>Attached Garage </Typography>
            <input id="attachedGarage" disabled={hideEdit}  name="attachedGarage" className={`profile-input`} aria-label='attachedGarage' onChange={handleInputChange} value={formData.attachedGarage} />
          </div>
        </div>

        <div className="flex-boxes" style={{ display: 'flex' }}>
          <div className="full-width">
            <Typography className='font-normal-small profile-input-label'>Covered Car Port </Typography>
            <input id="carGarage"  disabled={hideEdit} name="carGarage" className={`profile-input`} aria-label='carGarage' onChange={handleInputChange} value={formData.carGarage} />
          </div>
        </div>

        <div className="flex-boxes" style={{ display: 'flex' }}>
          <div className="full-width">
            <Typography className='font-normal-small profile-input-label'>Add Additional Details</Typography>
            <input id="subDetails" disabled={hideEdit}  name="subDetails" className={`profile-input`} aria-label='subDetails' onChange={handleInputChange} value={formData.subDetails} />
          </div>
        </div>
      </Grid>
      <div className="action-holder">
        <Button variant="outlined" className="primary-font-bold-6 action-btn btn-height profile-cancel-btn" onClick={onBackOrCancelClick}>Back</Button>
        {
          !isApiLoad && !hideEdit && <Button variant="outlined" className="primary-font-bold-6 action-btn btn-height profile-save-btn" onClick={handleSubmit}>Save & Continue</Button>
        }
         {
          hideEdit && <Button variant="outlined" className="primary-font-bold-6 action-btn btn-height profile-save-btn" onClick={()=>{handleNext()}}>Next</Button>
        }
        {isApiLoad && <LoadingButton
          size="small"
          className="primary-font-bold-6 action-btn btn-height profile-save-btn"
          loading={true}
          loadingPosition="start"
          variant="outlined"
          disabled
          style={{ opacity: '0.5' }}
        >
          <span>Saving</span>
        </LoadingButton>}
      </div>
    </Grid>
  );
};


function mapStateToProps(state) {
  return {
    homeType: state.propertyInventory.homeType,
    propertyType: state.propertyInventory.propertyType,
    propertyInventoryData: state.propertyInventory.propertyInventoryData,
    propertyUpdateResponse: state.propertyInventory.propertyUpdateResponse

  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateProperty,
      getHomeType,
      getPropertyType
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PropertyInfo);
