import React, { useState, useEffect } from "react";
import "../../../../../../index.css";
import { Grid, Typography, Button, Accordion, AccordionSummary, AccordionDetails, Select, MenuItem } from "@mui/material";
import { useNavigate } from "react-router-dom";
import * as formatters from '../../../../../../utils/util';
import PlusIcon from '../../../../../../assets/icons/plus-icon.svg';
import MinusIcon from '../../../../../../assets/icons/minus-icon.svg'; 
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DatePicker from 'react-date-picker';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import { connect, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment/moment";
import { addDemographicInformation, getDemographicInformation, getStatesList } from "../../../redux/actions";

const DemographicInfo = ({ onContinue, onCancel, onView, selectedUser, addDemographicInformation, demographicInfo, demographicDetails,getDemographicInformation, getStatesList, stateList }) => {
  const navigate = useNavigate();
  const [expanded, setExpanded] = useState(false)
  const [expanded2, setExpanded2] = useState(false)
  const [expanded3, setExpanded3] = useState(false)
  const [expanded4, setExpanded4] = useState(false)
  const dispatch = useDispatch();
 
  const [address, setAddress] = useState(null);
  const [zip, setZip] = useState(null);
  const [city, setCity] = useState(null);
  const [state, setState] = useState(null);
  const [stateArray, setStateArray] = useState(null);
  const [telephone, setTelephone] = useState(null);
  const [resident, setResident] = useState(null);
  const [nationality, setNationality] = useState(null);
  const [placeOfBirth, setPlaceOfBirth] = useState(null);

  const [elementary, setElementary] = useState(null);
  const [college, setCollege] = useState(null);
  const [highSchool, setHighSchool] = useState(null);

  const [occupation, setOccupation] = useState(null);
  const [income, setIncome] = useState(null);
  const [employedBy, setEmployedBy] = useState(null);
  const [previousOccupation, setPreviousOccupation] = useState(null);

  const [marritalStatus, setMarritalStatus] = useState(null);
  const [spouseName, setSpoueName] = useState(null);
  const [fatherName, setFatherName] = useState(null);
  const [motherName, setMotherName] = useState(null);
  const [motherDob, setMotherDob] = useState(null);
  const [fatherDob, setFatherDob] = useState(null);
  const [fatherPlaceOfBirth, setFatherPlaceOfBirth] = useState(null);
  const [motherPlaceOfBirth, setMotherPlaceOfBirth] = useState(null);
  const [relegiousAffiliation, setRelegiousAffiliation] = useState(null);

  const [firstNameErr, setFirstNameErr] = useState('');
  const [dobErr, setDOBErr] = useState('');
  const [relationErr, setRelationErr] = useState('');
  const [phoneNumberErr, setphoneNumberErr] = useState('');

  useEffect(()=>{
    getStatesList();
    if(selectedUser?.userId){
      getDemographicInformation(selectedUser)
    }
  },[])

useEffect(()=>{
  if(demographicInfo && demographicInfo.status === 200){
    dispatch({
      type:"RESET_DEMOGRAPHIC_INFORMATION",
      action:null
    })
    onContinue()
  }
},[demographicInfo])

useEffect(()=>{
  if(stateList && stateList.status === 200){
    setStateArray(Object.keys(stateList.data))
  }
},[stateList])

useEffect(()=>{
  if(demographicDetails && demographicDetails.status === 200){
    const data = demographicDetails.data;

    //Address
    setAddress(data.address);
    setZip(data.zip);
    setCity(data.city);
    setState(data.stateCode);
    setTelephone(data.telephone);
    setResident(data.residentSince);
    setNationality(data.nationality);
    setPlaceOfBirth(data.placeOfBirth);

    //Education
    setCollege(data.college);
    setElementary(data.elementary);
    setHighSchool(data.highSchool);

    //Employment
    setOccupation(data.occupation);
    setIncome(data.income);
    setEmployedBy(data.employedBy);
    setPreviousOccupation(data.previousOccupation);

    // Miscellaneous
    setMarritalStatus(data.maritalStatus);
    setSpoueName(data.spouseName);
    setFatherName(data.fatherName);
    setMotherName(data.motherName);
    setFatherDob(data?.fatherYearOfBirth?.toString());
    setFatherPlaceOfBirth(data.fatherPlaceOfBirth);
    setMotherDob(data?.motherYearOfBirth?.toString());
    setMotherPlaceOfBirth(data.motherPlaceOfBirth);
    setRelegiousAffiliation(data.religiousAffiliation);

  }
},[demographicDetails])
    const onChangeInput = (e, type) => {
      let value = "";
      if(type !== "motherDob" && type !== "fatherDob"){
        value = e.target.value
      }
      console.log(value);
      switch(type) {
        case 'address':
          setAddress(value)
          break;
        case 'zip':
          setZip(value)
          break;
        case 'city':
          setCity(value)
          break;
        case 'state':
          setState(value)
          break;
        case 'telephone':
          setTelephone(value)
          break;
        case 'resident':
          setResident(value)
          break;
        case 'nationality':
          setNationality(value)
          break;
        case 'placeOfBirth':
          setPlaceOfBirth(value)
          break;
        case 'elementary':
          setElementary(value)
          break;
        case 'college':
          setCollege(value)
          break;
        case 'highSchool':
          setHighSchool(value)
          break;
        case 'occupation':
          setOccupation(value)
          break;
        case 'income':
          setIncome(value)
          break;
        case 'employedBy':
          setEmployedBy(value)
          break;
        case 'previousOccupation':
          setPreviousOccupation(value)
          break;
        case 'marritalStatus':
          setMarritalStatus(value)
        break;
        case 'spouseName':
          setSpoueName(value)
          break;
        case 'fatherName':
          setFatherName(value)
          break;
        case 'motherName':
          setMotherName(value)
          break;
        case 'motherDob':
          let date =  e
          if(e){
            date = moment(e).format('YYYY')
          }
          setMotherDob(date )
          break;
        case 'fatherDob':
        let fatherDob =  e
        if(e){
          fatherDob = moment(e).format('YYYY')
        }
          
          setFatherDob(fatherDob)
          break;
        case 'fatherPlaceOfBirth':
          setFatherPlaceOfBirth(value)
          break;
        case 'motherPlaceOfBirth':
          setMotherPlaceOfBirth(value)
          break;
        case 'relegiousAffiliation':
          setRelegiousAffiliation(value)
          break;
        default:
      }
    }
   
    const onSave = () => {
    const payload = {
      "userId" :selectedUser?.userId,
      "createdBy":selectedUser?.userId,
      "updatedBy":selectedUser?.userId,
      "address":address,
      "nationality":nationality,
      "zip":zip,
      "city":city,
      "stateCode":state,
      "placeOfBirth":placeOfBirth,
      "telephone":telephone,
      "residentSince":resident,
      "elementary":elementary,
      "highSchool":highSchool,
      "college":college,
      "occupation":occupation,
      "income":income ? Number(income): null,
      "employedBy":employedBy,
      "previousOccupation":previousOccupation,
      "maritalStatus":marritalStatus,
      "spouseName":spouseName,
      "fatherName":fatherName,
      "fatherPlaceOfBirth":fatherPlaceOfBirth,
      "fatherYearOfBirth": fatherDob ? Number(fatherDob) : null,
      "motherName": motherName,
      "motherPlaceOfBirth":motherPlaceOfBirth,
      "motherYearOfBirth": motherDob ? Number(motherDob) : null,
      "religiousAffiliation":relegiousAffiliation,
    }
addDemographicInformation(payload)
  }
  
  return (
    <Grid container spacing={2} sx={{margin:0, padding: 0,width:"100%" }}>
      {/* <Grid item xs={2} md={2}></Grid> */}
      <Grid
        item
        xs={12}
        md={12}
        sx={{pl:onView?"0px !important":"16px",pt:onView?"0px !important":0}}
        className="accordion-holder"
      >
        <div className={(expanded || expanded2 || expanded3 || expanded4) ? 'h500' : ''}>
          <Accordion>
              <AccordionSummary
              expandIcon={expanded ? <img src={MinusIcon}/> : <img src={PlusIcon} />}
                onClick={(event) => {
                  event.stopPropagation();
                  event.preventDefault();
                  setExpanded(!expanded);
                }}
              aria-controls="panel1a-content"
              id="panel1a-header"
              >
              <Typography className="primary-font-bold-6">Address</Typography>
              </AccordionSummary>
              <AccordionDetails className="pl-0">
                  <Grid container spacing={2} style={{margin:0, height: 'auto'}}>
                      <Grid item  sx={{pl:onView?"0px !important":"16px",pt:onView?"0px":"16px"}} xs={12} md={6} className="pl-0">
                          <div>
                          <Typography className='font-normal-small error-message'>{phoneNumberErr}</Typography>
                              <Typography className='font-normal-small profile-input-label'>Address</Typography>
                              <input className={`profile-input ${phoneNumberErr !== '' ? 'err-input' : ''}`} aria-label='address' onChange={(e)=> {onChangeInput(e, 'address')}} value={(address)}/>
                          </div>
                          <div className="flex-boxes"> 
                              
                              
                              <div className="full-width">
                              <Typography className='font-normal-small error-message'>{relationErr}</Typography>
                                  <Typography className='font-normal-small profile-input-label'>Zip   </Typography>
                                  <input className={`profile-input ${relationErr !== '' ? 'err-input' : ''}`}  aria-label='zip'  onChange={(e)=> {onChangeInput(e, 'zip')}} value={zip}/>
                              </div>
                              <div className="full-width">
                                  <Typography className='font-normal-small profile-input-label'>City</Typography>
                                  <input className={`profile-input ${relationErr !== '' ? 'err-input' : ''}`}  aria-label='city'  onChange={(e)=> {onChangeInput(e, 'city')}} value={city}/>
                              </div>
                              <div className="full-width">
                                  <Typography className='font-normal-small profile-input-label'>State</Typography>
                                  <Select className="profile-input state-input font-normal-small" value={state}  onChange={(e)=> {onChangeInput(e, 'state')}}>
                                    {stateArray?.map((key) => (
                                      <MenuItem key={key} value={key}>
                                        {key}
                                      </MenuItem>
                                    ))}
                                  </Select>
                              </div>
                          </div>
                          <div> 
                              <Typography className='font-normal-small error-message'>{relationErr}</Typography>
                              <Typography className='font-normal-small profile-input-label'>Telephone</Typography>
                              <input className={`profile-input ${relationErr !== '' ? 'err-input' : ''}`}  aria-label='telephone'  onChange={(e)=> {onChangeInput(e, 'telephone')}} value={telephone}/>
                          </div>
                          
                          
                      </Grid>
                      <Grid item xs={12} md={6} sx={{pl:onView?"0px !important":"30px",pt:onView?"0px !important":"16px"}}>
                          <div> 
                              <Typography className='font-normal-small error-message'>{relationErr}</Typography>
                              <Typography className='font-normal-small profile-input-label'>Resident Since</Typography>
                              <input className={`profile-input ${relationErr !== '' ? 'err-input' : ''}`}  aria-label='city'  onChange={(e)=> {onChangeInput(e, 'resident')}} value={resident}/>
                          </div>
                          <div> 
                              <Typography className='font-normal-small error-message'>{relationErr}</Typography>
                              <Typography className='font-normal-small profile-input-label'>Nationality</Typography>
                              <input className={`profile-input ${relationErr !== '' ? 'err-input' : ''}`}  aria-label='city'  onChange={(e)=> {onChangeInput(e, 'nationality')}} value={nationality}/>
                          </div>
                          <div>
                              <Typography className='font-normal-small error-message'>{firstNameErr}</Typography>
                              <Typography className='font-normal-small profile-input-label'>Place of Birth </Typography>
                              <input className={`profile-input ${firstNameErr !== '' ? 'err-input' : ''}`}  aria-label='first name'  onChange={(e)=> {onChangeInput(e, 'placeOfBirth')}} value={placeOfBirth}/>
                          </div>
                      </Grid>
                  </Grid>
              </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={expanded2 ? <img src={MinusIcon}/> : <img src={PlusIcon}/>}
            onClick={(event) => {
              event.stopPropagation();
              event.preventDefault();
              setExpanded2(!expanded2);
            }}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography className="primary-font-bold-6">Education</Typography>
          </AccordionSummary>
          <AccordionDetails className="pl-0">
              <Grid container spacing={2} style={{margin:0, height: 'auto'}}>
                  <Grid item sx={{pl:onView?"0px !important":"16px",pt:onView?"0px":"16px"}} xs={12} md={6} className="pl-0">
                      <div>
                      <Typography className='font-normal-small error-message'>{phoneNumberErr}</Typography>
                          <Typography className='font-normal-small profile-input-label'>Elementary</Typography>
                          <input className={`profile-input ${phoneNumberErr !== '' ? 'err-input' : ''}`} aria-label='elementary' onChange={(e)=> {onChangeInput(e, 'elementary')}}  value={(elementary)}/>
                      </div>
                      <div> 
                          <Typography className='font-normal-small error-message'>{relationErr}</Typography>
                          <Typography className='font-normal-small profile-input-label'>College</Typography>
                          <input className={`profile-input ${relationErr !== '' ? 'err-input' : ''}`}  aria-label='college'  onChange={(e)=> {onChangeInput(e, 'college')}} value={college}/>
                      </div>
                      
                      
                  </Grid>
                  <Grid item xs={12} md={6} sx={{pl:onView?"0px !important":"30px",pt:onView?"0px":"16px"}}>
                      <div> 
                          <Typography className='font-normal-small error-message'>{relationErr}</Typography>
                          <Typography className='font-normal-small profile-input-label'>High School</Typography>
                          <input className={`profile-input ${relationErr !== '' ? 'err-input' : ''}`}  aria-label='highSchool'  onChange={(e)=> {onChangeInput(e, 'highSchool')}} value={highSchool}/>
                      </div>
                  </Grid>
              </Grid>
          </AccordionDetails>
        </Accordion>
        <Accordion>
        <AccordionSummary
            expandIcon={expanded3 ? <img src={MinusIcon} /> : <img src={PlusIcon} />}
            onClick={(event) => {
              event.stopPropagation();
              event.preventDefault();
              setExpanded3(!expanded3);
            }}
            aria-controls="panel3a-content"
            id="panel3a-header"
          >
            <Typography className="primary-font-bold-6">Employment</Typography>
          </AccordionSummary>
          <AccordionDetails className="pl-0">
              <Grid container spacing={2} style={{margin:0, height: 'auto'}}>
                  <Grid item sx={{pl:onView?"0px !important":"16px",pt:onView?"0px":"16px"}} xs={12} md={6} className="pl-0">
                      <div>
                      <Typography className='font-normal-small error-message'>{phoneNumberErr}</Typography>
                          <Typography className='font-normal-small profile-input-label'>Occupation</Typography>
                          <input className={`profile-input ${phoneNumberErr !== '' ? 'err-input' : ''}`} aria-label='occupation' onChange={(e)=> {onChangeInput(e, 'occupation')}}  value={(occupation)}/>
                      </div>
                      <div> 
                          <Typography className='font-normal-small error-message'>{relationErr}</Typography>
                          <Typography className='font-normal-small profile-input-label'>Employed By</Typography>
                          <input className={`profile-input ${relationErr !== '' ? 'err-input' : ''}`}  aria-label='employedBy'  onChange={(e)=> {onChangeInput(e, 'employedBy')}} value={employedBy}/>
                      </div>
                      
                      
                  </Grid>
                  <Grid item xs={12} md={6} sx={{pl:onView?"0px !important":"30px",pt:onView?"0px":"16px"}}>
                      <div> 
                          <Typography className='font-normal-small error-message'>{relationErr}</Typography>
                          <Typography className='font-normal-small profile-input-label'>Income</Typography>
                          <input className={`profile-input ${relationErr !== '' ? 'err-input' : ''}`}  aria-label='income'  onChange={(e)=> {onChangeInput(e, 'income')}} value={income}/>
                      </div>
                      <div> 
                          <Typography className='font-normal-small error-message'>{relationErr}</Typography>
                          <Typography className='font-normal-small profile-input-label'>Previous Occupation (if retired)</Typography>
                          <input className={`profile-input ${relationErr !== '' ? 'err-input' : ''}`}  aria-label='previousOccupation'  onChange={(e)=> {onChangeInput(e, 'previousOccupation')}} value={previousOccupation}/>
                      </div>
                  </Grid>
              </Grid>
          </AccordionDetails>
        </Accordion>
        <Accordion>
        <AccordionSummary
            expandIcon={expanded4 ? <img src={MinusIcon}/> : <img src={PlusIcon}/>}
            onClick={(event) => {
              event.stopPropagation();
              event.preventDefault();
              setExpanded4(!expanded4);
            }}
            aria-controls="panel4a-content"
            id="panel4a-header"
          >
            <Typography className="primary-font-bold-6">Miscellaneous</Typography>
          </AccordionSummary>
          <AccordionDetails className="pl-0">
              <Grid container spacing={2} style={{margin:0, height: 'auto'}}>
                  <Grid item sx={{pl:onView?"0px !important":"16px",pt:onView?"0px":"16px"}} xs={12} md={6} className="pl-0">
                      <div>
                      <Typography className='font-normal-small error-message'>{phoneNumberErr}</Typography>
                          <Typography className='font-normal-small profile-input-label'>Marital Status</Typography>
                          <input className={`profile-input ${phoneNumberErr !== '' ? 'err-input' : ''}`} aria-label='marritalStatusr' onChange={(e)=> {onChangeInput(e, 'marritalStatus')}} value={(marritalStatus)}/>
                      </div>
                      <div> 
                          <Typography className='font-normal-small error-message'>{relationErr}</Typography>
                          <Typography className='font-normal-small profile-input-label'>Name of the father</Typography>
                          <input className={`profile-input ${relationErr !== '' ? 'err-input' : ''}`}  aria-label='fatherName'  onChange={(e)=> {onChangeInput(e, 'fatherName')}} value={fatherName}/>
                      </div>
                      <div> 
                          <Typography className='font-normal-small error-message'>{relationErr}</Typography>
                          <Typography className='font-normal-small profile-input-label'>Father's Place of Birth</Typography>
                          <input className={`profile-input ${relationErr !== '' ? 'err-input' : ''}`}  aria-label='fatherPlaceOfBirth'  onChange={(e)=> {onChangeInput(e, 'fatherPlaceOfBirth')}} value={fatherPlaceOfBirth}/>
                      </div>
                      <div> 
                          <Typography className='font-normal-small error-message'>{relationErr}</Typography>
                          <Typography className='font-normal-small profile-input-label'>Father's Year of Birth</Typography>
                          <DatePicker format="yyyy" minDate={new Date(1900, 0, 1)}  className={`profile-input ${dobErr !== '' ? 'err-input' : ''}`}  onChange={(e)=> {onChangeInput(e, 'fatherDob')}} value={fatherDob} maxDate={new Date()} />
                      </div>
                      <div> 
                          <Typography className='font-normal-small error-message'>{relationErr}</Typography>
                          <Typography className='font-normal-small profile-input-label'>Relgious Affiliation</Typography>
                          <input className={`profile-input ${relationErr !== '' ? 'err-input' : ''}`}  aria-label='relegiousAffiliation'  onChange={(e)=> {onChangeInput(e, 'relegiousAffiliation')}} value={relegiousAffiliation}/>
                      </div>
                      
                      
                  </Grid>
                  <Grid item xs={12} md={6} sx={{pl:onView?"0px !important":"30px",pt:onView?"0px":"16px"}}>
                      <div> 
                          <Typography className='font-normal-small error-message'>{relationErr}</Typography>
                          <Typography className='font-normal-small profile-input-label'>Spouse's Maiden Name</Typography>
                          <input className={`profile-input ${relationErr !== '' ? 'err-input' : ''}`}  aria-label='spouseName'  onChange={(e)=> {onChangeInput(e, 'spouseName')}} value={spouseName}/>
                      </div>
                      <div> 
                          <Typography className='font-normal-small error-message'>{relationErr}</Typography>
                          <Typography className='font-normal-small profile-input-label'>Mother's Maiden Name</Typography>
                          <input className={`profile-input ${relationErr !== '' ? 'err-input' : ''}`}  aria-label='motherName'  onChange={(e)=> {onChangeInput(e, 'motherName')}} value={motherName}/>
                      </div>
                      <div> 
                          <Typography className='font-normal-small error-message'>{relationErr}</Typography>
                          <Typography className='font-normal-small profile-input-label'>Mother's Place of Birth</Typography>
                          <input className={`profile-input ${relationErr !== '' ? 'err-input' : ''}`}  aria-label='motherPlaceOfBirth'  onChange={(e)=> {onChangeInput(e, 'motherPlaceOfBirth')}} value={motherPlaceOfBirth}/>
                      </div>
                      <div> 
                          <Typography className='font-normal-small error-message'>{relationErr}</Typography>
                          <Typography className='font-normal-small profile-input-label'>Mother's Year of Birth</Typography>
                          <DatePicker  format="yyyy" minDate={new Date(1900, 0, 1)} className={`profile-input ${dobErr !== '' ? 'err-input' : ''}`}  onChange={(e)=> {onChangeInput(e, 'motherDob')}} value={motherDob} maxDate={new Date()} />
                      </div>
                  </Grid>
              </Grid>
          </AccordionDetails>
        </Accordion>
        </div>
      <div style={{marginTop: onView?"30px":"40px",marginBottom:onView?"30px":"40px"}} className="action-holder action-bg">
        <Button variant="outlined" className="primary-font-actionbtn action-btn profile-cancel-btn" onClick={onCancel}>Back</Button>
        <Button variant="outlined" className="primary-font-actionbtn action-btn profile-save-btn" onClick={onSave}>Save & Continue</Button>
    </div>
      </Grid>
    </Grid>
  );
};



DemographicInfo.defaultProps = {
};

function mapStateToProps(state) {
  return {
    demographicInfo: state.familyInfo.demographicInfo,
    demographicDetails: state.familyInfo.demographicDetails,
    stateList: state.familyInfo.stateList
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      addDemographicInformation,
      getDemographicInformation,
      getStatesList
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DemographicInfo);

