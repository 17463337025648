import React from "react";
import Header from "./Layout/Header/Header";
import Footer from "./Layout/Footer/Footer";
import ActivateAlert from "./Layout/ActivateAlert";
const { innerHeight: height } = window;

const ContentWrapper = ({ contenview, title, gobackUrl, goback, image, img}) => {
  return (
    <>
      <Header />
      <div
        style={{
          backgroundColor: "white",
          minHeight: height - 250 + "px",
        }}
      >
        <ActivateAlert title={title} gobackUrl={gobackUrl} goback={goback} image={image} img={img}  />
        {contenview}
        <Footer />
      </div>
    </>
  );
};

export default ContentWrapper;
