import {api} from '../../../../utils/apiClient';
import { Auth } from 'aws-amplify';

export const getProfileInfo = (payload) => async(dispatch) => {
     const userDetails = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')): null;
     const url = `v1/user/profile/read${userDetails?.id  ? `/${userDetails?.id}`: ''}`;
    try{
        dispatch({
            type: 'USERINFO_DATA_REQUEST',
            payload: null
        })
        const response =await api.getApi(url);
        if(response.data && response.data.data){
           const UserData =  window.localStorage.getItem('userInfo') ? JSON.parse( window.localStorage.getItem('userInfo')) :{}
           const newUserData = {...UserData, ...response.data.data};
           window.localStorage.setItem('userInfo', JSON.stringify(newUserData))
        }
        return dispatch({
            type: 'USERINFO_DATA_SUCCESS',
            payload: response.data
        });
    }catch(e){
        return dispatch({
            type: 'USERINFO_DATA_FAILURE',
            payload: e
        });
    }
}

export const updateProfileInfo = (payload) => async(dispatch) => {
    const url = `v1/user/profile/update`;
   try{
       dispatch({
           type: 'USERINFO_UPDATE_REQUEST',
           payload: null
       })
       const response =await api.putApi(url,payload);
       return dispatch({
           type: 'USERINFO_UPDATE_SUCCESS',
           payload: response.data
       });
   }catch(e){
       return dispatch({
           type: 'USERINFO_UPDATE_FAILURE',
           payload: e
       });
   }
}


export const changeUserPassword = (payload) => async(dispatch) => {
    const url = 'v1/user/updateStatusVerified';

   try{
       dispatch({
           type: 'PASSWORD_UPDATE_REQUEST',
           payload: null
       })
       
    const user = await Auth.currentAuthenticatedUser();
    const data = await Auth.changePassword(user, payload.oldPassword, payload.password);
       if(data === 'SUCCESS' && payload.oldPassword === payload.password){
            return dispatch({
                type: 'PASSWORD_UPDATE_FAILURE',
                payload: "New Password and Old Password should not be same."
            });
       }else  if(data === 'SUCCESS'){
        const response = await api.putApi(url,payload);
            return dispatch({
                type: 'PASSWORD_UPDATE_SUCCESS',
                payload: response
            });
   }else{
        return dispatch({
            type: 'PASSWORD_UPDATE_FAILURE',
            payload: data?.message 
        });
       }
  
   }catch(e){
       return dispatch({
           type: 'PASSWORD_UPDATE_FAILURE',
           payload: e
       });
   }
}

export const changeSecurityPin = (payload) => async(dispatch) => {
    const url = `v1/user/securityPin/update`;
   try{
       dispatch({
           type: 'PASSWORD_UPDATE_REQUEST',
           payload: null
       })
       const response =await api.putApi(url,payload);
       return dispatch({
           type: 'PASSWORD_UPDATE_SUCCESS',
           payload: response.data
       });
   }catch(e){
       return dispatch({
           type: 'PASSWORD_UPDATE_FAILURE',
           payload: e
       });
   }
}
export const changeSecurityLock = (payload) => async(dispatch) => {
    const url = `v1/user/securityLock/update`;
   try{
       dispatch({
           type: 'SECURITY_LOCK_UPDATE_REQUEST',
           payload: null
       })
       const response =await api.putApi(url,payload);
       return dispatch({
           type: 'SECURITY_LOCK_UPDATE_SUCCESS',
           payload: response.data
       });
   }catch(e){
       return dispatch({
           type: 'SECURITY_LOCK_UPDATE_FAILURE',
           payload: e
       });
   }
}
export const updateProfilePicture = (payload) => async(dispatch) => {

    const url = `v1/commonUtility/file/upload`;
   try{
       dispatch({
           type: 'PROFILE_PICTURE_UPDATE_REQUEST',
           payload: null
       })
 
       const response =await api.postApi(url,payload);
       return dispatch({
           type: 'PROFILE_PICTURE_UPDATE_SUCCESS',
           payload: response.data
       });
   }catch(e){
       return dispatch({
           type: 'PROFILE_PICTURE_UPDATE_FAILURE',
           payload: e
       });
   }
}

export const getRelationshipList = (payload) => async(dispatch) => {
    const url = `v1/commonUtility/listValues/readAll`;
   try{
       dispatch({
           type: 'RELATIONSHIP_DATA_REQUEST',
           payload: null
       })
       const response =await api.getApi(url);
       return dispatch({
           type: 'RELATIONSHIP_DATA_SUCCESS',
           payload: response?.data?.data['users-relationship'] ? response.data.data['users-relationship'] : []
       });
   }catch(e){
       return dispatch({
           type: 'RELATIONSHIP_DATA_FAILURE',
           payload: e
       });
   }
}
export const verifySecurityLock = (payload) => async(dispatch) => {
    const url = `v1/user/securityLock/verify`;
   try{
       dispatch({
           type: 'SECURITY_LOCK_VERIFY_REQUEST',
           payload: null
       })
       const response =await api.postApi(url,payload);
       return dispatch({
           type: 'SECURITY_LOCK_VERIFY_SUCCESS',
           payload: response.data
       });
   }catch(e){
       return dispatch({
           type: 'SECURITY_LOCK_VERIFY_FAILURE',
           payload: e
       });
   }
}

export const resetVerifyLock =  (payload) => async(dispatch) => {
       dispatch({
           type: 'SECURITY_LOCK_RESET',
           payload: null
       })
       if(payload?.from){
        window.location.reload();
       }
}
export const updateProfileInHeader = (newProfile) => (dispatch) => {
    return dispatch({
      type: 'UPDATE_PROFILE',
      payload: newProfile,
    });
  };
  export const getStatesList = (payload) => async(dispatch) => {
    const url = `v1/commonUtility/state/read`;
    
    try{
        dispatch({
            type: 'STATE_REQUEST',
            payload: null
        })
        const response =await api.getApi(url, payload);
        return dispatch({
            type: 'GET_STATE_SUCCESS',
            payload: response.data
        });
     }catch(e){
        return dispatch({
            type: 'GET_STATE_FAILURE',
            payload: e
        });
    }
 };

  