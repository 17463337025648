import React, { useState, useEffect } from "react";
import { GoogleMap, InfoWindowF, Marker} from "@react-google-maps/api";
import UserAvatar from '../../../../../assets/icons/user-avatar.svg';
import constants from "../../../../../config/constants";

const MeetingLocationMap = ({selectedLocation,defaultCenterlatlng, onSelectLocation,MarkerLocationList,selectedObject, zoom, showCustomMarker,userLocation}) => {
  const [activeMarker, setActiveMarker] = useState(null);
  const [map, setMap] = useState(null);
  const [locationsWithAddress, setLocationsWithAddress] = useState([]);

  const handleActiveMarker = (marker) => {
    setActiveMarker(null);
    if (marker === activeMarker) {
      return;
    }
    if(map && !userLocation){
      map.setZoom(16);
    }
    setActiveMarker(marker);
  };


 useEffect(()=>{
  if(MarkerLocationList){
    const fetchAddresses = async () => {
      try {
        const updatedLocations = await Promise.all(
          MarkerLocationList.map(async (location) => {
            const { locationLat, locationLong } = location;
            const response = await fetch(
              `https://maps.googleapis.com/maps/api/geocode/json?latlng=${locationLat},${locationLong}&key=${constants.GOOGLE_MAPS_API_KEY}`
            );
            const MarkerLocationList = await response.json();
            const formattedAddress =
              MarkerLocationList.results[0]?.formatted_address || '';

            return { ...location, address: formattedAddress };
          })
        );

        setLocationsWithAddress(updatedLocations);
      } catch (error) {
        console.error('Error fetching addresses:', error);
      }
    };

    fetchAddresses();
  }
 },[MarkerLocationList])

  useEffect(() => {
    if (userLocation) {
      setLocationsWithAddress(userLocation);
    }

  }, [userLocation])

  const handleOnLoad = (map) => {
    const bounds = new window.google.maps.LatLngBounds();
    MarkerLocationList?.forEach((marker) =>{
      const position = {lat:parseFloat(marker?.locationLat), lng:parseFloat(marker?.locationLong)}
      return(
        bounds.extend(position)
      )
    } );
    map.fitBounds(bounds);
    setMap(map);
  };

  useEffect(() => {
    if (map && selectedLocation) {
      map.panTo(selectedLocation);
      map.setZoom(zoom ? zoom : 16);
    } else if(map && defaultCenterlatlng){
      map.panTo(defaultCenterlatlng);
      map.setZoom(zoom ? zoom : 8);
    }
  }, [map, selectedLocation, defaultCenterlatlng]);
  return locationsWithAddress?.length === 0 ? (
    <></>
  ) : (  <GoogleMap
    onLoad={handleOnLoad}
    onClick={() => setActiveMarker(null)}
    mapContainerStyle={{ width: "100%", height: "382px" }}
    center={selectedLocation ? selectedLocation : defaultCenterlatlng}
    mapTypeId={"terrain"} // roadmap, satellite, hybrid, terrain
    // zoom={12}
    options={{
      scrollZoom: { ctrl: true },
      zoomControl: true,
      draggable: true,
      mapTypeControl: true,
      scaleControl: true,
      streetViewControl: false,
      rotateControl: true,
      fullscreenControl: true,
      scrollwheel: true, 
    }}
  >
    {locationsWithAddress?.map((marker) => {
        const positionData = {lat:marker?.locationLat ? parseFloat(marker.locationLat) : null , lng:marker?.locationLong ? parseFloat(marker.locationLong) : null}
      return (
      (marker?.locationLat && marker?.locationLat) && (
         <Marker
         key={marker.id}
         icon={{
          scaledSize: new window.google.maps.Size(50,50),
          url: showCustomMarker ? (marker.image_PreSignedUrl && marker.image_PreSignedUrl !== "" ? marker.image_PreSignedUrl : marker.isMeetingLocationMarker ? require('../../../../../assets/images/green-dot.png'):   require('../../../../../assets/icons/user-avatar.svg').default) + '#custom_marker' : null,
         }}
         position={positionData}
         onClick={() => {handleActiveMarker(marker.id)}}
       >
         {activeMarker !== null && activeMarker === marker.id ? (
           <InfoWindowF onCloseClick={() => setActiveMarker(null)}>
            <>
                  <div style={{fontWeight:"bold"}}> {marker.name
                    ? marker.name
                    : marker.firstName +
                    " " +
                    (marker?.middleName && marker?.middleName !== null ? marker.middleName : "") +
                    " " +
                    (marker?.lastName && marker?.lastName !== null ? marker?.lastName : "")}</div>
                    <div>{marker.address}</div>

                    </>
             
           </InfoWindowF>
         ) : <></>  }
       </Marker>
      ))
    
})}
  </GoogleMap>)
 
}

export default MeetingLocationMap;
