import React, { useEffect, useState } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { ThemeProvider as MuiThemeProvider, createTheme as createMuiTheme } from "@mui/material/styles";
import { useIdleTimer } from 'react-idle-timer'

import IndexComponent from "../../Pages/Login/component/IndexComponent";
import HomeComponent from '../../Pages/Home/components/HomeComponent';
import MyAssetsComponent from '../../Pages/Home/components/MyAssetsComponent';
import MyProfile from "../../Pages/UserProfile/components/MyProfile";
import EditProfile from '../../Pages/UserProfile/components/EditProfile';
import ManagePassword from "../../Pages/UserProfile/components/ManageProfile";
import SettingComponent from "../../Pages/Settings/components/SettingComponent";
import DisasterManagement from "../../Pages/Settings/components/DisasterManagement";
import FamilyMembersComponent from "../../Pages/Settings/components/MyFamily/FamilyMembersComponent";
import AddFamilyMembersComponent from "../../Pages/Settings/components/MyFamily/AddFamilyMembersComponent";
import EditFamilyMembersComponent from "../../Pages/Settings/components/MyFamily/EditFamilyMembersComponent";
import InfoFamilyMembersComponent from '../../Pages/Info/components/MyFamily/AddMyFamily/FamilyMembersComponent';
import AddFamilyMembersInfoComponent from '../../Pages/Info/components/MyFamily/AddMyFamily/AddInformationComponent';
import CreateAccount from "../../Pages/Registration/components/CreateAccount";
import AccountVerification from "../../Pages/Registration/components/AccountVerification";
import ForgotPassword from "../../Pages/ForgotPassword/components/ForgotPassword";
import ResetPassword from "../../Pages/ForgotPassword/components/ResetPassword";
import SetPassword from "../../Pages/ForgotPassword/components/SetPassword";
import ProtectedRoute from "./ProtectedRoute";
import UserRoute from './UserRoute';
import MyBankAccounts from "../../Pages/Info/components/MyBankAccounts";
import AddBankAccount from '../../Pages/Info/components/MyBankAccounts/AddBankAccount';
import MyCreditCards from "../../Pages/Info/components/MyCreditCards";

import AddCreditCardDetails from '../../Pages/Info/components/MyCreditCards/AddCreditCardDetails';
import EditCreditCardDetails from '../../Pages/Info/components/MyCreditCards/EditCreditCardDetails';
import MeetingLocation from "../../Pages/Settings/components/MeetingLocations";
import PredefinedMessages from "../../Pages/Settings/components/PreDefinedMessages";
import SystemBranding from "../../Pages/Settings/components/SystemBranding";
import EmergencyAlert from "../../../common/components/Layout/Footer/EmergencyAlert/components/index";
import MyPropertys from '../../Pages/Info/components/MyPropertys';
import AddMyPropertyInventory from '../../Pages/Info/components/MyPropertys/AddMyPropertyInventory';
import MyFireArms from '../../Pages/Info/components/MyFireArm';
import EditFireArms from '../../Pages/Info/components/MyFireArm/EditMyFireArms';
import AddFireArms from '../../Pages/Info/components/MyFireArm/AddMyFireArms';
import MyVechiles from '../../Pages/Info/components/MyVechiles';
import AddVechiles from '../../Pages/Info/components/MyVechiles/AddMyVechiles';
import EditVechiles from '../../Pages/Info/components/MyVechiles/EditMyvechiles';
import ManagePredefinedMessages from "../../Pages/Settings/components/ManagePredefinedMessages";
import MyIdentifications from "../../Pages/Info/components/MyIdentificatons";
import AddMyIdentificationDetails from "../../Pages/Info/components/MyIdentificatons/AddMyIdentifications";
import EditMyIdentificationDetails from "../../Pages/Info/components/MyIdentificatons/EditMyIdentifications";
import MySocialMedia from "../../Pages/Info/components/MySocialMedia";
// import EditSocialMedia from "../../Pages/Info/components/MySocialMedia/EditSocialMedia";
import MySubscription from "../../Pages/UserProfile/components/MySubscription";
import BillingAddress from "../../Pages/UserProfile/components/BillingAddress";
import PaymentMethod from "../../Pages/UserProfile/components/PaymentMethod";
import TrackMe from "../../../common/components/Layout/Footer/TrackMe/components/index";
import TrackMeNow from "../../../common/components/Layout/Footer/TrackMe/TrackMeNow/TrackMeNow";
import TrackMyFamily from "../../../common/components/Layout/Footer/TrackMe/TrackMyFamily/index";
import RequestForTracking from "../../../common/components/Layout/Footer/TrackMe/RequestForTracking/index";
import RequestForTrackingView from "../../../common/components/Layout/Footer/TrackMe/RequestForTracking/requestView";
import MyPlansComponent from '../../Pages/MyPlans/components/MyPlansComponent';
import EmergencyServices from "../../../common/components/Layout/Footer/EmergencyServices/index";
import ServiceIndex from "../../../common/components/Layout/Footer/EmergencyServices/serviceIndex";
import PropertyInventory from "../../Pages/Info/components/MyPropertys/PropertyInventory";
import ForgotSecurityLock from "../../../containers/Pages/Home/components/ForgotSecurityLock";
import ResetSecurityLock from "../../Pages/Home/components/ResetSecurityLock";
import ResetSecurityPin from "../../Pages/Home/components/ResetSecurityPin";
import { UserGeolocationProvider } from "../../../utils/userContext";
import TrackMeView from "../../../common/components/Layout/Footer/TrackMe/components/viewSummary"
import LiveLocation from "../../../common/components/Layout/Footer/TrackMe/TrackMyFamily/LiveLocation";
import NeedHelp from "../../Pages/NeedHelp/components/index";
import NeedHelpCard from "../../Pages/NeedHelp/components/NeedHelpCard/index";

const theme = createMuiTheme({
  palette: {
    type: "light",
  },
});

function ScrollToTop() {
  const location = useLocation();


  useEffect(() => {
    window.scrollTo(0, 0);
    window.history.scrollRestoration = "manual";
  }, [location]);

  return null;
}



const Router = () => {

  

  return (
    <>
      <MuiThemeProvider theme={theme}>
        <ScrollToTop />
        <UserGeolocationProvider>
        <Routes>
          <Route path="/"
            element={
              <UserRoute>
                <IndexComponent />
              </UserRoute>
            } />
          <Route path="/login" element={
            <UserRoute>
              <IndexComponent />
            </UserRoute>
          } />
          <Route path="/create-account"
            element={
              <UserRoute>
                <CreateAccount />
              </UserRoute>
            } />
          <Route path="/account-verification" element={
            <UserRoute>
              <AccountVerification />
            </UserRoute>
          }
          />
          <Route path="/forgot-password"
            element={
              <UserRoute>
                <ForgotPassword />
              </UserRoute>} />
          <Route path="/reset-password"
            element={
              <UserRoute>
                <ResetPassword />
              </UserRoute>} />
          <Route path="/setup-password"
            element={
              <UserRoute>
                <SetPassword />
              </UserRoute>
            } />
          <Route path="/verification/"
            element={
              <UserRoute>
                <IndexComponent socialMediaRedirect="true" />
              </UserRoute>} />
          <Route path="/logout/"
            element={<IndexComponent />} />
          <Route path="/home"
            element={
              <ProtectedRoute>
                <HomeComponent />
              </ProtectedRoute>
            }
          />
          <Route path="/profile"
            element={
              <ProtectedRoute>
                <MyProfile />
              </ProtectedRoute>
            }
          />
          <Route path="/edit-profile"
            element={
              <ProtectedRoute>
                <EditProfile />
              </ProtectedRoute>
            }
          />
          <Route path="/manage-password"
            element={
              <ProtectedRoute>
                <ManagePassword />
              </ProtectedRoute>
            }
          />
          <Route path="/settings" element={
            <ProtectedRoute>
              <SettingComponent />
            </ProtectedRoute>
          }
          />
          <Route path="/disaster-management" element={
            <ProtectedRoute>
              <DisasterManagement />
            </ProtectedRoute>
          }
          />

          <Route path="/manage-predefined-messages" element={
            <ProtectedRoute>
              <PredefinedMessages />
            </ProtectedRoute>
          }
          />
          <Route path="/system-branding" element={
            <ProtectedRoute>
              <SystemBranding />
            </ProtectedRoute>
          }
          />
          <Route path="/my-family-members" element={
            <ProtectedRoute>
              <FamilyMembersComponent />
            </ProtectedRoute>
          }
          />
          <Route path="/my-bank-accounts" element={
            <ProtectedRoute>
              <MyBankAccounts />
            </ProtectedRoute>
          }
          />
          <Route path="/add-bank-account" element={
            <ProtectedRoute>
              <AddBankAccount />
            </ProtectedRoute>
          } />
          <Route path="/my-credit-cards" element={
            <ProtectedRoute>
              <MyCreditCards />
            </ProtectedRoute>
          }
          />
          <Route path="/add-card-details" element={
            <ProtectedRoute>
              <AddCreditCardDetails />
            </ProtectedRoute>
          } />
          <Route path="/edit-card-details" element={
            <ProtectedRoute>
              <EditCreditCardDetails />
            </ProtectedRoute>
          } />
          <Route path="/add-family-members" element={
            <ProtectedRoute>
              <AddFamilyMembersComponent />
            </ProtectedRoute>
          } />
          <Route path="/edit-family-members/:id" element={
            <ProtectedRoute>
              <EditFamilyMembersComponent />
            </ProtectedRoute>
          } />
          <Route path="/my-family" element={
            <ProtectedRoute>
              <InfoFamilyMembersComponent />
            </ProtectedRoute>
          } />
          <Route path="/add-members-info" element={
            <ProtectedRoute>
              <AddFamilyMembersInfoComponent />
            </ProtectedRoute>
          } />
          <Route path="/my-assets" element={
            <ProtectedRoute>
              <MyAssetsComponent />
            </ProtectedRoute>
          } />
          <Route path="/meeting-locations"
            element={
              <ProtectedRoute>
                <MeetingLocation />
              </ProtectedRoute>
            }
          />
          <Route path="/emergency-alert" element={
            <ProtectedRoute>
              <EmergencyAlert />
            </ProtectedRoute>
          } />
          <Route path="/my-property" element={
            <ProtectedRoute>
              <MyPropertys />
            </ProtectedRoute>
          } />

          <Route path="/my-property-inventory" element={
            <ProtectedRoute>
              <PropertyInventory />
            </ProtectedRoute>
          } />
          <Route path="/add-property-details" element={
            <ProtectedRoute>
              <AddMyPropertyInventory />
            </ProtectedRoute>
          } />

          <Route path="/edit-property-details/:propertyId" element={
            <ProtectedRoute>
              <AddMyPropertyInventory />
            </ProtectedRoute>
          } />  
          <Route path="/my-fire-arms" element={
            <ProtectedRoute>
              <MyFireArms />
            </ProtectedRoute>
          } />
          <Route path="/add-fire-arms" element={
            <ProtectedRoute>
              <AddFireArms />
            </ProtectedRoute>
          } />
          <Route path="/edit-fire-arms" element={
            <ProtectedRoute>
              <EditFireArms />
            </ProtectedRoute>
          } />
          <Route path="/my-vechiles" element={
            <ProtectedRoute>
              <MyVechiles />
            </ProtectedRoute>
          } />
          <Route path="/add-my-vechiles" element={
            <ProtectedRoute>
              <AddVechiles />
            </ProtectedRoute>
          } />
          <Route path="/edit-my-vechiles" element={
            <ProtectedRoute>
              <EditVechiles />
            </ProtectedRoute>
          } />
          <Route path="/my-identifications" element={
            <ProtectedRoute>
              <MyIdentifications />
            </ProtectedRoute>
          } />
          <Route path="/add-identifications" element={
            <ProtectedRoute>
              <AddMyIdentificationDetails />
            </ProtectedRoute>
          } />
          <Route path="/edit-identifications" element={
            <ProtectedRoute>
              <EditMyIdentificationDetails />
            </ProtectedRoute>
          } />
          <Route path="/my-socialmedia" element={
            <ProtectedRoute>
              <MySocialMedia />
            </ProtectedRoute>
          } />
          {/* <Route path="/edit-mysocialmedia" element={
          <ProtectedRoute>
         <EditSocialMedia/>
          </ProtectedRoute>  
        } />  */}
         <Route path="/my-subscription" element={
          <ProtectedRoute>
            <MySubscription />
          </ProtectedRoute>
        } />
         <Route path="/billing-address" element={
          <ProtectedRoute>
            <BillingAddress />
          </ProtectedRoute>
        } />
         <Route path="/payment-method" element={
          <ProtectedRoute>
            <PaymentMethod />
          </ProtectedRoute>
        } />
            <Route path="/track-me" element={
              <ProtectedRoute>
                <TrackMe />
              </ProtectedRoute>
            } />
           
           <Route path="/share-my-location/view" element={
              <ProtectedRoute>
                <TrackMeView/>
              </ProtectedRoute>
            }/>
            <Route path="/live-location" element={
              <ProtectedRoute>
                <LiveLocation/>
              </ProtectedRoute>
            }/>
            <Route path="/share-my-location" element={
              <ProtectedRoute>
                <TrackMeNow/>
              </ProtectedRoute>
            }/>
            <Route path="/track-my-family" element={
              <ProtectedRoute>
                <TrackMyFamily/>
              </ProtectedRoute>
            } />

            <Route path="/request-for-tracking" element={
              <ProtectedRoute>
                <RequestForTracking />
              </ProtectedRoute>
            } />
            <Route path="//request-for-tracking/view" element={
              <ProtectedRoute>
                <RequestForTrackingView />
              </ProtectedRoute>
            } />


             <Route path="/emergency-services" element={
              <ProtectedRoute>
                <EmergencyServices />
              </ProtectedRoute>
            } />

            <Route path="/emergency-services/:serviceType" element={
              <ProtectedRoute>
                <ServiceIndex />
              </ProtectedRoute>
            } />
             {/* <Route path="/forgot-security-lock" element={
              <ProtectedRoute>
                <ForgotSecurityLock/>
               </ProtectedRoute>
             } />  */}
              <Route path="/reset-security-lock" element={
                  <ProtectedRoute>
                     <ResetSecurityLock/>
                   </ProtectedRoute>
            } />
             <Route path="/reset-security-pin" element={
              // <ProtectedRoute>
                <ResetSecurityPin/>
              // </ProtectedRoute>
             } />
             <Route path="/need-help" element={
              <ProtectedRoute>
                <NeedHelp/>
              </ProtectedRoute>
             }/>
             <Route path="/need-help/activate" element={
              <ProtectedRoute>
                <NeedHelpCard/>
              </ProtectedRoute>
             }/>
        </Routes>
        </UserGeolocationProvider>
       
      </MuiThemeProvider>
    </>
  )
};

export default Router;
