import React from "react";
import ContentWrapper from "../../../../ContentWrapper";
import TrackMe from "./TrackMe";

const TrackMeComponents = () => {
  return(
    <ContentWrapper title={"Track me"} gobackUrl={'/home'} goback={true} img={false} contenview={<TrackMe />} />
  )
}

export default TrackMeComponents;