import React, { useState, useEffect } from "react";
import ContentWrapper from "../../../../common/components/ContentWrapper";
import { styled } from "@mui/material/styles";
import { Link, useNavigate } from "react-router-dom";
import {
  Grid,
  Typography,
  Button,
  Card,
  CardContent,
  CardActions,
  Divider,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Checkbox,
  Dialog,
  Alert,
  Collapse,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { FlashOnTwoTone } from "@mui/icons-material";

const StyledCard = styled(Card)({
  variant: "outlined",
  border: "1px solid #D8D8D8",
  borderRadius: 5,
  boxShadow: "none",
  padding: 10,
});
const Plantype = [
  {
    title: "Premium",
    content: "Subscription renewal date",
    date: "September 29, 2023 (in 17 days)",
  },

  // Add more data as needed
];
const BillingInformation = [
  {
    title: "Billing Information",
    name: "John Doe",
    address: "15703 Pines Blvd",
    location: "Pembroke Pines, FL - 33027",
  },
];
const PaymentMethod = [
  {
    title: "Payment Method",
    card: "credit card",
  },
];

const MainLayout = () => {
  const [promoCode, setPromoCode] = useState();
  const [openAlert, setOpenAlert] = useState();
  const [selectPlan, setSelectPlan] = useState(null);

  const handlePlanChange = (e, planType) => {
    setSelectPlan(planType);

    document.querySelectorAll('input[type = "radio"]').forEach((radio) => {
      if (radio.value !== planType) {
        radio.checked = false;
      }
    });
    console.log(planType);
  };

  const navigate = useNavigate();

  return (
    <Grid container style={{ width: "100%", margin: 0, display: "flex" }}>
      <Grid item xs={1} md={1} lg={1}></Grid>
      <Grid
        item
        xs={10}
        md={10}
        lg={10}
        className="box-contianer"
        style={{ display: "flex" }}
      >
        <Grid>
          <div className="box-header-2">
            <Typography className="primary-font-bold-2-heading">
              Payments Details
            </Typography>
          </div>
          <Grid item xs={10} md={6} lg={6}>
            {PaymentMethod.map((paymentmethod) => (
              <StyledCard
                style={{
                  width: 550,
                  height: 120,
                  padding: "10px",
                  margin: "10px 0",
                  marginLeft: "15px",
                }}
              >
                <CardContent>
                  <Typography className="primary-font-bold1" sx={{ mb: 1.5 }}>
                    {paymentmethod.title}
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button
                    variant="outlined"
                    className="primary-font-bold-5 action-btn profile-cancel-btn"
                    onClick={() => {
                      navigate("/payment-method");
                    }}
                  >
                    Add Payment Method
                  </Button>
                </CardActions>
              </StyledCard>
            ))}

            {BillingInformation.map((billinginfo) => (
              <StyledCard
                style={{
                  width: 550,
                  height: 120,
                  padding: "10px",
                  marginTop: "20px",
                  marginLeft: "15px",
                }}
              >
                <CardContent>
                  <Typography className="primary-font-bold1">
                    {billinginfo.title}
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button
                    variant="outlined"
                    className="primary-font-bold-5 action-btn profile-cancel-btn"
                    onClick={() => {
                      navigate("/billing-address");
                    }}
                  >
                    Add billing information
                  </Button>
                </CardActions>
              </StyledCard>
            ))}
          </Grid>
        </Grid>
        <Divider
          orientation="vertical"
          flexItem
          variant="middle"
          style={{ paddingLeft: 50 }}
        />
        <Grid item xs={10} md={6} lg={6} style={{ paddingLeft: 40 }}>
          <div className="box-header-2">
            <Typography className="primary-font-bold-2-heading">
              Order Summary
            </Typography>
          </div>
          <Grid item xs={10} md={6} lg={6}>
            <Card
              style={{
                width: 430,
                height: 80,
                marginLeft: "20px",
                display: "flex",
                borderRadius: "5px",
              }}
            >
              <Grid
                style={{ backgroundColor: "	#D0D0D0", alignContent: "center" }}
              >
                <Typography
                  variant="h5"
                  style={{
                    alignContent: "center",
                    marginLeft: "20px",
                    paddingRight: "77px",
                  }}
                >
                  Basic
                </Typography>
              </Grid>

              <Divider orientation="vertical" flexItem />
              <div>
                <FormControl>
                  <RadioGroup
                    sx={{ "&.Mui-checked": { color: "red" } }}
                    style={{ accentColor: "red" }}
                  >
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <FormControlLabel
                        value="Monthly"
                        control={
                          <Radio color="error"
                            // style={{
                            //   color:
                            //     selectPlan === "basic-monthly" ? "red" : "grey",
                            // }}
                          />
                        }
                        label="Monthly"
                        style={{ marginLeft: "10px" }}
                        onChange={(e) => handlePlanChange(e, "basic-monthly")}
                        checked={selectPlan === "basic-monthly"}
                      />
                      <Typography
                        style={{ fontWeight: "bold", marginLeft: "70px" }}
                      >
                        $10.99
                      </Typography>
                    </div>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <FormControlLabel
                        value="yearly"
                        control={
                          <Radio color="error"
                            // style={{
                            //   color:
                            //     selectPlan === "basic-yearly" ? "red" : "grey",
                            // }}
                          />
                        }
                        label="Yearly"
                        style={{ marginLeft: "10px" }}
                        onChange={(e) => handlePlanChange(e, "basic-yearly")}
                        checked={selectPlan === "basic-yearly"}
                      />
                      <Typography
                        style={{ fontWeight: "bold", marginLeft: "76px" }}
                      >
                        $119.00
                      </Typography>
                    </div>
                  </RadioGroup>
                </FormControl>
              </div>
            </Card>
            <br />
            <Card
              style={{
                width: 430,
                height: 80,
                marginLeft: "20px",
                display: "flex",
              }}
            >
              <Grid
                style={{ backgroundColor: "	#D0D0D0", alignContent: "center" }}
              >
                <Typography
                  variant="h5"
                  style={{
                    alignContent: "center",
                    marginLeft: "20px",
                    paddingRight: "40px",
                  }}
                >
                  Premium
                </Typography>
              </Grid>
              <Divider orientation="vertical" flexItem />
              <div>
                <FormControl>
                  <RadioGroup>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <FormControlLabel
                        value="Monthly"
                        control={
                          <Radio color="error"
                            // style={{
                            //   color:
                            //     selectPlan === "premium-monthly"
                            //       ? "red"
                            //       : "grey",
                            // }}
                          />
                        }
                        label="Monthly"
                        style={{ marginLeft: "10px" }}
                        onChange={(e) => handlePlanChange(e, "premium-monthly")}
                        checked={selectPlan === "premium-monthly"}
                      />
                      <Typography
                        style={{ fontWeight: "bold", marginLeft: "70px" }}
                      >
                        $29.00
                      </Typography>
                    </div>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <FormControlLabel
                        value="yearly"
                        control={
                          <Radio color="error"
                            // style={{
                            //   color:
                            //     selectPlan === "premium-yearly"
                            //       ? "red"
                            //       : "grey",
                            // }}
                          />
                        }
                        label="Yearly"
                        style={{ marginLeft: "10px" }}
                        onChange={(e) => handlePlanChange(e, "premium-yearly")}
                        checked={selectPlan === "premium-yearly"}
                      />
                      <Typography
                        style={{ fontWeight: "bold", marginLeft: "76px" }}
                      >
                        $329.00
                      </Typography>
                    </div>
                  </RadioGroup>
                </FormControl>
              </div>
            </Card>
          </Grid>
          <br />
          <Grid style={{ paddingLeft: 20 }}>
            <Typography style={{ fontWeight: "bold" }}>Promo Code</Typography>
            <div style={{ marginTop: "20px", display: "flex" }}>
              <TextField
                placeholder="Enter the code here"
                variant="outlined"
                onChange={(e) => setPromoCode(e.target.value)}
                style={{
                  border: "1px solid #C0C0C0",
                  width: "60%",
                  borderRadius: "5px",
                  height: "50px",
                }}
              />
              <Button
                variant="outlined"
                disabled={promoCode ? false : true}
                onClick={() => setOpenAlert(true)}
                style={{
                  marginLeft: "10px",
                  width: "25%",
                  color: promoCode ? "red" : "grey",
                  borderColor: promoCode ? "red" : "grey",
                }}
              >
                Apply
              </Button>
            </div>
            {/* <br/> */}
            <div style={{ marginTop: "5px", width: "87%" }}>
              <Collapse in={openAlert}>
                <Alert
                  variant="filled"
                  severity="success"
                  action={
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={() => {
                        setOpenAlert(false);
                      }}
                    >
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                  }
                >
                  coupon applied successfully!
                </Alert>
              </Collapse>
            </div>
          </Grid>
          <br />
          <Grid>
            <StyledCard style={{ width: 410, height: 150, marginLeft: "20px" }}>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Typography style={{ fontWeight: "bold" }}>
                  Premium Subscription
                </Typography>
                <Typography style={{ fontWeight: "bold" }}>Monthly</Typography>
              </div>
              <br />
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Typography>Price</Typography>
                <Typography>$ 329.00</Typography>
              </div>
              <br />
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Typography>Tax</Typography>
                <Typography>$ 0.00</Typography>
              </div>
              <br />
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Typography style={{ fontWeight: "bold" }}>Total</Typography>
                <Typography style={{ fontWeight: "bold" }}>$ 329.00</Typography>
              </div>
            </StyledCard>
          </Grid>
          {/* <br/> */}
          <br />
          <Grid
            style={{
              display: "flex",
              alignItems: "center",
              marginLeft: "10px",
            }}
          >
            <Checkbox />
            <Typography variant="body 1">
              {" "}
              I agree to the{" "}
              <Link style={{ color: "#0099ff" }}>Licence Terms</Link> and
              Condition.
            </Typography>
          </Grid>
          <br />
          <Grid style={{ marginLeft: "20px" }}>
            <Button variant="outlined" disabled style={{ width: "90%" }}>
              complete Orders
            </Button>
          </Grid>
          <br />
        </Grid>
      </Grid>
    </Grid>
  );
};
const MySubscription = () => {
  return (
    <ContentWrapper
      title={"My Subscription"}
      gobackUrl={"/my-subscription"}
      goback={true}
      contenview={<MainLayout />}
      img={false}
    />
  );
};
export default MySubscription;
