import React, { useEffect, useState } from "react";
import { Button, Box, Typography, Divider, Card, CardContent } from "@mui/material";
import * as formatters from '../../../../utils/util';
import { useNavigate } from "react-router-dom";
import {resetForgotPasswordData, resetPasswordData} from '../redux/forgotPasswordAction'
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import CloseIcon from"../../../../assets/icons/modal-close-icon.svg";

const ResetPassword = (props) => {
    const {
        resetForgotPasswordData, 
        forgotPasswordData,
        resetPasswordData,
        forgotPasswordError,
        isFetchingFP,
        fpBackendData,
        fpBackendError
      } = props;
  const navigate = useNavigate();
  const [code, setCode] = useState(null)
  const [newPassword, setNewPassword] = useState(null);
  const [confirmNewPassword, setConfirmNewPassword] = useState(null);
  const [codeErr, setCodeErr] = useState('');
  const [newPasswordErr, setNewPasswordErr] = useState('');
  const [confirmNewPasswordErr, setConfirmNewPasswordErr] = useState('');
  const [hiddenEmail, setHiidenEmail] = useState(window.localStorage.getItem('hiddenEmail'));
  const email = window.localStorage.getItem('forgotpassEmail');

  const [showCode, setShowCode] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleClickShowCode = () => setShowCode(!showCode);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleClickShowConfirmPassword = () => setShowConfirmPassword(!showConfirmPassword);

  const handleMouseDownCode = () => setShowCode(!showCode);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  const handleMouseDownConfirmPassword = () => setShowConfirmPassword(!showConfirmPassword);

  useEffect(()=>{
    if(forgotPasswordData){
        const payload = {
            id:"",
            emailId: email,
            // password: newPassword,
        }
        resetPasswordData(payload)
    }
    if(forgotPasswordError){
        if(forgotPasswordError?.code === 'CodeMismatchException'){
            setCodeErr('Invalid verification code provided, please try again');
        }else{
            setCodeErr(forgotPasswordError?.message)
        }
    }
  },[forgotPasswordData,forgotPasswordError])

  useEffect(()=>{
    if(fpBackendData && fpBackendData.status === 200){
        navigate('/')
    }else if(fpBackendData && fpBackendData.status !== 200){
        setCodeErr(fpBackendData.message)
    }
    // if(fpBackendError){
    //     setCodeErr(JSON.stringify(fpBackendError))
    // }
  },[ fpBackendError, fpBackendData])

  const onChangePassword = (e, type) => {
    if (type === 'code') {
        setCode(e.target.value)
    } else if (type ===  'newPassword') {
        setNewPassword(e.target.value);
    } else if (type ===  'confirmNewPassword') {
        setConfirmNewPassword(e.target.value);
    }
  }
  const onClickSave = () => {
    if (!code?.trim().length) {
        setCodeErr('This is required.');
        return
    } else if (code?.trim().length !== 6) {
        setCodeErr('Code should contain 6 digits.');
        return
    } else {
        setCodeErr('');
    }
    if (!newPassword?.trim().length) {
      setNewPasswordErr('This is required.');
      return
    } else if (!formatters.isPasswordValid(newPassword)) {
        setNewPasswordErr('Enter a valid New Password.');
        return
    } else {
        setNewPasswordErr('');
    }
    if (!confirmNewPassword?.trim().length) {
        setConfirmNewPasswordErr('This is required.');
        return
    }  else if (newPassword !== confirmNewPassword) {
        setConfirmNewPasswordErr('New Password and Confirm New Password mismatch.');
        return
    } else {
        setConfirmNewPasswordErr('');
    }
    const payload = {
        username:email,
        code: code,
        newPassword: newPassword,
        confirmNewPassword: confirmNewPassword
    }
    resetForgotPasswordData(payload)   
  }
  const handleCloseIconClick = () => {
    (navigate('/login'))
    setCodeErr('');
    setNewPasswordErr('');
    setConfirmNewPasswordErr('');
  }
  return (
    <Box
    className="loginScreen"
    id='account-layout'
  >
        <Card sx={{ minWidth: '35%' }}>
            <div style={{ display: 'flex',   justifyContent: 'space-between' }}>
            <Typography className='primary-font-bold-heading1' style={{paddingLeft: 30}}>Reset Password</Typography>
              <div style={{ display: 'flex',alignitems:'flex-end',justifycontent:'end',paddingRight:30}}>
                <img src={CloseIcon} alt="close-icon" style={{cursor:'pointer',width:"21px"}} onClick={handleCloseIconClick} sx={{
                        marginRight:"30px"
                    }}/>         
                </div>
            </div>
            <Divider />
            <CardContent style={{padding: '15.5px 42px 32px 42px'}}>
                <Typography className="primary-font-normal-2" component="div" style={{marginBottom: 25}}>
                  We have sent a password reset code by email to <b>{hiddenEmail}</b> Enter it below to reset your password.
                </Typography>
                <div>
                  <Typography className='font-normal-small error-message'>{codeErr}</Typography>
                  <Typography className='font-normal-small profile-input-label'>Code</Typography>
                  <div className="input-holder"> 
                    <input type={showCode ? "text" : "password"} className={`profile-input ${codeErr !== '' ? 'err-input' : ''}`}  aria-label='new-pin'  maxLength={6} onChange={(e) => {onChangePassword(e, 'code')}} value={code}/>
                    <IconButton type="button" sx={{ p: '10px' }} aria-label="password" 
                        onClick={handleClickShowCode}
                        onMouseDown={handleMouseDownCode}
                        className="input-eye-icon pwd-icon1"
                        >
                        {showCode ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                    </div>
              </div>
              <div>
                  <Typography className='font-normal-small error-message'>{newPasswordErr}</Typography>
                  <Typography className='font-normal-small profile-input-label'>New Password</Typography>
                  <div className="input-holder"> 
                    <input className={`profile-input ${newPasswordErr !== '' ? 'err-input' : ''}`}  aria-label='new-password' type={showPassword ? "text" : "password"} onChange={(e) => {onChangePassword(e, 'newPassword')}}   maxLength={12} value={newPassword}/>
                    <IconButton type="button" sx={{ p: '10px' }} aria-label="password" 
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        className="input-eye-icon pwd-icon1"
                        >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                    </div>
              </div>
              <div>
                  <Typography className='font-normal-small error-message'>{confirmNewPasswordErr}</Typography>
                  <Typography className='font-normal-small profile-input-label'>Confirm New Password</Typography>
                  <div className="input-holder"> 
                    <input className={`profile-input ${confirmNewPasswordErr !== '' ? 'err-input' : ''}`} aria-label='confirm-new-password' type={showConfirmPassword ? "text" : "password"} onChange={(e) => {onChangePassword(e, 'confirmNewPassword')}}   maxLength={12} value={confirmNewPassword}/>
                    <IconButton type="button" sx={{ p: '10px' }} aria-label="password" 
                        onClick={handleClickShowConfirmPassword}
                        onMouseDown={handleMouseDownConfirmPassword}
                        className="input-eye-icon pwd-icon1"
                        >
                        {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                    </div>
              </div>
              <div className="action-holder" style={{marginTop: 20, marginBottom: 0}}>
                <Button variant="outlined" style={{width: '100%'}}  className="primary-font-bold-6 action-btn profile-save-btn"  onClick={onClickSave}>Change Password</Button>
              </div>
            </CardContent>
        </Card>
  </Box>
        
  )
}

function mapStateToProps(state){
    return{
        forgotPasswordData: state.forgotPassword.forgotPasswordData,
        forgotPasswordError: state.forgotPassword.forgotPasswordError,
        isFetchingFP : state.forgotPassword.isFetchingFP,
        fpBackendData: state.forgotPassword.fpBackendData,
        fpBackendError: state.forgotPassword.fpBackendError
    }
}
function mapDispatchToProps(dispatch){
    return bindActionCreators(
            {
                resetForgotPasswordData,
                resetPasswordData
            },
            dispatch
        )
}
export default connect(mapStateToProps,mapDispatchToProps)(ResetPassword);
